.b-news {
  @at-root {
    &__title {
      max-width: 400px;
      @include respond(sm) {
        max-width: 200px;
      }
    }

    &__list {
      margin-top: var(--space-xxl);
      @include respond(lg) {
        margin-top: var(--space-xxxxxxxxxl);
      }
      @include respond(md) {
        margin-top: var(--space-xxxxxl);
      }
    }

    &__list-item {
      @include respond-down(sm) {
        &:nth-child(n+3) {
          display: none;
        }
      }
    }

    &__list-image-wrapper {
      display: flex;
      grid-column: span 4;
      grid-row: span 2;
      @include respond-down(lg) {
        display: none;
      }
    }

    &__list-image {
      display: block;
      width: 100%;
      object-fit: cover;
    }

    &__button-all {
      display: block;
      min-width: 180px;
      max-width: 180px;
      margin: var(--space-xxxxl) auto 0;
      @include respond(lg) {
        margin: calc(-1 * var(--space-m)) auto 0;
      }
      @include respond(md) {
        min-width: 190px;
        max-width: 190px;
        margin: var(--space-xxxxs) auto 0
      }
      @include respond-down(sm) {
        margin: 0 auto;
      }
    }
  }
}
