.b-hero {
  @include respond(lg) {
    padding-top: var(--space-xxxs);
  }
  @include respond(md) {
    padding-top: var(--space-m);
    padding-right: var(--space-xl);
  }
  @include respond(sm) {
    padding-top: var(--space-xxxl);
  }

  @at-root {
    &__text {
      @include respond(xl) {
        padding-top: var(--space-l);
      }
      @include respond(lg) {
        line-height: 1.142;
      }
      @include respond(md) {
        line-height: calc(42 / 38);
        font-size: 38px;
      }
      @include respond(sm) {
        font-size: 32px;
        line-height: calc(38 / 32);
      }
    }

    &__image-wrapper {
      display: flex;
      justify-content: flex-end;
      @include respond(md) {
        margin-top: var(--space-m);
      }
    }

    &__image {
      display: block;
      width: 100%;
      object-fit: contain;

      @include respond(xl) {
        height: 427px;
      }
      @include respond(lg) {
        height: 310px;
      }
      @include respond(md) {
        height: 360px;
      }
      @include respond(sm) {
        height: 344px;
      }
    }

    &__partners {

      flex-direction: row;
      @include respond(sm) {
        flex-direction: column;
      }
    }
  }
}
