body.for-advertisers-page {

  .p-advertisers {
    padding-top: var(--space-xxxxxl);
    @include respond(lg) {
      padding-top: var(--space-s);
    }
    @include respond(md) {
      padding-top: var(--space-l);
    }
    @include respond(sm) {
      padding-top: var(--space-xxxl);
    }

    .triangle {
      margin: var(--space-xl) auto var(--space-xl) auto;
      @include respond(md) {
        margin: var(--space-xxl) auto var(--space-xxxxxxxxl) auto;
      }
      @include respond(sm) {
        margin: var(--space-s) auto var(--space-l) auto;
      }
    }

    @at-root {


      &__section {

        &_name {
          &_advertisers {

          }

          &_distribution {

          }

          &_info {
            @include respond(xl) {
              margin-top: calc(var(--space-xxxxxxxxxl) + var(--space-xxxxs));
            }
            @include respond(lg) {
              margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-l));
            }
            @include respond-down(md) {
              margin-top: var(--space-xxxxxxxxl);
            }
            @include respond-down(sm) {
              margin-top: var(--space-xxxxxxxxxl);
            }
          }

          &_placement {
            @include respond-up(lg) {
              padding-top: var(--space-xs);
            }
            @include respond-down(md) {
              .p-advertisers__section-title {
                margin-top: var(--space-xxxxxxxl);
                margin-bottom: var(--space-xxxxxl);
              }
            }
          }
        }
      }

      &__title {
        @include textLargest;

        @include respond(xl) {
          margin-bottom: var(--space-xxxxxxl);
        }

        @include respond(lg) {
          margin-bottom: var(--space-xxxxxxxxxxl);
        }

        @include respond(md) {
          margin-bottom: var(--space-xxxxxxxxl);
        }
        @include respond(sm) {
          font-size: 36px;
          margin-bottom: var(--space-xxxxxxxl);
        }
      }

      &__content {

      }

      &__text {
        font-size: 18px;
        line-height: 24px;

        &_large {
          font-size: 24px;
          line-height: 31px;
          @include respond(md) {
            font-size: 18px;
          }
          @include respond(sm) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        &_small {
          font-size: 16px;
          line-height: 21px;
          @include respond(sm) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      &__text-line {
        @include respond-down(sm) {
          &:not(:last-child) {
            margin-bottom: var(--space-xxxs);
          }
        }
      }

      &__text-block {
        margin-top: var(--space-l);
        padding: var(--space-s) var(--space-l) var(--space-l) var(--space-s);
        font-size: 18px;
        line-height: 1.28;

        @include respond(lg) {
          margin-top: var(--space-xxs);
        }
        @include respond(md) {
          padding: var(--space-s) var(--space-l) var(--space-m) var(--space-s);
        }
        @include respond(sm) {
          padding: var(--space-xxxs) var(--space-xxxs) var(--space-xs);
          font-size: 14px;
          line-height: 18px;
        }

        &_border {
          &_dashed {
            border: 1px dashed $color-secondary;
          }
        }
      }

      &__form {
        &.success {
          border: 0;
        }
        &_name {
          &_order {
            @include respond(xl) {
              margin-top: var(--space-xxxxxxxxl);
              padding: var(--space-xxl) var(--space-xxxxxxxl) var(--space-xxxxxxl);
            }

            @include respond(lg) {
              margin-top: 0;
              padding: var(--space-xxl) var(--space-xxxl) var(--space-xxxxxxl);
            }

            @include respond(md) {
              margin-top: var(--space-xxxxs);
              padding: var(--space-xxxxl) var(--space-xxxxxxxxl) var(--space-xl);
            }
            @include respond(sm) {
              margin-top: var(--space-s);
              line-height: 1.2;
            }

            .b-form__title {
              @include respond(sm) {
                font-size: 28px;
              }
            }

            .b-form__subtitle {
              @include respond(lg) {
                margin-left: calc(-1 * var(--space-xxs));
                margin-right: calc(-1 * var(--space-xxs));
              }
              @include respond(md) {
                padding: 0 var(--space-xxxxxl);
              }
            }

            .b-form__body {
              margin-top: var(--space-s);
              @include respond(md) {
                margin-top: 0;
              }
              @include respond(sm) {
                margin-top: var(--space-xl);
              }

              .form-field {
                &:not(:last-child) {
                  margin-bottom: var(--space-xs);
                  @include respond(sm) {
                    margin-bottom: var(--space-xxxs);
                  }
                }

                &.policy-privacy {
                  .label {
                    font-size: 14px;
                    line-height: 1.4;
                    @include respond-down(sm) {
                      font-size: 12px;
                      line-height: 1.25;
                    }
                  }
                }

                input {
                  height: var(--input-xxl);

                  @include respond(sm) {
                    height: var(--input-xl);
                    margin-bottom: var(--space-xxxxs);
                  }
                }
              }

              button {
                margin-top: var(--space-xxs);
                height: var(--input-xxl);
                @include respond-down(md) {
                  display: block;
                  max-width: 300px;
                  margin: var(--space-xs) auto 0;
                  height: var(--input-xl);
                }
              }
            }
          }

          &_subscribe {
            margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxxs));
            padding: var(--space-xxl) var(--space-xxxxxxxxl) var(--space-l);

            @include respond(lg) {
              margin-top: var(--space-xxxxxl);
              padding: var(--space-xl) var(--space-xxxxxxxl) var(--space-xl);
            }

            @include respond(md) {
              margin-top: var(--space-xxxxxxxxxxl);
              padding: var(--space-xxxxxl) var(--space-xxxxxxxxl) var(--space-xxxxl);
            }

            .b-form__title-block {
              @include respond(md) {
                margin: 0 calc(-1 * var(--space-xs));
              }
              @include respond(sm) {
                margin-bottom: var(--space-l);
              }
            }

            @include respond(sm) {
              padding: 0;
              margin-top: var(--space-xxxxxxxxl);

              .b-form__title {
                font-size: 28px;
                line-height: 1.2;
              }
            }

            .b-form__body {
              @include respond(xl) {
                margin-top: var(--space-xxl);
              }
              @include respond(lg) {
                margin-top: var(--space-xxxl);
              }
              @include respond(md) {
                margin-top: var(--space-xxxxl);
              }

              input, .button {
                height: var(--input-xxl);
              }

              .button {
                @include respond-down(lg) {
                  margin-left: auto;
                  margin-right: auto;
                  margin-top: var(--space-xxs);
                  width: 31%;
                }
                @include respond(md) {
                  width: 300px;
                }
                @include respond-down(sm) {
                  margin-top: var(--space-xs);
                  width: 100%;
                  height: var(--input-xl);
                }
              }

              .form-field {
                @include respond(sm) {
                  &:not(:last-child) {
                    margin-bottom: var(--space-xxs);
                  }
                }

                @include respond(md) {
                  &:not(:last-child) {
                    margin-bottom: var(--space-xs);
                  }
                }

                input {
                  @include respond(sm) {
                    height: var(--input-xl);
                  }
                }
              }
            }

            .policy-privacy {
              margin-top: var(--space-s);
              margin-left: 27%;
              @include respond(lg) {
                margin-left: 31%;
              }
              @include respond-down(md) {
                margin-top: var(--space-m);
              }

              .label {
                margin-top: var(--space-xxxxs);
                font-size: 14px;
                line-height: 1.3;

                @include respond(sm) {
                  font-size: 12px;
                  line-height: 1.25;
                }
              }

              @include respond-down(md) {
                margin-left: 0;
              }
            }
          }
        }
      }

      &__section-title {
        margin-top: var(--space-xxxxxxxxxxxl);
        margin-bottom: var(--space-xxxxxxl);
        @include respond(lg) {
          margin-top: var(--space-xxxxxxxxxl);
          margin-bottom: var(--space-xxxxxxl);
        }
        @include respond(sm) {
          margin-top: var(--space-xxxxxxxxl);
          margin-bottom: var(--space-xxxl);
        }
      }

      &__list {

      }

      &__list-item {
        line-height: 1.3;
        @include respond(md) {
          line-height: 1.4;
        }
        @include respond(sm) {
          font-size: 14px;
        }

        &:not(:last-child) {
          margin-bottom: var(--space-xxl);
          @include respond-down(md) {
            margin-bottom: var(--space-l);
          }
          @include respond(sm) {
            margin-bottom: var(--space-s);
          }
        }
      }

      &__table {
        @include respond(sm) {
          width: 100vw;
          margin-left: -20px;
          margin-right: -20px;
        }

        .glide__track {
          margin-bottom: var(--space-xxs);
        }

        .glide__bullets {
          position: relative;
          top: 0;
        }
      }
    }
  }
}