body.personal-page {
  .p-sidebar {
    @at-root {
      &__list {
      }

      &__list-item {
        &_active {
          .p-sidebar__link {
            color: $color-link;
          }
        }

        .p-sidebar__link {
          &:first-child {
            border-top-color: $t;
          }
        }
      }

      &__link {
        display: block;
        padding: var(--space-xs) 0;
        border-bottom: 1px solid $color-secondary;
      }
    }
  }

  .title {
    display: block;

    &_level {
      &_1 {
        margin-bottom: var(--space-xxxxxxxxxl);
      }

      &_2 {
        margin-bottom: var(--space-xxxxxxxl);
      }

      &_3 {
        margin-top: var(--space-xl);
        margin-bottom: var(--space-xxxl);
      }
    }

    &_with-subtitle {
      margin-bottom: 0;
    }
  }

  .subtitle {
    font-size: 18px;
    line-height: 24px;
    margin: var(--space-xs) 0 var(--space-m);
  }

  .section {
    @at-root {
      &_name {
        &_support {
          .button {
            margin-top: var(--space-l);
          }
        }

        &_edit {
          .fl-wrap.fl-wrap-select {
            display: flex;
            align-items: center;
            justify-content: center;

            label {
              opacity: 1;
              position: static;
              margin-right: var(--space-xs);
              padding: 0;
              white-space: nowrap;
              font-size: 16px;
              line-height: 20px;
            }
            select {
              color:  var(--color-secondary);
            }
          }
        }

        &_subscribe-form {
          margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxxs));
        }
      }
    }
  }

  ul.errors {
    position: static;
  }
}

.personal-page__form {
  .fl-wrap-input,
  .fl-wrap-textarea {
    margin-bottom: var(--space-xxs);
  }
}

.products {
  display: flex;
  flex-wrap: wrap;
  & .product__wrap {
      border: 1px solid #9e9e9e;
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      border-radius: 5px;
  }
  & .product__item {
    flex-basis: 33%;
    padding: 20px 10px;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    //margin-bottom: 15px;
    
    // @include respond-down(lg) {

    // }
    & .buttons {
      flex-basis: 100%;
      margin-left: auto;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      a {
        margin: 0 10px;
      }
      svg {
        width: 26px;
        height: 20px;
      }
    }
    & .name {
      flex-basis: 100%;
      margin-bottom: 5px;
      background-color: #4288b7;
      a {
        display: block;
        color: #fff;
        text-align: center;
        padding: 10px;
      }

    }
    & .image {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        height: 170px;
        flex-grow: 1;
        margin-bottom: 20px;
        & a {
          height: 100%;
          max-width: 100%;
          display: block;
          text-align: center;
        }
        & img {
          max-height: 100%;
          max-width: 100%;
        }
    }
  }
}