.js-mobile-menu {
  @include respond-up(lg) {
    &, &_active {
      display: none;
    }
  }

  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: $bg-default;
  overflow-y: scroll;
  padding-right: 15px; // Для того, чтобы скроллбар вплотную не прилегал к контенту

  @at-root {
    &_active {
      @include respond-down(md) {
        display: block;
      }
    }

    &__container {
      display: block;
      width: 100%;
      height: 100%;
      max-height: calc(100vh - 90px);
      padding-top: calc(var(--space-xxxxxxxxl) + var(--space-m));
      overflow-y: scroll;
      @include mobileScrollbar();
      padding-right: 5px; // Для того, чтобы скроллбар вплотную не прилегал к контенту

      .header__auth {
        display: block;
        text-align: center;
        padding-bottom: var(--space-s);
      }

      .header__menu, .header__button {
        display: block;
      }

      .header__menu {
        font-size: 18px;
        text-align: center;
        padding: var(--space-l) 0;

        &_lines {
          border-top: 1px solid $color-greyd2;
          border-bottom: 1px solid $color-greyd2;
        }

        &-item {
          padding-right: 0;

          &:not(:last-child) {
            margin-bottom: var(--space-m);
          }
        }
      }

      .header__button {
        &_add {
          margin-top: var(--space-xxxxxs);
        }
      }
    }

    &__fav {
      margin-bottom: var(--space-xs);

      &:before {

      }
    }

    &__search {
      margin-bottom: var(--space-s);
    }


  }

}