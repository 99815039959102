.b-docs {
  @at-root {
    &__items {
      margin-top: var(--space-xxxxxxl);

      @include respond(lg) {
        margin-top: var(--space-xl);
      }
      @include respond-down(md) {
        margin-top: var(--space-xxxl);
      }
      @include respond-down(sm) {

      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: var(--space-xxxxl);

      @include respond-down(lg) {
        margin-top: var(--space-l);
      }
      @include respond(sm) {
        margin-top:var(--space-xs);
        flex-direction: row;
        align-items: flex-end;
      }
    }


    &__icon {
      @include respond-down(sm) {
        flex: 0 0 47px;
        margin-right: var(--space-s);
        .svg-icon {
          width: 37px;
          height: 48px;
        }
      }
    }

    &__title {
      max-width: 220px;
      margin-top: var(--space-m);
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      @include respond(lg) {

      }
      @include respond-down(md) {
        font-size: 25px;
        line-height: 28px;
      }
      @include respond(sm) {
        text-align: left;
      }
    }

  }
}