.roud-show-graphic {
    padding: 40px 0;
    // .roud-show-graphic__wrap
    &__wrap {
        border: 1px solid $color-black;
        border-radius: 25px;
        padding: 60px 80px;
    }
    // .roud-show-graphic__title
    &__title {
        margin-bottom: 65px;
        text-transform: uppercase;
    }
    // .roud-show-graphic__row
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -35px;
        overflow: hidden;
    }
    // .roud-show-graphic__column
    &__column {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        flex: 0 0 100%;
        padding: 0 35px;
        margin: 0 -10px;
        @media(min-width:600px) {
            flex: 0 0 50%;
            &:first-child {
                border-right: 1px solid $color-grey76;
            }
        }
        @media(min-width:1440px) {
            flex: 0 0 calc(100% / 3);
            &:nth-child(2) {
                border-right: 1px solid $color-grey76;
            }
        }
    }
    // .roud-show-graphic__year
    &__year {
        flex: 0 0 100%;
        margin-bottom: 50px;
        padding: 0 10px;
        font-size: 24px;
        color: $color-grey76;
    }
    // .roud-show-graphic__item
    &__item {
        flex: 0 1 50%;
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        margin-bottom: 40px;
        &_red {
            .roud-show-graphic__item-mount {
                color: #E67B61;
            }
            .roud-show-graphic__item-city {
                color: $color-greyd2;
            }
        }
    }
    // .roud-show-graphic__item-mount
    &__item-mount {
        font-size: 18px;
        color: $color-blue;
        margin-bottom: 5px;
    }
    // .roud-show-graphic__item-city
    &__item-city {
        font-size: 24px;
        font-weight: 700;
    }
}