.roud-show {
    padding: 30px 0;
    &__content {
    }
    &__title {
        margin-bottom: 40px;
        text-transform: uppercase;
    }
    // .roud-show__picker
    &__picker {
        margin-bottom: 40px;
    }
    // .roud-show__name
    &__name {
        margin-left: 30px;
        font-size: 18px;
        line-height: 140%;
        font-weight: 700;
        margin-bottom: 20px;
    }
    // .roud-show__select
    &__select {
        position: relative;
        margin-bottom: 40px;
        select {
            border-radius: 25px;
            background-color: #EAF0F2;
        }
        &::after {
            content: url("data:image/svg+xml,%3Csvg width='13px' height='21px' viewBox='0 0 13 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='32' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F---%D0%A1%D0%BF%D0%BE%D1%80%D1%82-B2B-2' transform='translate(-1803.000000, -1414.000000)' fill-rule='nonzero' stroke='%23000000' stroke-width='2'%3E%3Cg id='%D0%91%D0%BB%D0%B8%D0%B6%D0%B0%D0%B9%D1%88%D0%B8%D0%B5-%D0%BC%D0%B5%D1%80%D0%BE%D0%BF%D1%80%D0%B8%D1%8F%D1%82%D0%B8%D1%8F' transform='translate(-3.000000, 1248.000000)'%3E%3Cg id='Group-17-Copy' transform='translate(1725.000000, 151.000000)'%3E%3Cg id='Group-16' transform='translate(87.000000, 25.000000) scale(-1, 1) translate(-87.000000, -25.000000) translate(62.000000, 0.000000)'%3E%3Cpolyline id='Path-2' points='29.469697 34.9393939 20 25.469697 29.469697 16'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            position: absolute;
            top: 50%;
            transition: all 0.3s ease-in;
            transform: translateY(-50%) rotate(90deg);
            right: 25px;
            z-index: 2;
        }
    }
    // .roud-show__date
    &__date {
        color: $color-blue;
    }
    // .roud-show__option
    &__option {
    }
    &__image {
        @media(min-width:768px) {
            position: relative;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}