.company-list {
  @at-root {
    &__title {
      margin-bottom: 55px;

      h1 {
        align-self: center;

        @include respond-down(md) {
          margin-bottom: 35px;
        }
      }

      br {
        @include respond-down(md) {
          display: none;
        }
      }

      img {
        @include respond-down(md) {
          justify-self: center;
        }
      }
    }
  }
}

.company-filter {
  position: relative;
  height: 100%;
  z-index: 3;
  background: $bg-default;

  @at-root {
    &__container {
      position: relative;
      @include respond(xl) {
        top: var(--space-xl);
        position: sticky;
      }

      @include respond(md) {
        grid-template-rows: repeat(3, 1fr);
        grid-row-gap: var(--space-m);
        align-items: start;
      }

      @include respond(sm) {
        grid-row-gap: 0;
      }

      & > *:first-child {
        margin-bottom: var(--space-xxs);

        @include respond(md) {
          margin-bottom: 0;
          grid-row-start: 1;
          grid-column-start: 4;
          grid-column-end: 7;
        }
      }

      & > *:nth-child(2) {
        margin-bottom: var(--space-xxxl);

        @include respond(md) {
          margin-bottom: 0;
          grid-row-start: 1;
          grid-row-end: 4;
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      & > *:last-child {
        @include respond(md) {
          margin-bottom: 0;
          grid-row-start: 2;
          grid-column-start: 4;
          grid-column-end: 7;
        }
      }
    }

    &__list {
      position: relative;
      padding: 0;
      background: $bg-default;
    }

    &__list-item {
      border-bottom: 1px solid $color-secondary;
      transition: all ease-out .2s;

      @include respond-up(xl) {
        span {
          padding-right: var(--space-xxxs);
        }
      }

      .icon-arrow-4x9_bold {
        display: none;
      }

      &_active {
        padding-left: var(--space-xxs);

        @include respond(sm) {
          padding: 0 0 var(--space-s);

          .company-filter__list-link {
            position: sticky;
            top: calc(-1 * var(--space-l));
            right: 0;
            left: 0;
            z-index: 1;
            border-bottom: 1px solid $color-secondary;
            color: $color-primary;
            background: $bg-default;
          }
        }

        .icon-arrow-4x9_grey {
          display: none;
        }

        .icon-arrow-4x9_bold {
          display: block;
        }
      }

      &:hover {
        padding-left: var(--space-xxs);

        .icon-arrow-4x9_grey {
          display: none;
        }

        .icon-arrow-4x9_bold {
          display: block;
        }
      }

      & > a {
        padding: var(--space-xs) 0;

        @include respond(sm) {
          padding: var(--space-xs) 0;
        }
      }
    }

    &__list-link {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      @include respond(sm) {
        color: $color-secondary;
      }

      &:hover, &_active {
        color: $color-primary;
      }

      &_active {
        cursor: default;
        pointer-events: none;
      }
    }

    &__button-back {
      @include respond(xl) {
        display: none;
      }

      position: absolute;
      top: var(--space-l);
      left: var(--space-l);
      font-size: 12px;
      font-weight: $medium;

      @include respond(lg) {
        min-width: 167px;
      }
    }

    &__catalog-button {
      display: flex;
      height: var(--input-xl);
      line-height: var(--input-xl);
      border-top: 1px solid $bg-border;
      border-bottom: 1px solid $bg-border;
      font-weight: $bold;
      font-size: 16px;

      justify-content: space-between;
      align-items: center;

      &, &:hover, &:active, &:focus {
        color: $color-primary;
      }

      &_active {
        .company-filter__catalog-button-plus {
          &:before {
            opacity: 0;
            transform: rotateZ(90deg);
          }
        }
      }
    }

    &__catalog-button-plus {
      display: flex;
      width: 18px;
      height: 18px;
      justify-content: center;
      align-items: center;

      &:before {
        opacity: 1;
        transform: rotateZ(0);

        content: '';
        display: block;
        position: relative;
        top: 0;
        left: 9px;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: $bg-border;

        transition: all ease .3s;
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: $bg-border;
      }
    }
  }
}

.company_logo_list {
  margin: var(--space-xxs);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - var(--space-m));
}