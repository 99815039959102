.table {
  width: 100%;

  @at-root {
    &__head {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);

      .table__row {
        background: $color-additional;
      }

      .table__data {
        padding-top: var(--space-xs);
        padding-bottom: var(--space-xs);
        font-weight: $bold;
        text-transform: uppercase;

        &, &:first-of-type {
          font-size: 16px;
        }
      }
    }

    &__body {
      .table__row {
        .table__data {
          padding-top: var(--space-s);
          padding-bottom: var(--space-s);

          &:nth-of-type(n+2) {
            font-weight: $bold;
          }
        }
      }
    }

    &__row {
      border-bottom: 2px solid $color-additional;
    }

    &_columns_2 {
      .table__data {
        width: 50%;
      }
    }

    &_columns_5 {
      .table__data {
        width: calc(100% / 5);

        &:first-of-type {
          @include respond(md) {
            width: 26%;
          }
        }
      }
    }

    &__data {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      vertical-align: middle;

      &:first-of-type {
        text-align: left;
        padding-left: var(--space-xxxxl);

        @include respond-down(lg) {
          padding-left: var(--space-xxs);
          font-size: 14px;
        }
        @include respond-down(sm) {
          padding-left: var(--space-xs);
        }
      }
    }
  }
}