.b-form {
  position: relative;
  padding: var(--space-xxxl) var(--space-xxxxxxl);
  border: 1px solid $color-secondary;

  .success-answer{
    display: none;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 3rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;

    h2{
      font-size: 1em;
    }

    p{
      font-size: 0.6em;
      padding: 20px 0px;
    }
  }

  &.success {
    &:after {
      content: 'Спасибо за заявку!';
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $bg-default;
    }

    & > * {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.LearningRegistrationForm{
    &.success {
      .success-answer{
        display: flex;
          opacity: 1;
          pointer-events: auto;
      }
      &:after {
        content: '';
        display: none;
        background: transparent;
      }
    }
  }

  @include respond(lg) {
    padding: var(--space-xxl) var(--space-xxxxxxxl);
  }
  @include respond-down(md) {
    padding: var(--space-xxl) var(--space-xxxxxxxxl);
  }
  @include respond(sm) {
    padding: 0;
    border: none;
  }

  &.grid__fraction {
    @include respond-up(xl) {
      grid-column-start: 2;
      grid-column-end: 12;
    }
  }

  @at-root {
    &__title-block {

    }

    &__title {
      text-align: center;
      @include respond-down(lg) {
        font-size: 36px;
      }
    }

    &__subtitle {
      margin-top: var(--space-xxxxs);
      font-size: 24px;
      line-height: 38px;
      text-align: center;
      @include respond-down(sm) {
        font-size: 14px;
      }

      &_small {
        margin-top: var(--space-xs);
        font-size: 16px;
        line-height: 20px;
        @include respond-down(sm) {

        }
      }
      &_full {
        margin-top: 35px;
        grid-column: span 3;
        text-align: left;
        @include respond-down(sm) {
          font-size: 18px;
        }
      }
    }

    &__body {
      margin-top: var(--space-xxxs);
      @include respond(md) {
        padding-top: var(--space-s);
        width: 87%;
        margin: 0 auto;
      }
      @include respond-down(sm) {
        margin-top: 0;
      }
    }

    &__inputs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &_width {
        &_full {
          .form-field {
            width: 100%;
          }
        }

        &_1-4 {
          .form-field {
            width: 25%;
          }

          .button {
            width: 20%;
          }
        }

        &_lg-1-3 {
          @include respond(lg) {
            .form-field {
              width: calc(33% - var(--space-s));
            }
          }
        }

        &_md-full {
          @include respond(md) {
            .form-field {
              width: 100%;
            }
          }
        }

        &_sm-full {
          @include respond(sm) {
            .form-field {
              width: 100%;
            }
          }
        }


      }
    }

    &__columns {
      margin-bottom: 30px;
      @include respond-up(lg) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      &.no-grid {
        @include respond-up(lg) {
          display: block;
        }
        .b-form__footer {
          align-items: center;
          margin-top: var(--space-xl);
        }
      }

      .b-form__col {
        .b-form__input-group {
          justify-content: flex-end;
          @include respond-up(lg) {
            flex: 0 0 calc(100% / 3);
            .b-form__label {
              max-width: 310px;
            }
          }
        }
      }
    }

    &__input-group {
      display: flex;
      position: relative;
      margin-top: var(--space-l);
      align-items: center;
      justify-content: flex-end;
      margin-right: 10px;
      --full-width: 100%;
      --input-gap: 0;

      @include respond-down(md) {
        margin-top: var(--space-s);
      }

      ul.errors {
        position: absolute;
        bottom: calc(-1 * var(--space-xs));
        left: 0;

        li {
          color: $alert-color;
        }
      }

      &_gap {
        --input-gap: var(--space-xl);
        --full-width: calc(100% - calc(var(--input-gap) * 2));
        @include respond-down(md) {
          --input-gap: 0;
        }

        &.b-form__input-group_xl-width_1-3 {
          --full-width: calc(100% - calc(var(--input-gap) * 2));
        }

        &.b-form__input-group_xl-width_1-4 {
          --full-width: calc(100% - calc(var(--input-gap) * 3));
        }
      }

      @each $name, $content in $grid {
        &_#{$name}-width {
          @include respond(#{$name}) {
            &_1-3 {
              width: calc(var(--full-width) / 3);
            }

            &_2-3 {
              width: calc((var(--full-width) / 3) * 2);
            }

            &_1-4 {
              width: calc(var(--full-width) / 4);
            }

            &_full {
              width: 100%;
            }
          }
        }
      }

      &_type {
        &_checkbox {
          flex-direction: row-reverse;
        }
      }

      &.mtn {
        margin-top: 0;
      }
    }

    &__label {
      margin-left: var(--space-xs);
      cursor: pointer;
      font-size: 14px;
      line-height: 18px;
    }

    &__input {

    }
    &__politic {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: $color-black;
      text-decoration: underline;
    }

    &__footer {
      display: flex;
      grid-area: b;
      flex-direction: column;

      @include respond-up(xl) {
        padding: 0 0 0 var(--space-xxl);
      }
      @include respond(lg) {
        padding: 0 0 0 var(--space-xs);
      }
      @include respond(md) {
        margin-top: var(--space-xxxl);
      }
      @include respond-down(sm) {
        margin-top: var(--space-xl);
      }

      &.corona {
        @include respond-up(xl) {
          padding: 0 0 0 300px;
        }
        @include respond(lg) {
          padding: 0 0 0 250px;
        }
      }

      .b-form__hint {
        margin-top: var(--space-l);
        max-width: 737px;
        @include respond(lg) {
          margin-top: var(--space-xs);
        }
        @include respond-down(lg) {
          align-items: flex-start;
        }
      }

      .b-form__hint-mb {
        margin-bottom: var(--space-l);
        max-width: 737px;
        @include respond(lg) {
          margin-bottom: var(--space-xs);
        }
        @include respond-down(lg) {
          align-items: center;
        }
      }
    }

    &__submit-button {
      width: 372px;
      @include respond-down(md) {
        width: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__hint {
      max-width: 730px;

      &, .b-form__label {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

}