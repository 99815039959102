.theme {
  background: var(--bg-default);
  --grid-gap: 70px;
  --rowGap: 25px;
  @include respond(lg) {
    --grid-gap: 52px;
  }
  @include respond(md) {
    --grid-gap: 52px;
  }
  @include respond(sm) {
    --grid-gap: 30px;
  }
  
  @at-root {
    &_color {
      @each $themeName, $settings in $themes {
        &_#{$themeName} {
          $colors: map-get($settings, "colors");
          @each $color, $value in $colors {
            --#{$color}: #{$value};
            $color: var(--#{$color});
          }
        }
      }
    }

    &_space {
      @each $sizeName, $items in $spaces {
        &_#{$sizeName} {
          @each $name, $size in $items {
            --space-#{$name}: #{$size};
          }
        }
      }
    }

    &_gap {
      &_small {
        --grid-gap: var(--space-s);
      }

      &_default {
        --grid-gap: 70px;
        --rowGap: 25px;
        @include respond(lg) {
          --grid-gap: 52px;
        }
        @include respond(md) {
          --grid-gap: 52px;
        }
        @include respond(sm) {
          --grid-gap: 30px;
        }
      }
    }
  }
}
