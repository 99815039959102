.b-events {
  @at-root {
    &__title-block {
      .section__title {
        max-width: 594px;
        @include respond(md) {
          max-width: 394px;
        }
      }
    }

    &__controls {

    }

    &__slider {
      margin-top: var(--space-xxxxxxxl);
      @include respond(lg) {
        margin-top: var(--space-xxxxxxxxxl);
      }
      @include respond-down(sm) {
        margin-top: var(--space-xxxxl);
      }
    }

    &__list {

    }

    &__button-all {
      display: block;
      width: 206px;
      margin: var(--space-xxxxxxl) auto 0;
      text-align: center;
      @include respond(md) {
        margin: var(--space-xxxxxl) auto 0;
        width: 220px;
      }
      @include respond-down(sm) {
        margin: var(--space-xl) auto 0;
        width: 240px;
      }
    }
  }
}