.hamburger {
  display: none;
  position: relative;
  width: 20px;
  height: 16px;
  flex: 0 0 20px;
  flex-direction: column;
  justify-content: space-between;
  background: $t;
  overflow: hidden;

  @include respond-down(md) {
    display: flex;
  }

  @at-root {
    &_active {
      .hamburger__line {
        &:first-child {
          transform: rotateZ(45deg);
          top: 7px;
        }

        &:nth-of-type(2) {
          left: -20px;
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: rotateZ(-45deg);
          top: 7px;
        }
      }
    }

    &__line {
      display: block;
      position: absolute;
      width: 20px;
      height: 2px;
      top: 0;
      right: 0;
      left: 0;
      border-radius: 1px;
      background: $bg-border;
      transition: all ease-out .2s;

      &:nth-of-type(2) {
        top: 7px;
      }

      &:nth-of-type(3) {
        top: 14px;
      }
    }
  }
}