body.about-page {
  @import "goals";
  @import "opportunities";
  @import "vacancy";

  .title_block {
    font-weight: $bold;
  }

  li {
    line-height: 1.5;
  }
}