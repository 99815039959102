.b-promo-product {
  display: flex;

  .grid {
    align-items: center;
  }

  @at-root {
    &__to-fav {

    }

    &__image-wrapper {
      display: flex;
      height: 360px;

      @include respond(lg) {
        height: 340px;
      }

      @include respond(md) {
        height: 365px;
      }

      @include respond(sm) {
        height: 332px;
        margin-top: var(--space-xxxxxxl);
      }
    }

    &__image {
      display: block;
      width: 100%;
      object-fit: contain;
    }

    &__info {
      @include respond(sm) {
        margin-top: var(--space-xl);
      }
    }

    &__title {
      min-height: 96px;
      margin-top: var(--space-xs);
      font-size: 28px;
      line-height: 32px;

      @include respond-down(lg) {
        margin-top: var(--space-xxxs);
        min-height: 72px;
        font-size: 20px;
        line-height: 24px;
      }

      @include respond-down(md) {
        min-height: auto;
      }

      @include respond(sm) {
        margin-top: var(--space-xxs);
      }
    }

    &__price {
      margin-top: var(--space-l);
      font-size: 40px;

      @include respond-down(lg) {
        margin-top: var(--space-s);
        font-size: 32px;
      }

      @include respond(md) {
      }
    }

    &__to-cart {
      margin-top: var(--space-m);

      @include respond(lg) {
        min-width: 180px;
        margin-top: var(--space-xxxl);
        height: var(--input-xl);
        line-height: calc(var(--input-xl) - 1px);
      }

      @include respond(md) {
        margin-top: var(--space-xl);
      }

      @include respond(sm) {
        width: 100%;
        margin-bottom: var(--space-s);
      }
    }
  }
}