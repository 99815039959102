body.articles-view-page {
  .articles-view{
    display: flex;
    margin-top: var(--space-xxxxxxxxxl);
    @include respond-down(md){
      display: block;
    }
    @include respond(sm){
      margin-top: var(--space-xxxxxl);
    }
    @at-root{
      &__author{
        @include respond(md){
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
        }
        @at-root{
          &__article{
            @at-root{
              &__date{
                display: flex;
                margin-bottom: var(--space-xxxl);
                @include respond(sm){
                  margin-bottom: var(--space-s);
                }
                @at-root{
                  &__number{
                    font-size: 60px;
                    margin-right: var(--space-xxxs);
                  }
                  &__month-and-year{
                    margin-top: var(--space-xxxs);
                  }
                  &__month{
                    font-size: 15px;

                  }
                  &__year{
                    font-size: 12px;

                  }
                }
              }
              &__person{
                display: flex;
                margin-bottom: var(--space-xxxxxl);
                @include respond(sm){
                  display: block;
                  margin-bottom: var(--space-xl);
                }
                @at-root{
                  &__photo{
                    margin-right: var(--space-xs);
                    @include respond(md){
                      display: grid;
                    }
                    @include respond(sm){
                      display: flex;
                    }
                  }
                  &__img{
                    margin-bottom: var(--space-xxs);
                    border-radius: 50%;
                  }
                  &__button{
                    width: 124px;
                    min-width: 124px;
                    height: 24px;
                    padding: 0;
                    text-align: center;
                    border-radius: var(--space-xxxxs);
                    // & .text {
                    //   vertical-align: middle;
                    //   line-height: 24px;
                    //   font-family: $h-font;
                    // }
                    @include respond(sm){
                      margin-top: 50px;
                      margin-left: 20px;
                    }
                  }
                  &__card{

                  }
                  &__info{
                    @at-root{
                      &__name{
                        font-size: 24px;
                        line-height: 28px;
                        margin-bottom: var(--space-xxxs);
                      }
                      &__position{
                        font-size: 14px;
                        line-height: 17px;
                        width: 175px;
                        @include respond(sm){
                          width: 100%;
                        }
                      }
                    }
                  }
                  &__company{
                    margin-top: var(--space-xl);
                    @include respond(sm){
                      margin-top: var(--space-xxs);
                    }
                    @at-root{
                      &__title{
                        margin-bottom: var(--space-xxxs);
                      }
                      &__name{

                      }
                    }
                  }
                }
              }
              &__share{
                display: flex;
                @include respond(md){
                  display: block;
                  padding-bottom: var(--space-xxxl);
                }
                @include respond(sm){
                  display: none;
                }
                @at-root{
                  &__title{
                    margin-right: var(--space-xxxl);
                    font-size: 16px;
                    @include respond(md){
                      margin-bottom: var(--space-xs);
                    }
                  }
                  &__socials{

                  }
                  &__social{
                    display: flex;
                    margin-bottom: var(--space-xxxs);
                    @at-root{
                      &__msgr{
                        margin-right: var(--space-xs);
                      }
                      &__name{
                        font-size: 16px;
                        line-height: 28px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .articles-text{
    padding: 0 350px 0 100px;
    @include respond(lg){
      padding: 0 0 0 100px;
    }
    @include respond-down(md){
      padding: 0 0 0 0;
    }
    @at-root{
      &__title{

      }
      &__img{
        margin: var(--space-xxxl) 0 var(--space-xxxl) 0;
      }
      &__text{
        display: flex;
        margin-bottom: var(--space-m);
        font-size: 16px;
        line-height: 28px;
        @include respond-down(md){
          font-size: 14px;
          line-height: 20px;
        }
        @at-root{
          &__number{
            font-size: 22px;
            line-height: 31px;
            font-weight: bold;
            margin-right: var(--space-xxxs);
            @include respond(sm){
              margin-right: var(--space-xxxs);
              line-height: unset;
            }
          }
        }
      }
      &__subtitle{
        margin: var(--space-xl) 0 var(--space-m) 0;
      }
    }
  }
}