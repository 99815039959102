@import "view";
@import "list";

.b-news-card-vt {
  &
  .card {
    @at-root {
      &__image {
        display: block;
        margin-bottom: var(--space-s);
        width: 100%;
        height: 267px;
        object-fit: cover;

        @include respond(sm) {
          height: 238px;
        }
      }

      &__date {
        margin-bottom: var(--space-xs);
        font-size: 14px;
        font-weight: $medium;
      }

      &__title {
        margin-bottom: var(--space-s);

        @include respond-down(lg) {
          margin-bottom: var(--space-xs);
        }
      }

      &__text {
        font-size: 16px;
        line-height: 1.5;
        @include respond(sm) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      &__detail {
        font-size: 14px;
        margin-top: var(--space-s);

        @include respond(lg) {
          margin-top: var(--space-m);
        }

        @include respond(sm) {
          margin-top: var(--space-m);
        }
      }
    }
  }
}