.full-text-search {
  position: relative;

  @at-root {
    &__button {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      opacity: 0;
    }
  }
}