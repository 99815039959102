/**
  Стрелка
  <div class="arrow arrow_size_default arrow_direction_right"></div> – стрелка вправо с default размерами (50х50)
 */
.arrow {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  color: $bg-border;
  border: 2px solid currentColor;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  cursor: pointer;
  background-color: $bg-default;

  @at-root {
    &_size {
      &_default {
        flex-basis: 50px;
        width: 50px;
        height: 50px;
        @include respond-down(md) {
          flex-basis: 40px;
          width: 40px;
          height: 40px;
        }
      }
    }

    &_direction {
      &_right, &_left {
        background-size: 10px 19px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='13px' height='21px' viewBox='0 0 13 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='32' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Главная---Спорт-B2B-2' transform='translate(-1803.000000, -1414.000000)' fill-rule='nonzero' stroke='%23000000' stroke-width='2'%3E%3Cg id='Ближайшие-мероприятия' transform='translate(-3.000000, 1248.000000)'%3E%3Cg id='Group-17-Copy' transform='translate(1725.000000, 151.000000)'%3E%3Cg id='Group-16' transform='translate(87.000000, 25.000000) scale(-1, 1) translate(-87.000000, -25.000000) translate(62.000000, 0.000000)'%3E%3Cpolyline id='Path-2' points='29.469697 34.9393939 20 25.469697 29.469697 16'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }

      &_right {
        border-left: 1px solid $bg-border;
      }

      &_left {
        border-left: 1px solid $bg-border;
        transform: rotateZ(180deg);
      }
    }

    &_border {
      &_all {
        border: 2px solid $bg-border;
      }
    }
  }
}