.b-possibilities {
  @at-root {
    &_index {

    }

    &__title {
      max-width: 326px;
      font-size: 34px;
      line-height: 42px;

      @include respond(lg) {
        font-size: 28px;
        line-height: 34px;
      }
      @include respond(md) {
        font-size: 24px;
        line-height: 32px;
      }
      @include respond-down(sm) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__list {
      display: flex;
      width: 100%;
      margin-top: var(--space-xxl);
      padding-top: var(--space-m);
      border-top: 2px solid $bg-border;
      justify-content: space-between;
      flex-wrap: wrap;
      @include respond(lg) {
        padding-top: var(--space-xl);
        justify-content: center;
      }
      @include respond(md) {
        margin-top: var(--space-s);
        padding-top: var(--space-xxl);
      }
      @include respond-down(sm) {
        margin-top: var(--space-l);
        padding-right: var(--space-xxxxs);
      }
    }

    &__text {
      font-size: 20px;
      line-height: 24px;
      @include respond-down(lg) {
        max-width: 360px;
        font-size: 18px;
        line-height: 24px;
      }
      @include respond(md) {
        max-width: 313px;
      }
      @include respond-down(sm) {
        max-width: none;
      }
    }

    &__list-item {
      @include respond(xl) {
        max-width: 240px;
      }
      @include respond(lg) {
        max-width: none;
        flex: 0 0 33%;
        &:nth-of-type(4), &:nth-of-type(5) {
          margin-top: var(--space-xxxxl);
        }
      }
      @include respond(md) {
        flex: 0 0 calc(50% - calc(var(--grid-gap) / 2));
        min-height: 72px;
        margin-bottom: var(--space-xs);
      }
      @include respond-down(sm) {
        &:not(:last-child) {
          margin-bottom: var(--space-s);
        }
      }
    }
  }
}