.button {
  display: inline-block;
  min-width: 200px;
  height: 50px;
  background: $bg-accent;
  padding: 0 var(--space-s);

  &:hover {
    background: var(--bg-button-hover);
  }

  @include respond-down(sm) {
    padding: 0 var(--space-xxs);
  }
  @at-root {
    &_rounded {
      border-radius: 5px;
    }

    &_icon {
      &_plus, &_fav, &_window, &_cart, &_squares, &_eye, &_back {
        position: relative;
        padding: 0 var(--space-s) 0 var(--space-xs);
        @include respond-down(sm) {
          padding: 0 0 0 var(--space-xxs);
        }

        &:before {
          content: '';
          display: inline-block;
          flex-shrink: 0;
          flex-grow: 0;
          top: 50%;
          margin-right: var(--space-xxs);
          background-repeat: no-repeat;
          background-size: contain;

          @include respond-down(sm) {
            margin-right: var(--space-xxxxs);
          }
        }
      }

      &_plus {
        &:before {
          content: '';
          width: 23px;
          height: 23px;
          flex-basis: 23px;
          border-radius: 50%;
          line-height: 1;
          transform: translateY(6px);
          @include image-ico('plus-in-circle', '23px', '23px');
        }
      }

      &_fav {
        color: $color-primary;

        &:before {
          content: '';
          width: 15px;
          height: 13px;
          margin-right: var(--space-xxxxs);
          flex-basis: 15px;
          line-height: 1;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url('../svg/fav-heart-white.svg');
          @include respond-down(md) {
            width: 17px;
            height: 15px;
            transform: translateY(3px);
          }
        }
      }

      &_window {
        &:hover {
          color: $color-white;
        }

        &:before {
          width: 18px;
          height: 17px;
          background-image: url('../svg/window-icon.svg');
          transform: translate(3px, 3px);
        }
      }

      &_cart {
        &:before {
          width: 28px;
          height: 23px;
          background-image: url('../svg/cart-white.svg');
          transform: translateY(5px);
        }
      }

      &_squares {
        &:before {
          width: 14px;
          height: 15px;
          flex-basis: 14px;
          transform: translateY(3px);
          background-image: url('../svg/squares.svg');
        }
      }

      &_eye {
        &:before {
          width: 20px;
          height: 12px;
          flex-basis: 20px;
          transform: translateY(1px);
          background-image: url('../svg/eye.svg');
        }
      }

      &_back {
        &:before {
          width: 7px;
          height: 11px;
          flex-basis: 20px;
          transform: translateY(1px) rotateZ(-180deg);
          background-image: url('../svg/arrow-4x9.svg');
        }
      }
    }

    &_view {
      &_accent {
        background: $bg-accent;
        transition: color ease-out .2s;

        &:hover {
          color: $color-white;
        }
      }

      &_black {
        background: $bg-border;
        transition: background ease-out .2s;

        &:hover {
          background: rgba(0, 0, 0, .9);
        }
      }

      &_white {
        background: $bg-white;

        &, &:hover, &:active, &:focus {
          color: $bg-accent;
          background: $bg-white;
        }
      }

      &_transparent {
        background: $t;

        &, &:hover, &:active, &:focus {
          color: $bg-accent;
          background: $t;
        }
      }

      &_border_secondary {
        background: $t;
        transition: background  ease-out .2s;
        border: 2px solid $color-secondary;

        &, .text {
          color: $color-secondary;
        }

        &_active, &:active, &:hover, &:focus {
          background: $color-secondary;

          &, .text {
            color: $bg-white;
          }
        }

        &_hover_primary {
          transition: border ease-out .2s;
          &_active, &:active, &:hover, &:focus {
            background: $t;
            border: 2px solid $color-primary;

            &, .text {
              color: $color-primary;
            }
          }
        }
      }

      &_fill_secondary {
        background: $color-secondary;
        transition: background ease-out .2s;
        border: 2px solid $color-secondary;

        &, .text {
          color: $bg-white;
        }

        &_active, &:active, &:hover, &:focus {
          background: $bg-white;

          &, .text {
            color: $color-secondary;
          }
        }
      }
    }

    &_size {
      @each $name, $height in $inputSizes {
        &_#{$name} {
          height: #{$height};
          line-height: calc(#{$height} - 1px);
        }
      }

      &_xxl {
        font-size: 14px;
        @include respond-down(lg) {
          font-size: 12px;
          padding: 0 var(--space-xs);
        }
      }

      &_l {
        font-size: 12px;
      }

      &_xs {
        font-size: 12px;
      }
    }

    &_width {
      &_full {
        width: 100%;
      }
    }

    &_border {
      &_all {
        border: 1px solid $color-additional;

        &.button_view_white {
          border: 1px solid $color-black;
        }
      }
    }

    &_align {
      &_center {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
