body.events-view-page {
  .event {
    @at-root {
      /**
         Hero todo перенеси куда-нибудь
      */
      &__hero {
        display: flex;
        width: 100%;
        height: 766px;
        position: relative;
        @include respond(lg) {
          height: 622px;
        }
        @include respond(md) {
          height: 561px;
        }
        @include respond(sm) {
          min-height: 450px;
          height: 100%;
        }
      }

      &__hero-bg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
        user-select: none;
        @include respond(sm) {
          height: 216px;
        }
      }

      &__hero-text {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-top: calc(var(--space-xxxxxxxxxxl) + var(--space-xxxxs));
        @include respond(lg) {
          padding-top: var(--space-xxxxxxxxl);
        }
        @include respond(md) {
          padding-top: var(--space-xxxxxl);
        }
        @include respond(sm) {
          padding-top: var(--space-xl);
        }
      }

      &__hero-title {
        @include textLargest;
        @include respond(md) {
          font-size: 36px;
        }
        @include respond(sm) {
          height: 65px;
          font-size: 28px;
          line-height: 1.25;
        }
      }

      &__hero-subtitle {
        min-height:20px;
        //min-height: calc(var(--space-xxxxxxxxxxl) + var(--space-xxxl));
        max-width: 64%;
        margin-top: var(--space-s);
        font-size: 32px;
        line-height: 41px;

        @include respond(lg) {
          min-height:20px;
          //min-height: var(--space-xxxxxxxxxxl);
          max-width: 65%;
          font-size: 28px;
          line-height: 36px;
        }
        @include respond(md) {
          min-height:20px;
          //min-height: var(--space-xxxxxxxxxl);
          max-width: none;
          font-size: 24px;
          line-height: 30px;
        }
        @include respond(sm) {
          margin-top: var(--space-xxs);
          max-width: none;
          //min-height:20px;
          min-height: var(--space-xxxxxxxxxl);
          max-height: var(--space-xxxxxxxxxl);
          overflow-y: scroll;
          font-size: 17px;
          line-height: 19px;
        }
      }

      &__hero-date-wrapper {
        display: block;
        margin-top: var(--space-m);
        width: 100%;
        @include respond(lg) {
          margin-top: var(--space-xs);
        }
        @include respond-down(sm) {
          margin-top: var(--space-s);
        }

        &:before {
          content: '';
          display: block;
          max-width: 443px;
          width: 100%;
          height: 6px;
          background: $color-white
        }

        @include respond(sm) {
          &:before {
            display: none;
          }
        }
      }

      &__hero-dates {
        margin-top: var(--space-m);
        font-size: 28px;
        white-space: nowrap;
        @include respond(lg) {
          margin-top: var(--space-l);
          font-size: 25px;
        }
        @include respond(md) {
          font-size: 24px;
        }
        @include respond(sm) {
          margin-top: var(--space-s);
          font-size: 18px;
          color: var(--color-default);
        }
      }

      &__hero-location {
        margin-top: var(--space-s);

        .icon-plus {
          &__icon {
            color: $bg-white;
            @include respond(sm) {
              margin-right: var(--space-xxxs);
              color: $bg-accent;
              .svg-icon {
                width: 18px;
              }
            }
          }
        }

        .text {
          font-size: 25px;
          line-height: 32px;
          @include respond(md) {
            font-size: 24px;
            line-height: 30px;
          }
          @include respond(sm) {
            font-size: 16px;
            line-height: 20px;
            color: var(--color-default);
          }
        }
      }

      &__hero-register-button {
        width: 234px;
        margin-top: var(--space-l);

        &.button_view_black {
          display: none;
        }

        @include respond(lg) {

        }
        @include respond(sm) {
          display: none;
          &.button_view_black {
            display: block;
            width: 100%;
            margin-top: var(--space-m);
          }
        }
      }


      /**
        Sections
       */
      &__sections {
        @include respond(md){
          overflow: hidden;
        }
      }
      &__section {
        &__some-text {
          font-size: 26px;
          line-height: 150%;
        }
        &_name {
          &_themes {
            padding-top: calc(var(--space-xxxxxxxxxxl) + var(--space-m));
            padding-bottom: calc(var(--space-xxxxxxxxxxl) + var(--space-m));
            @include respond(lg) {
              padding-top: var(--space-xxxxxxxxxxl);
              padding-bottom: var(--space-xxxxxxxl);
            }
            @include respond(md) {
              padding-top: var(--space-xxxxxxxxl);
              padding-bottom: var(--space-xxxxl);
            }
            @include respond(sm) {
              padding-top: var(--space-xxxl);
              padding-bottom: var(--space-xxxxl);
            }
          }

          &_schedule {
            padding-top: var(--space-l);
            @include respond-down(sm) {
              padding-top: var(--space-xxxxxxxxl);
            }

            .event__section-title {
              word-break: break-word;
              margin-bottom: calc(var(--space-xxxxxxxxxl) - var(--space-xxxxs));
              @include respond(lg) {
                margin-bottom: var(--space-xxxxxxxxxl);
              }
              @include respond(md) {
                margin-bottom: var(--space-xxxxxxl);
              }
              @include respond-down(sm) {
                margin-bottom: var(--space-xxxxxxl);
              }
            }
          }

          &_exponents {

          }

          &_whom-we-see {

          }

          &_phone {
            margin: calc(var(--space-xxxxxxxxxxl) + var(--space-xxl)) 0 var(--space-xxxxxxxxxl);
            @include respond(lg) {
              margin: var(--space-xxxxxxxxxxxl) 0 var(--space-xxxxxxxxxl);
            }
            @include respond-down(md) {
              margin: calc(var(--space-xxxxxxxxxxl)) 0 var(--space-xxxxxl);
            }
            @include respond-down(sm) {
              margin: var(--space-xxxxxxxxxl) 0 var(--space-xl);
            }

            .phone-num {
              display: flex;
              flex-direction: column;
            }

            .phone-num__title {
              font-size: 48px;
              @include respond(lg) {
                font-size: 36px;
              }
              @include respond(md) {
                font-size: 32px;
              }
              @include respond(sm) {
                font-size: 18px;
              }
            }

            .phone-num__phone {
              margin-top: var(--space-s);
              font-size: 72px;
              @include respond(lg) {
                font-size: 60px;
              }
              @include respond(md) {
                font-size: 48px;
              }
              @include respond-down(sm) {
                margin-top: var(--space-xs);
                font-size: 32px;
              }
            }

          }

          &_speakers {
            margin: var(--space-xxxxxxxl) 0 0;
            padding: var(--space-xxxxxxxxxl) 0 var(--space-xxxxxxxxxxl);

            @include respond(lg) {
              margin: var(--space-xxxxxxxxxxxxl) 0 0;
              padding: var(--space-xxxxxxxxl) 0 var(--space-xxxxxxxxl);
            }
            @include respond-down(md) {
              margin: var(--space-xxl) 0 0;
              padding: var(--space-xxxxxxxxl) 0 var(--space-xxxxxxxxl);
            }
            @include respond-down(sm) {
              margin: 0;
              padding: var(--space-xxxl) 0 var(--space-xxxxxxxxl);
            }

            .glide__bullets {
              @include respond-down(sm) {
                display: inline-flex;
                flex-wrap: wrap;
                bottom: auto;
                position: relative;
                flex-grow: 1;
                left: 0;
                right: 0;
                transform: none;
                & .glide__bullet {
                  margin-bottom: 5px;
                }
              }
            }

            .title-w-controls {
              margin-bottom: calc(var(--space-xxxxxxxxxxl) + var(--space-s));

              @include respond(lg) {
                margin-bottom: var(--space-xxxxxxxxxxl);
              }
              @include respond-down(md) {
                margin-bottom: var(--space-xxxxxxxl);
              }
              @include respond-down(sm) {
                margin-bottom: var(--space-xxxl);
              }
            }
          }

          &_partners {
            padding-top: calc(var(--space-xxxxxxxxxxl) + var(--space-xxxs));
            padding-bottom: var(--space-xxxxxxxxxxl);

            @include respond(lg) {
              padding-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxs));
            }
            @include respond-down(md) {
              padding-top: var(--space-xxxxxxxxl);
            }
            @include respond-down(sm) {
              padding-bottom: var(--space-s);
            }
          }

          &_exponents {
            padding-top: var(--space-xxxxxxxxxxl);
            @include respond(lg) {
              padding-top: var(--space-xxxl);
            }
            @include respond(md) {
              padding-top: var(--space-l);
            }
            @include respond-down(sm) {
              padding-top: var(--space-xxxxxxxl);
            }

            .glide__bullets {
              @include respond-down(sm) {
                flex-wrap: wrap;
                display: inline-flex;
                position: relative;
                margin-top: var(--space-xxxxxxxl);
                transform: translateX(-50%);
                & .glide__bullet {
                  margin-bottom: 5px;
                }
              }
            }

            .title-w-controls {
              margin-bottom: var(--space-xxxxxxxxxl);
              @include respond(lg) {
                margin-bottom: var(--space-xxxxxxxxxxxl);
              }
              @include respond-down(sm) {
                margin-bottom: var(--space-xxxxxxxl);
              }
            }
          }

          &_participant_offline{
            padding-top: var(--space-xxxxxxxxxxl);
            @include respond(lg) {
              padding-top: var(--space-xxxl);
            }
            @include respond(md) {
              padding-top: var(--space-l);
            }
            @include respond-down(sm) {
              padding-top: var(--space-xxxxxxxl);
            }

            display: none;


            .navigation-links{

              &__item{
                color: $color-secondary;
                text-decoration-line: underline;
                display: inline-block;
                margin-right: 20px;

                &.active{
                  font-weight: bold;
                  color: $color-black;
                  text-decoration-line: none;

                }
              }
            }

            .glide__bullets {
              @include respond-down(sm) {
                display: inline-flex;
                position: relative;
                margin-top: var(--space-xxxxxxxl);
                transform: translateX(-50%);
              }
            }

            .title-w-controls {
              margin-bottom: var(--space-xxxxxl);
              @include respond(lg) {
                margin-bottom: var(--space-xxxxl);
              }
              @include respond-down(sm) {
                margin-bottom: var(--space-xxxxl);
              }
            }
          }

          &_participant_online{
            padding-top: var(--space-xxxxxxxxxxl);
            @include respond(lg) {
              padding-top: var(--space-xxxl);
            }
            @include respond(md) {
              padding-top: var(--space-l);
            }
            @include respond-down(sm) {
              padding-top: var(--space-xxxxxxxl);
            }


            .navigation-links{

              &__item{
                color: $color-secondary;
                text-decoration-line: underline;
                display: inline-block;
                margin-right: 20px;

                &.active{
                  font-weight: bold;
                  color: $color-black;
                  text-decoration-line: none;

                }
              }
            }

            .glide__bullets {
              @include respond-down(sm) {
                display: inline-flex;
                position: relative;
                margin-top: var(--space-xxxxxxxl);
                transform: translateX(-50%);
              }
            }

            .title-w-controls {
              margin-bottom: var(--space-xxxxxl);
              @include respond(lg) {
                margin-bottom: var(--space-xxxxl);
              }
              @include respond-down(sm) {
                margin-bottom: var(--space-xxxxl);
              }
            }
          }

          &_purchases {
            margin-top: var(--space-xxs);
            padding-top: var(--space-xxxxxxxxxxl);


            @include respond(lg) {
              padding-top: var(--space-xxxxxxxxxl);
            }
            @include respond-down(md) {
              padding-top: var(--space-xxxxxxxxl);
            }

            .glide__bullets {
              @include respond-down(sm) {
                flex-wrap: wrap;
                display: inline-flex;
                position: relative;
                margin-top: var(--space-xxxxxxxl);
                transform: translateX(-50%);
                & .glide__bullet {
                  margin-bottom: 5px;
                }
              }
            }

            .title-w-controls {
              margin-bottom: var(--space-xs);
              @include respond(lg) {
                margin-bottom: var(--space-m);
              }
              @include respond-down(sm) {
                margin-bottom: var(--space-xl);
              }
            }
          }

          &_how-it-was {
            margin-top: var(--space-s);
            padding-top: var(--space-xxxxxxxxxxl);
            @include respond-down(md) {
              padding-top: var(--space-xxxxxxxl)
            }

            .grid__fraction_name_video {
              grid-column-end: -1;
            }
          }

          &_whom-we-see {
            margin-top: calc(var(--space-xxxxxxxxxxl) + var(--space-xxxxxxxl));
            @include respond(lg) {
              margin-top: var(--space-xxxxxxxl);
            }
            @include respond-down(md) {
              margin-top: var(--space-xxxxxl);
            }
            @include respond-down(sm) {
              margin-top: var(--space-xxxl);
            }

            .event__section-wrapper {
              padding-top: var(--space-xxxxxxxxxl);
              padding-bottom: var(--space-xxxxxxxxxl);
              @include respond(lg) {
                padding-top: var(--space-xxxxxxxxl);
                padding-bottom: var(--space-xxxxxxxxxl);
              }
              @include respond-down(md) {
                padding-top: var(--space-xxxxxxxxl);
                padding-bottom: var(--space-xl);
              }
              @include respond-down(sm) {
                padding-top: var(--space-xxxxxl);
              }
            }
          }

          &_scheme {
            padding-top: var(--space-xxxxxxxxxxxxl);

            @include respond(lg) {
              padding-top: var(--space-xxxxxxxxxxl)
            }

            @include respond-down(md) {
              padding-top: var(--space-xxxxxxxxxl);
            }

            @include respond-down(sm) {
              padding-top: var(--space-xxxxxxxl);
            }
          }

          &_price {
            margin-top: var(--space-xxxxxxxxxxxxl);
            padding-top: var(--space-xxxxxxxxxxxxl);
            @include respond-down(lg) {
              margin-top: 0;
              padding-top: 0;
            }
            @include respond-down(sm) {
              padding-top: var(--space-xxxxxxxxl);
            }

            .event__section-title {
              margin-bottom: var(--space-xxxxxxxxxxxxl);
              @include respond(lg) {
                margin-bottom: var(--space-xxxxxxxxl);
              }
              @include respond-down(md) {
                margin-bottom: var(--space-xxxl);
              }
              @include respond-down(sm) {
                margin-bottom: var(--space-xxl);
              }
            }
          }

          &_docs {
            margin-top: var(--space-xxxxxxxl);
            padding-top: var(--space-xxxxxxxxl);
            @include respond(lg) {
              margin-top: var(--space-l);
            }
            @include respond-down(md) {
              margin-top: 0;
              padding-top: var(--space-xxxxxxl);
            }
          }

          &_other-events {
            padding-top: var(--space-xxxxxxxxxxxxl);
            padding-bottom: var(--space-xxxxxxxxl);
            margin-top: var(--space-m);
            @include respond(lg) {
              padding-top: var(--space-xxxxxxxxl);
            }
            @include respond-down(md) {
              padding-top: var(--space-xxxxxxxxxxxxl);
              padding-bottom: var(--space-xxxxxxxxl);
              margin-top: var(--space-xxl);
            }
            @include respond-down(sm) {
              padding-top: var(--space-l);
            }
          }

          &_info {
            margin-top: var(--space-xxxxxl);
            @include respond-down(sm) {
              margin-top: var(--space-xxxxxxxxl);
            }
          }

          &_program_text {
            padding-top: 120px;

            @include respond-down(sm) {
              padding-top: 40px;
            }

            &.small {
              padding-top: 80px;

              @include respond-down(sm) {
                padding-top: 40px;
              }
            }

            p {
              margin-bottom: 25px;
              font-size: 22px;
              line-height: 30px;

              @include respond-down(md) {
                font-size: 20px;
                line-height: 28px;
              }
              @include respond-down(sm) {
                font-size: 18px;
                line-height: 24px;
              }
            }
          }
        }
      }

      &__section-wrapper {
        overflow: hidden;

        @include respond(md){
          overflow: visible;
        }
      }

      &__section-title {
        @include textLargest;
        @include respond-down(sm) {
          font-size: 33px;
        }
      }

      &__section-description {
        font-size: 16px;
        line-height: 1.5;
        &_p {
          font-size: 20px;
          padding-left: 10px;
          margin-top: calc(var(--space-xxxxl) - var(--space-xxxxs));
          margin-bottom: calc(var(--space-xxxxxxxl) - var(--space-xxxxs));
          color: var(--color-secondary)
        }

        @include respond(sm) {
          padding-top: var(--space-xxxxs);
          font-size: 12px;
        }
      }

      &__section-arrows {

      }

      &__section-slides-count {

      }

      &__section-content {

      }

      /**
        todo: перенеси куда-нибудь
       */
      &__list {
        &_name {
          &_sections {
            display: flex;
            margin: calc(var(--space-xxxxxxxxxxl) + var(--space-s)) 0 0;
            justify-content: space-between;
            flex-wrap: wrap;

            @include respond(lg) {
              margin: var(--space-xxxxxxxxxxxl) 0 0;
              justify-content: center;
            }
            @include respond(md) {
              margin: var(--space-xxxxxxxxxxl) 0 0;
            }

            .list__item {
              flex: 0 0 20%;
              max-width: 300px;

              @include respond(lg) {
                flex: 0 0 33%;
                max-width: 350px;
                &:nth-child(n+4) {
                  margin-top: var(--space-xxxxxxxxxxl);
                }
              }
              @include respond(md) {
                flex: 0 0 50%;
                justify-content: flex-start;
                &:nth-child(-n+3) {
                  margin-bottom: var(--space-xxxxxl);
                }
              }
              @include respond(sm) {
                flex: 0 0 100%;
              }
            }

            .list__image-wrapper {
              height: 300px;
            }

            .list__image {
              width: initial;
            }

            .list__title {
              min-height: 70px;
              margin-top: var(--space-s);
              margin-bottom: var(--space-s);
              font-size: 18px;
              line-height: 23px;

            }
          }
        }
      }

      /**
      todo: перенеси куда-нибудь
       */
      &__sm-slider {
        margin-top: var(--space-xxs);
        @include respond-down(sm) {
          margin-top: var(--space-m);
        }

        .list__image-wrapper {
          height: 300px;
        }

        .list__image {
          width: initial;
        }

        .list__title {
          height: 82px;
          margin-top: var(--space-xs);
          font-size: 18px;
          line-height: 23px;
          @include respond-down(sm) {
            margin-top: var(--space-m);
          }
        }

        .glide__bullets {
          bottom: calc(-1 * var(--space-xxxxl));
        }
      }
    }
  }
}