body.news-view-page {

  .news-list--inner {
    margin-bottom: var(--space-xxxxxxxxxxl);
  }
  .news-list__item {
    @include respond-down(md) {
      margin-bottom: var(--space-xxl);
    }
    
  }
  .view-list {
    @at-root {
      &__main {
        @include respond(xl) {
          grid-column-start: 3;
          grid-column-end: 11;
        }

        @include respond(lg) {
          grid-column-start: 2;
          grid-column-end: 12;
        }
      }

      &__page-title {
        margin-top: var(--space-xxxxxxxxxl);
        margin-bottom: var(--space-xxxxl);
      }

      &__image {
        width: 100%;
        margin-bottom: var(--space-xxxxl);
      }
      &__advantages {
        line-height: 28px;
        margin-bottom: var(--space-xxxxxxl);

        h2, h3, h4, h5 {
          margin-top: var(--space-xxxxxxl);
          margin-bottom: var(--space-xl);
        }

        ol {
          list-style: decimal;
        }

        li {
          margin-bottom: var(--space-s);
          list-style-position: inside;

          &::marker {
            font-size: 22px;
            font-weight: bold;
            padding-right: var(--space-xxl)
          }
        }

        @at-root{
          &__title{
            margin-bottom: var(--space-xl);
          }
          &__text{
            display: flex;
            font-size: 16px;
            line-height: 28px;
            margin-bottom: var(--space-s);
            @at-root{
              &__title{
                margin-bottom: var(--space-xxxl);
              }
              &__number{
                font-size: 22px;
                line-height: 31px;
                font-weight: bold;
                margin-right: var(--space-xxxl);
              }
            }

          }
        }
      }
    }
  }
}