.b-event {
  @at-root {
    &__time {
      display: flex;
      align-items: center;
      padding-bottom: var(--space-xs);
      font-size: 15px;
      @include respond-down(lg) {
        font-size: 14px;
      }
      @include respond-down(sm) {
        padding-bottom: var(--space-s);
      }

      .svg-icon {
        flex: 0 0 20px;
        margin-right: var(--space-xxxxs);
      }
    }

    &__title-block {
      display: flex;
      padding-top: var(--space-s);
      border-top: 1px solid $bg-border;
      justify-content: space-between;
      align-items: center;
      @include respond(lg) {
        padding-top: var(--space-xxxs);
      }
      @include respond-down(sm) {
        align-items: flex-start;
      }

      .event__date {
        padding-left: var(--space-xxs);
        @include respond-down(sm) {
          padding-left: 0;
        }
      }
    }
    &__date-delimetr {
      position: relative;
      display: inline-block;
      width: 10px;
      margin: 0 3px;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 10px;
        height: 1px;
        background: #000;
      }
    }

    &__date {
      align-self: flex-start;
      display: flex;
      margin-right: 10px;
      &_big {
        font-size: 56px;
        @include respond-down(md) {
          font-size: 42px;
        }
        @include respond-down(sm) {
          font-size: 38px;
        }

        & ~ .event__date_small {
          margin-top: var(--space-xxxxs);
          @include respond(lg) {
            margin-top: var(--space-xxxs);
          }
          @include respond(md) {
            margin-top: var(--space-xxxs);
          }
        }
      }

      &_small {
        font-size: 15px;
        @include respond-down(md) {
          font-size: 14px;
        }
        @include respond-down(sm) {
          font-size: 10px;
        }
      }
    }

    &__title {
      max-width: 72%;
      font-size: 23px;
      line-height: 24px;
      @include respond(lg) {
        font-size: 20px;
        max-width: 73%;
        padding-right: var(--space-xxxxxxxl);
      }
      @include respond-down(md) {
        font-size: 16px;
        line-height: 18px;
      }
      @include respond(md) {
        max-width: 74%;
      }
      @include respond-down(sm) {
        max-width: 78%;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__image-wrapper {
      display: flex;
      margin-top: var(--space-s);
      @include respond(lg) {
        margin-top: var(--space-xs);
      }
      @include respond(md) {
        margin-top: var(--space-xs);
      }
    }

    &__image {
      display: block;
      object-fit: cover;
      width: 100%;
      max-height: 360px;
      @include respond(md) {
        height: 205px;
      }
      @include respond-down(sm) {
        height: 190px;
      }
    }
  }
}