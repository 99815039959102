.icon {
  @at-root {
    &_name {
      &_fav {
        display: inline-block;
        width: 25px;
        height: 22px;
        color: $t;
        --strokeColor: var(--bg-border);
        transition: transform ease .1s;

        &:focus, &:active, &_active {
          transform: scale(1.08);
        }

        //@include respond(lg) {
        //  .svg-icon {
        //    height: 18px;
        //    width: 19px;
        //  }
        //}

        &:hover, &_active {
          color: $alert-color;

          .svg-icon {
            --strokeColor: currentColor;
          }
        }
      }
    }
  }
}