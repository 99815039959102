.text {
  color: $color-primary;

  @at-root {
    &_align {
      &_left {
        text-align: left;
      }

      &_center {
        text-align: center;
      }

      &_right {
        text-align: right;
      }
    }

    &_type {

    }

    &_view {
      &_primary {
        color: var(--color-primary);
      }

      &_secondary {
        color: var(--color-secondary);
      }

      &_additional {
        color: var(--color-additional);
      }

      &_link {
        color: var(--color-link);
      }

      &_white {
        color: #fff;
      }
    }

    &_weight {
      &_regular, &_normal {
        font-weight: $normal;
      }

      &_light {
        font-weight: $light;
      }

      &_medium {
        font-weight: $medium;
      }

      &_bold {
        font-weight: $bold;
      }
    }

    &_transform {
      &_upper, &_uppercase {
        text-transform: uppercase;
      }

      &_lower {
        text-transform: lowercase;
      }

      &_capitalize {
        text-transform: capitalize;
      }
    }

    &_size {
      @for $size from 9 through 23 {
        &_#{$size} {
          font-size: $size+px;
        }
      }
    }

    &_decoration {
      &_underline {
        text-decoration: underline;
      }
    }
  }
}

strong, b{
  font-weight: bold;
}

u{
  text-decoration: underline;
}

i, em {
  font-style: italic;
}