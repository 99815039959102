.modal {
  display: flex;
  width: 100%;
  max-width: 400px;
  align-items: center;
  flex-direction: column;

  @at-root {
    &__title-block {
      margin-bottom: var(--space-m);
    }

    &__title {

    }

    &__subtitle {
      margin-top: var(--space-xxs);
    }

    &__form {
      width: 100%;

      &.success {
        position: relative;

        & > * {
          opacity: 0;
          pointer-events: none;

        }

        &:after {
          content: 'Спасибо! Ваша заявка успешно отправлена.';
          display: flex;
          position: absolute;
          padding-top: var(--space-xl);
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          align-items: flex-start;
          justify-content: center;
          text-align: center;
          font-size: 2rem;
          background: $bg-default;
          height: auto;
        }
      }
    }
  }
}