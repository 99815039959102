// Прелоадер для незагрузившихся картинок.
img.lazyload {
  &, &[src=""] {
    background: $bg-default url('../svg/preloader.svg') no-repeat center center;
    background-size: 50px;
    text-indent: -10000px;
  }

  &.loaded:not([src=""]), &[data-was-processed="true"] {
    background-image: none;
  }
}