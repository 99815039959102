.b-line {
    position: relative;
    padding-top: 70px;
    padding-bottom: 40px;
    overflow: hidden;
    max-width: unset;
    margin-bottom: 50px;
    // .b-line__wrap
    &__wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -50px;
        overflow: hidden;
    }
    // .b-line__item
    &__item {
        display: flex;
        align-items: center;
        padding: 0 50px;
        margin-bottom: 30px;
    }
    // .b-line__icon
    &__icon {
        margin-right: 30px;
    }
    // .b-line__text
    &__text {
        font-size: 20px;
        font-weight: 700;
        color: var(--color-primary);
    }
}