.b-schemes {
  @at-root {
    &__title {

    }

    &__images {
      display: flex;
      margin-top: var(--space-xxxxxxxxxxxxl);
      width: 100%;
      height: 100%;

      @include respond-down(md) {
        margin-top: var(--space-xxxxxxxl);
        flex-direction: column;
      }
    }

    &__image {
      width: 100%;
      max-height: 700px;
      height: 100%;
      object-fit: contain;

      &:not(:last-child) {
        @include respond-down(md) {
          margin-bottom: var(--space-l);
        }
      }
    }
  }
}