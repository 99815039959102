.js-show-more {
  position: relative;

  @at-root {
    &__link {
      display: inline-block;
      margin-top: var(--space-xxs);
      font-weight: $bold;

      &_hidden {
        display: none;
      }
    }

    &__content {
      max-height: 400px;
      overflow: hidden;
      @include respond(lg) {
        max-height: 330px;
      }
      @include respond(md){
        max-height: 210px;
      }
      @include respond(sm){
        max-height: 330px;
      }

      &_active {
        overflow: visible;
        max-height: none;

        & > {
        }
      }
    }
  }
}