.triangle {
  &, &::after, &::before {
    display: flex;
    position: relative;
    width: 528px;
    height: 418px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    z-index: 2;
    @include respond-down(md) {
      width: 541px;
      height: 428px;
    }
    @include respond-down(sm) {
      max-width: 541px;
      width: 100%;
      height: 282px;
    }
  }

  &_align {
    &_center {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: initial;
    height: initial;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background: $bg-default;
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    width: initial;
    height: initial;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background: $bg-border;
    z-index: 1;
  }

  @at-root {
    &_size {
      &_4 {
        & > .triangle__item {
          height: calc(57.554% / 3);

          &:first-of-type {
            padding-top: var(--space-xxxxxxxxxl);
            height: 42.446%;
            @include respond-down(sm) {
              padding-top: var(--space-xxxxxxxl);
            }
          }
        }
      }
    }

    &__item {
      display: flex;
      position: relative;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $bg-border;
      text-align: center;
      font-size: 24px;
      line-height: 1;
      z-index: 2;

      @include respond-down(sm) {
        font-size: 18px;
      }

      &:nth-child(even) {
        background: $color-additional;
      }
    }
  }
}