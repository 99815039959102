$max-row-width: 1890;
$grid-columns: 12;
$grid: (
        sm: (
                from: 0,
                to: 767,
                gutter: 20
        ),
        md: (
                from: 768,
                to:1150,
                gutter: 60
        ),
        lg:(
                from: 1151,
                to:1439,
                gutter: 60
        ),
        xl:(
                from: 1440,
                gutter: 60
        )
);
/**
   Типографика
 */
$body-font-size: 16px;
$font: "PTRootUIWeb", sans-serif;
$h-font: "PTRootUIWeb", sans-serif;
$light: 200;
$medium: 500;
$bold: 700;
$normal: 400;

/**
   Цвета
 */
$t: transparent;
$color-blue: #4288B7;
$color-white: #fff;
$color-greyd2: #d2d2d2;
$color-grey76: #767676;
$color-grey: $color-grey76;
$color-black: #000;

$color-primary: $color-black;
$primary-color: $color-primary;
$alert-color: #b50000;

$themes: (
        "default":
        (
                colors:
                (
                        "bg-default": $color-white,
                        "bg-accent": $color-black,
                        "bg-border": $color-black,
                        "bg-footer": #f7f9fa,
                        "color-primary": $color-black,
                        "color-secondary": $color-grey,
                        "color-additional": #d2d2d2,
                        "color-link": $color-blue,
                        "bg-button-hover":lighten(#000, 10%),
                        "color-white":#fff
                )
        ),
        "blue":
        (
                colors:
                (
                        "bg-default": #ebf2f4,
                        "bg-accent": #234558,
                        "bg-border": $color-black,
                        "bg-footer": #f7f9fa,
                        "color-primary": $color-black,
                        "color-secondary": #818485,
                        "color-additional": #d2d2d2,
                        "color-link": $color-blue,
                        "bg-button-hover":saturate(#234558, 10%),
                        "color-white":#fff
                )
        ),
        "beige":
        (
                colors:
                (
                        "bg-default": #f5f1eb,
                        "bg-accent": #234558,
                        "bg-border": #455B67,
                        "bg-footer": #f7f9fa,
                        "color-primary": #1E4154,
                        "color-secondary": #e6e6e6,
                        "color-additional": #000,
                        "color-link": #1E4154,
                        "bg-button-hover":saturate(#234558, 10%),
                        "color-white":#fff
                )
        )
);

// Переменные цветов, которые нужно использовать в стилях
$bg-default: var(--bg-default);
$bg-border: var(--bg-border);
$bg-accent: var(--bg-accent);
$bg-footer: var(--bg-footer);
$bg-white: var(--color-white);
$color-primary: var(--color-primary);
$color-secondary: var(--color-secondary);
$color-additional: var(--color-additional);
$color-link: var(--color-link);

// Отступы
$spaces: (
        "default":
        (
                "xxxxs":"5px",
                "xxxs": "10px",
                "xxs": "15px",
                "xs": "20px",
                "s": "25px",
                "m": "30px",
                "l": "35px",
                "xl":"40px",
                "xxl": "45px",
                "xxxl":"50px",
                "xxxxl": "55px",
                "xxxxxl":"60px",
                "xxxxxxl": "65px",
                "xxxxxxxl": "75px",
                "xxxxxxxxl": "85px",
                "xxxxxxxxxl": "100px",
                "xxxxxxxxxxl": "110px",
                "xxxxxxxxxxxl": "120px",
                "xxxxxxxxxxxxl": "130px",
        )
);
$inputSizes: ("xs":"24px", "s":"30px", "m":"35px", "l":"40px", "xl":"50px", "xxl":"56px", "xxxl":"70px");

$containerSizes: ("default":$max-row-width+px);


$default-transition: all 0.4s;