.informer {
  background: var(--bg-default);
  @at-root {
    &_view {
      &_default {
        background: var(--bg-default);
      }
    }

    &_border {
      &_all {
        border: 3px solid var(--color-primary);
      }
    }

    &__content {
      display: flex;
      flex-direction: column;

      &_distribute {
        &_default {
          align-items: flex-start;
        }

        &_center {
          align-items: center;
        }
      }

      &_space-a {
        $spaces: "xl", "xxl";
        @each $name in $spaces {
          &_#{$name} {
            padding: var(--space-#{$name});
          }
        }
      }
    }

    &__action {
      display: flex;
      flex-direction: column;

      &_distribute {
        &_default {
          align-content: flex-start;
        }

        &_between {
          align-content: space-between;
        }

        &_center {
          align-content: center;
        }
      }

      &_space-a {
        //Внутренние отступы по всем сторонам
        $spaces: "xl", "xxl";
        @each $name in $spaces {
          &_#{$name} {
            padding: var(--space-#{$name});
          }
        }
      }
    }

    &__list {
      &_border {
        &_all {
          border: 3px solid var(--bg-border);
        }
      }

      &_view {
        &_default {
          background: var(--bg-default);
        }
      }
    }

    &__item {
      display: flex;

      &_border {
        $sides: "bottom", "top";
        @each $side in $sides {
          &_#{$side} {
            border-#{$side}: 3px solid var(--bg-border);
          }
        }
      }

      &_distribute {
        //Распределение контента по горизонтали
        &_between {
          justify-content: space-between;
        }
      }

      &_vertical-align {
        //Распределение контента по горизонтали
        &_center {
          align-items: center;
        }
      }

      &_indent-t {
        $spaces: "s", "m", "l", "xl", "xxl";
        @each $name in $spaces {
          &_#{$name} {
            margin-top: var(--space-#{$name});
          }
        }
      }

      &_indent-b {
        $spaces: "s", "m", "l", "xl", "xxl";
        @each $name in $spaces {
          &_#{$name} {
            margin-bottom: var(--space-#{$name});
          }
        }
      }

      &_space-b {
        $spaces: "s", "m", "l", "xl", "xxl";
        @each $name in $spaces {
          &_#{$name} {
            padding-bottom: var(--space-#{$name});
          }
        }
      }

      &_space-a {
        $spaces: "s", "m", "l", "xl", "xxl";
        @each $name in $spaces {
          &_#{$name} {
            padding: var(--space-#{$name});
          }
        }
      }
    }
  }
}
