.link {
  @at-root {
    &_decoration {
      &_underline {
        text-decoration: underline;
      }
    }

    &_hover-line {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: auto;
        height: 2px;
        bottom: -3px;
        right: calc(100% - 11px);
        left: 0;
        background: var(--bg-border);
        transition: right ease .2s;
      }

      &:hover {
        &:before {
          right: 0;
        }
      }
    }

    &_icon {
      &_arrow-right {
        &:after {
          content: '';
          display: inline-block;
          width: 4px;
          height: 9px;
          margin: 0 0 0 var(--space-xxs);
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url('../svg/arrow-4x9.svg');
        }
      }

      &_fav {
        .icon {
          margin-right: var(--space-xxxs);
        }

        &_active,
        &:hover {
          .icon {
            &, &.svg-icon {
              --strokeColor: $alert-color;
              color: $alert-color;
            }
          }
        }
      }
    }

    &_hint {
      &_help {
        position: relative;

        &::after {
          content: "?";
          display: flex;
          justify-content: center;
          align-items: center;
          width: 16px;
          height: 16px;
          position: absolute;
          top: -13px;
          right: -18px;
          border-radius: 50%;
          background: $bg-accent;
          color: $color-white;
          font-size: 12px;
          font-weight: $bold;
          text-align: center;
        }
      }
    }
  }
}