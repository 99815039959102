@mixin respond($name) {
  @if map-has-key($grid, $name) {
    $point: map-get($grid, $name);
    @if map-has-key($point, from) and map-has-key($point, to) {
      @media (min-width: #{map-get($point, from)}px) and (max-width: #{map-get($point, to)}px) {
        @content;
      }
    } @else if map-has-key($point, to) {
      @media (max-width: #{map-get($point, to)}px) {
        @content;
      }
    } @else if map-has-key($point, from) {
      @media (min-width: #{map-get($point, from)}px) {
        @content;
      }
    }
  } @else {
    @warn "Unknown breakpoint '#{$name}'";
  }
}

@mixin respond-up($name) {
  @if map-has-key($grid, $name) {
    $point: map-get($grid, $name);
    @if map-has-key($point, from) {
      @media (min-width: #{map-get($point, from)}px) {
        @content;
      }
    }
  } @else {
    @warn "Unknown breakpoint '#{$name}'";
  }
}

@mixin respond-down($name) {
  @if map-has-key($grid, $name) {
    $point: map-get($grid, $name);
    @if map-has-key($point, to) {
      @media (max-width: #{map-get($point, to)}px) {
        @content;
      }
    }
  } @else {
    @warn "Unknown breakpoint '#{$name}'";
  }
}

/**
* Usage:
*  @include respond-property(margin-bottom, 15px, 20px, 100px);
*  @include respond-property(padding, 30px 15px, 40px 30px, 50px 45px);
*/
@mixin respond-property($property, $values...) {
  $grid-counter: 0;
  @each $name, $data in $grid {
    $counter: 0;
    @each $var in $values {
      @if $counter == $grid-counter {
        @include respond($name) {
          #{$property}: #{$var};
        }
      }
      $counter: $counter+1;
    }
    $grid-counter: $grid-counter+1;
  }
}

.sm-only-show {
  @include respond-up(md) {
    display: none !important;
  }
}

.sm-only-hide {
  @include respond(sm) {
    display: none !important;
  }
}

.md-up-show {
  @include respond-down(sm) {
    display: none !important;
  }
}

.md-down-hide {
  @include respond-down(md) {
    display: none !important;
  }
}
.md-down-show {
  @include respond-up(lg) {
    display: none !important;
  }
}

.lg-only-show {
  @include respond-up(xl) {
    display: none !important;
  }
  @include respond-down(md) {
    display: none !important;
  }
}

.lg-down-hide {
  @include respond-down(lg) {
    display: none !important;
  }
}

.lg-down-show {
  @include respond-up(xl) {
    display: none !important;
  }
}

.xl-only-hide {
  @include respond(xl) {
    display: none !important;
  }
}

.xl-only-show {
  @include respond-down(lg) {
    display: none !important;
  }
}