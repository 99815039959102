.card {
  display: flex;
  position: relative;
  flex-direction: column;

  @at-root {
    &_rounded {
      border-radius: 5px;
    }

    &_border {
      &_all {
        border: 1px solid $bg-border;
      }
    }

    &_view {
      &_default {
        background: $bg-default;
      }
    }

    &__content {
      display: flex;
      min-height: 100%;
      flex-direction: column;

      &_distribute {
        &_center {
          align-items: center;
        }

        &_between {
          justify-content: space-between;
        }
      }

      &_vertical-align {
        &_center {
          .card__footer {
            justify-content: center;
          }
        }
      }

      &_space-a {
        $spaces: "m", "l", "xl", "xxl";
        @each $name in $spaces {
          &_#{$name} {
            padding: var(--space-#{$name});
          }
        }
      }
    }

    &__footer {
      display: flex;
      position: absolute;
      width: 100%;
      right: 0;
      bottom: 0;
      left: 0;
      flex-direction: column;

      &_distribute {
        &_between {
          align-content: space-between;
        }

        &_center {
          align-content: center;
        }

        &_right {
          align-content: flex-end;
        }
      }

      &_vertical-align {
        &_center {
          justify-content: center;
        }

        &_top {
          justify-content: flex-start;
        }

        &_bottom {
          justify-content: flex-end;
        }
      }

      &_space-a {
        $spaces: "m", "l", "xl", "xxl";
        @each $name in $spaces {
          &_#{$name} {
            padding: var(--space-#{$name});
          }
        }
      }
    }
  }
}
