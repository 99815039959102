.b-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-xxs) 0 var(--space-xs);
  border-bottom: 1px solid $color-secondary;

  @include respond-down(md) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 0;
  }

  @at-root {

    &__part {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond-down(md) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &:nth-child(2) {
        width: 46%;
        @include respond-down(md) {
          width: 100%;
        }
      }

      &_name {
        &_page-count {
          @include respond-down(md) {
            flex-direction: row;
          }
        }
      }

      &_justify {
        &_start {
          justify-content: flex-start;
        }
      }

      .text {
        font-size: 16px;
        white-space: nowrap;

        @include respond-down(md) {
          font-size: 18px;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;

      @include respond-down(md) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }

      .text {
        .svg-icon {
          cursor: pointer;
          transform: rotateZ(90deg);
          margin-left: var(--space-xxs)
        }
      }
    }

    &__dropdown {
      position: relative;
      @include respond-down(md) {
        width: 100%;
      }

      &_active {
        z-index: 4;

        .b-filter__dropdown-visible {

          .svg-icon {
            transform: rotateZ(270deg)
          }
        }

        .b-filter__dropdown-hidden {
          opacity: 1;
          pointer-events: all;

          @include respond-down(md) {
            display: flex;
            margin-bottom: var(--space-l);
          }
        }
      }
    }

    &__dropdown-visible {
      position: relative;
      cursor: pointer;
      user-select: none;
      z-index: 3;

      @include respond-down(md) {
        display: block;
        margin: var(--space-xxxs) 0;
      }

      .svg-icon {
        transition: transform ease-out .2s;
      }
    }

    &__dropdown-hidden {
      opacity: 0;
      pointer-events: none;

      display: flex;
      position: absolute;
      width: 340px;
      padding: var(--space-xxxxxxxxxl) var(--space-xl) var(--space-xxxl) var(--space-xxl);
      top: calc(-1 * var(--space-xl));
      left: calc(-1 * var(--space-xxl));
      flex-direction: column;
      line-height: 2.25;
      background: $bg-default;
      border: 1px solid $bg-border;
      transition: opacity ease-out .1s;
      z-index: 2;

      @include respond-down(md) {
        display: none;
        position: static;
        width: 100%;
        margin-top: var(--space-xs);
        padding: var(--space-xl) var(--space-m);
      }
    }

    &__list {
      line-height: 1.25;
      word-wrap: break-word;
      max-height: 320px;
      overflow-y: scroll;
    }

    &__link {
      &:hover,
      &:active ,
      &_active {
        font-weight: bold;
        color: var(--color-primary);
      }
    }

    &__inlabel {
      margin-left: 14px;
      margin-bottom: 14px;
      cursor: pointer;

      &:hover,
      &:active ,
      &_active {
        font-weight: bold;
        color: var(--color-primary);
      }

      &_active {
        position: relative;
        &:not(:first-child)::before {
          content: '\00D7';
          position: absolute;
          top: 4px;
          left: -14px;
          height: 14px;
          width: 14px;
          font-size: 24px;
          font-weight: 300;
          line-height: 14px;
        }
      }

      &.text {
        white-space: normal;
      }
    }

    &__label {
      cursor: default;
      margin-right: var(--space-xxs);
      @include respond-down(md) {
        margin-bottom: var(--space-m);
      }
    }

    &__pages {
      display: flex;
      padding-left: var(--space-xl);
      line-height: 1;

      .text {
        font-size: 14px;

        &:not(:last-child) {
          margin-right: var(--space-xs);
        }
      }
    }

    &__search {
      margin-bottom: 10px;
    }
  }
}