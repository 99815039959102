body.index-page {
  .slider {
    margin-top: var(--space-xxl);
    @include respond(lg) {
      margin-top: var(--space-xxxxxxxl);
    }
    @include respond(md) {
      margin-top: var(--space-xxl);
    }
    @include respond-down(sm) {
      margin-top: 0;
    }
  }

  .b-possibilities {
    margin-top: var(--space-xxl);
    @include respond(lg) {
      margin-top: var(--space-xxxxxxl);
    }
    @include respond(md) {
      margin-top: calc(var(--space-xxxxxxxxxxl) + var(--space-m));
    }
    @include respond-down(sm) {
      margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxs))
    }
  }

  .b-events {
    margin-top: var(--space-xxxxxxxxxxl);
    padding-top: var(--space-xxxxxxxxxl);
    padding-bottom: var(--space-xxxxxxxl);
    @include respond(lg) {
      margin-top: var(--space-xxxxxxxxl);
      padding-top: var(--space-xxxxxxxxl);
    }
    @include respond(md) {
      margin-top: var(--space-xs);
      padding-top: var(--space-xxxxxxxl);
      padding-bottom: var(--space-xxxxxxxl);
    }
    @include respond-down(sm) {
      margin-top: var(--space-xxl);
      padding-top: var(--space-xxxxxl);
      padding-bottom: var(--space-xxxxxxl);
    }
  }

  .b-goods {
    margin-top: calc(var(--space-xxxxxxxxxxl) + var(--space-xxxs));
    @include respond(lg) {
      margin-top: var(--space-xxxxxxxxxxl);
    }
    @include respond(md) {
      margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxxs));
    }
    @include respond-down(sm) {
      margin-top: var(--space-xxxxxxl);
    }
  }

  .b-news {
    margin-top: calc(var(--space-xxxxxxxxxxl) + var(--space-s));
    @include respond(lg) {
      margin-top: var(--space-xxxxxxxxxxl);
    }
    @include respond(md) {
      margin-top: var(--space-xxxxxxxxl);
    }
    @include respond-down(sm) {
      margin-top: var(--space-xxxxxxxl);
    }
  }

  .subscribe-form {
    margin-top: calc(var(--space-xxxxxxxxxxl) + var(--space-xxxs));
    @include respond(lg) {
      margin-top: var(--space-xxxxxxxxxl);
    }
    @include respond(md) {
      margin-top: var(--space-xxxxxxxxl)
    }
    @include respond-down(sm) {
      margin-top: var(--space-xxxxxxxl);
    }
  }
}