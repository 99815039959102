/* Увеличение картинки на :hover
   Инклудить во враппер!
  .image-wrapper {
    @include imageHoverZoom;
    .image {}
  }
 */
@mixin imageHoverZoom() {
  overflow: hidden;
  & > * {
    transform: scale(1);
    transition: transform ease-out .4s;

    &:hover {
      transform: scale(1.04);
    }
  }
}