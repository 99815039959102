/**
  Разметка:
  1. Цвета
  <div class="input-group radio radio_type_color">
    <input type="radio" name="color" id="{$name}" class="radio__input" value="{$name}">
    <label for="{$name}" class="radio__label" style="background:{$hex};{if strpos($hex, '#fff') === 0}border:1px solid #ACACAC{/if}">{$name}</label>
  </div>
  2. Надписи
   <div class="input-group radio">
     <input type="radio" name="size" id="{$name}" class="radio__input" value="{$size}">
     <label for="{$name}" class="radio__label">{$name}</label>
   </div>

  $name – название цвета/надпись
  $hex – цвет в hex, rgb, rgba, etc...
 */
.radio {
  $padding: 0 var(--space-xxxs);
  $borderColor: $color-secondary;
  $activeBorderColor: #0D8CCB;
  $bgColor: #35AEE9;
  $borderRadius: 5px;
  $height: 40px;
  $width: 40px;
  $colorWidth: 28px;
  $colorHeight: 28px;
  $checkWidth: 18px;
  $checkHeight: 18px;

  $checkImage: url('../svg/checkmark-white.svg');
  $checkImageWidth: 10px;
  $checkImageHeight: 10px;


  display: inline-block;
  min-width: $width;
  height: $height;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;

  @at-root {
    &_type {
      &_color {
        .radio__label {
          position: absolute;
          font-size: 0;
          width: $colorWidth;
          height: $colorHeight;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: $borderRadius;
        }

        .radio__multicolor{
          background: red; /* For browsers that do not support gradients */
          background: -webkit-linear-gradient(left, orange , yellow, green, cyan, blue, violet); /* For Safari 5.1 to 6.0 */
          background: -o-linear-gradient(right, orange, yellow, green, cyan, blue, violet); /* For Opera 11.1 to 12.0 */
          background: -moz-linear-gradient(right, orange, yellow, green, cyan, blue, violet); /* For Firefox 3.6 to 15 */
          background: linear-gradient(to right, orange , yellow, green, cyan, blue, violet); /* Standard syntax (must be last) */
        }

        .radio__input {
          &:checked {
            & ~ .radio__label {
              &::after {
                top: calc((#{$height} - #{$colorHeight}) / -1);
                right: calc((#{$width} - #{$colorWidth}) / -1);
                transform: none;
              }
            }
          }
        }
      }
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $padding;
      margin: 0;
      font-size: 14px;
      font-weight: $medium;
      opacity: 1;
      color: $color-primary;
      text-align: center;
      line-height: $height;
    }

    &__input {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      outline: none;
      appearance: none;
      border-radius: $borderRadius;
      border: 1px solid $borderColor;
      cursor: pointer;

      &:checked {
        border-color: $activeBorderColor;

        ~ .radio__label {
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: $checkWidth;
            height: $checkHeight;
            top: 0;
            right: 0;

            border-radius: 50%;
            border: 1px solid $activeBorderColor;

            background-image: $checkImage;
            background-repeat: no-repeat;
            background-size: $checkImageWidth $checkImageHeight;
            background-position: center center;
            background-color: $bgColor;

            transform: translate(50%, -50%);
            pointer-events: none;
            z-index: 1;
          }
        }
      }
    }
  }
}
