.b-learning-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-content: space-between;

  @at-root {
    &_size {
      &_large {
        .b-learning-card__image {
          @include respond(xl) {
            height: 440px;
          }

          @include respond(lg) {
            height: 380px;
          }
          @include respond(md) {
            height: 386px;
          }
          @include respond(sm) {
            height: 187px;
          }
        }

        .b-learning-card__title {
          @include respond(xl) {
            margin-top: var(--space-xxxl);
            font-size: 38px;
            line-height: 48px;
          }

          @include respond(lg) {
            margin-top: var(--space-l);
            padding-right: var(--space-xl);
          }

          @include respond-down(lg) {
            font-size: 32px;
            line-height: 41px;
          }

          @include respond-down(md) {
            margin-top: var(--space-xl);
          }

          @include respond(sm) {
            font-size: 18px;
            line-height: 23px;
          }
        }

        .b-learning-card__button {
          width: 28%;
          @include respond(xl) {
            margin-top: var(--space-xxxl);
          }

          @include respond(lg) {
            width: 41%;
            margin-top: var(--space-xl);
            height: var(--input-xxl);
            line-height: var(--input-xxl);
          }

          @include respond(md) {
            width: 34%;
            margin-top: var(--space-l);
          }

          @include respond(sm) {
            margin-top: var(--space-s);
            width: 100%;
          }
        }
      }

      &_small {
        .b-learning-card__image {
          @include respond(xl) {
            height: 257px;
          }

          @include respond(lg) {
            height: 173px;
          }

          @include respond-down(md) {
            height: 187px;
          }
        }

        .b-learning-card__title {
          max-width: none;
          font-size: 26px;
          line-height: 33px;

          @include respond(xl) {
            margin-top: var(--space-xxl);
          }

          @include respond(lg) {
            margin-top: var(--space-l);
          }

          @include respond-down(lg) {
            font-size: 24px;
            line-height: 30px;
          }

          @include respond-down(md) {
            margin-top: var(--space-l);
          }

          @include respond(sm) {
            font-size: 18px;
            line-height: 23px;
          }
        }

        .b-learning-card__button {
          @include respond(xl) {
            width: 60%;
          }

          @include respond-down(lg) {
            width: 100%;
            height: var(--input-xxl);
            margin-top: var(--space-l);
            line-height: calc(var(--input-xxl) - 1px);
            font-size: 14px;
          }

          @include respond-down(md) {
            width: 75%;
            margin-top: var(--space-m);
            height: var(--input-xl);
            line-height: calc(var(--input-xl) - 1px);
          }

          @include respond(sm) {
            width: 100%;
          }
        }
      }
    }

    &__date {
      margin: 30px 0px 0px 0px;
      font-size: 20px;
      width: 100%;
    }

    &__image {
      width: 100%;
      object-fit: contain;

      @include respond(xl) {
        height: 360px;
      }

      @include respond(lg) {
        height: 242px;
      }

      @include respond(md) {
        height: 214px;
      }

      @include respond(sm) {
        height: 186px;
      }
    }

    &__title {
      display: block;
      margin-top: var(--space-l);
      font-size: 20px;
      line-height: 33px;
      height: 230px;

      @include respond(xl) {
        max-width: 86.67%;
        height: 240px;
      }

      @include respond(lg) {
        margin-top: var(--space-xxxxl);
      }

      @include respond(md) {
        margin-top: var(--space-m);
        font-size: 24px;
        line-height: 30px;
        height: 170px;
      }

      @include respond(sm) {
        font-size: 18px;
        line-height: 23px;
        height: 100px;
      }
    }

    &__button {
      width: 43%;
      margin-top: var(--space-m);

      @include respond-down(lg) {
        width: 65%;
        height: var(--input-xl);
        line-height: var(--input-xl);
      }

      @include respond-down(md) {
        width: 75%;
        font-size: 14px;
        margin-top: var(--space-m);
      }
      @include respond(sm) {
        width: 100%;
      }
    }
  }
}