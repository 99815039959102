.form {
  $indentSizes: "xxxs", "xxs", "s", "m", "l", "xl", "xxl", "xxxl", "xxxxl";
  @at-root {
    &_border {
      &_all {
        border: 3px solid var(--bg-border);
      }
    }

    &_view {
      &_default {
        background: var(--bg-default);
      }
    }

    &__item {
      display: flex;

      &_border {
        &_bottom {
          border-bottom: 3px solid var(--bg-border);

          &:last-child {
            border: none;
          }
        }
      }

      &_distribute {
        &_between {
          justify-content: space-between;
        }
      }

      &_vertical-align {
        &_center {
          align-items: center;
        }
      }

      &_indent-b {
        //Внешний отступ снизу
        @each $name in $indentSizes {
          &_#{$name} {
            margin-bottom: var(--space-#{$name});
          }
        }
      }

      &_space-h {
        //Внутренние отступы по горизонтали
        @each $name in $indentSizes {
          &_#{$name} {
            padding-left: var(--space-#{$name});
            padding-right: var(--space-#{$name});
          }
        }
      }

      &_space-v {
        //Внутренние отступы по вертикали
        @each $name in $indentSizes {
          &_#{$name} {
            padding-top: var(--space-#{$name});
            padding-bottom: var(--space-#{$name});
          }
        }
      }
    }

    &__label {
      &_width {
        &_default {
          width: 35%;
        }
      }
    }

    &__control {
    }

    &__header {
    }

    &__content {
    }

    &__content-item {
    }

    &__footer {
    }

    &__label {

    }
  }
}
