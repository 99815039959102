.opportunities {
  margin-top: 83px;

  .title_block {
    margin-bottom: 35px;
    font-size: 32px;
    line-height: 39px;
  }

  @include respond(sm) {
    margin-top: 55px;
  }

  ul {
    li {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 21px;
      display: flex;

      li:last-child {
        margin-bottom: 0;
      }

      p {
        font-size: 22px;
        padding-right: 50px;
        font-weight: $bold;
      }

      @include respond(sm) {
        font-size: 14px;
      }
    }
  }

  .sentences_text {
    font-size: 18px;
    line-height: 28px;
    font-weight: $bold;
  }
}