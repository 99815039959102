//TODO: сделать мобильную версию для блока мультизагрузки
.multiple {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 30px 0;
    border: 2px dashed rgb(160, 160, 160);
    padding: 20px;
    justify-content: center;
    &__images {

    }
    &__title {
        font-size: 16px;
    }
    &__wrap {
        flex-basis: 100%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        & picture {
            position: relative;
            width: 150px;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            margin: 5px;
            border: 1px solid var(--color-secondary);
 
            img {
                object-fit: contain;
                height: 100%;
            }
            button {
                display: none;
                position: absolute;
                background-color: #fff;
                width: 20px;
                height: 20px;
                top: 7px;
                right: 7px;
                border-radius: 50%;
                &::after {
                    position: absolute;
                    content: "";
                    width: 80%;
                    height: 2px;
                    background-color: var(--color-secondary);
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                }
                &::before {
                    position: absolute;
                    content: "";
                    width: 80%;
                    height: 2px;
                    background-color: var(--color-secondary);
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%) rotate(45deg);
                }
            }
            &:hover {
                button {
                    display: block;
                }
            }
        }
    }
    &__label {
        padding: 10px 20px;
        border-radius: 5px;
        border: 1px solid #000;
        background-color: #000;
        color:#fff;
    }
    input {
        display: none;
    }
}

.images-block {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 50px;

    h2 {
        flex-basis: 100%;
        margin-bottom: 20px;
    }
    &__wrap {
        flex-basis: 20%;
        padding: 10px;
        position: relative;
        &:hover {
            .images-block__remove {
                display: block;
            }
        }
        &::after {
            content: "";
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.445);
        }
        &.delete {
            &::after {
                display: block;
            }
            & .images-block__remove {
                display: none;
            }
        }
    }
    &__img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
    }
    &__remove {
        display: none;
        position: absolute;
        background-color: #fff;
        width: 25px;
        height: 25px;
        top: 0;
        right: 0;
        border-radius: 50%;
        border: 1px solid transparent;
        box-shadow: 0px 0px 4px #000000;
        &::after {
            position: absolute;
            content: "";
            width: 80%;
            height: 2px;
            background-color: #000;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        }
        &::before {
            position: absolute;
            content: "";
            width: 80%;
            height: 2px;
            background-color: #000;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
        }
    }
}