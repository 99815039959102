@mixin image-icon($path, $width, $height) {
  &.#{$path} {
    background-size: contain;
    width: $width;
    height: $height;
    @include image-ico($path, $width, $height);
  }
}

@mixin image-ico($path, $width, $height) {
  background: {
    color: $t;
    image: url('../images/image-icons/#{$path}.png');
    position: 50% 50%;
    repeat: no-repeat;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1) {
    background-image: url('../images/image-icons/#{$path}-2x.png');
  }
}

@mixin with-icon($path, $width, $height) {
  &.#{$path} {
    background-size: $width $height;
    @include image-ico($path, $width, $height);
  }
}

.image-icon {
  display: inline-block;
  vertical-align: middle;
  //@include image-icon('example', 20px, 34px);
}

/*
{icon:raw 'unchecked'}
<i class="svg-icon icon-unchecked"></i>
*/

.svg-icon {
  display: inline-block;
  vertical-align: middle;
}