$default-input-height: 50px;

/** Стили для кнопок лежат в button.scss.
    Стили для инпутов – input.scss
    Добавляйте новые стили, пожалуйста, туда. :)
 */
.button, button {
  border: none;
  cursor: pointer;
  user-select: none;
}

label {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
  color: #9f9f9f;
  cursor: pointer;
}

.form-error {
  .form-field {
    ul.errors {
      position: static;
      margin-top: 5px;
      li {
        color: $alert-color;
      }
    }
  }
}


.form-field {
  position: relative;
  margin-bottom: var(--space-xxs);
  [data-phone-container] {
    position: relative;
    width: 100%;
    .iti--allow-dropdown {
        width: 100%;
        input[type=text] {
            background: transparent !important;
        }
        .iti__flag-container {
            &:hover {
                .iti__selected-flag {
                    background: transparent !important;
                }
            }
        }
    }
  }

  &.policy-privacy, &.fz {
    margin-top: var(--space-m);
    margin-bottom: 0;
  }

  &.file-field {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .label {
      font-size: 16px;
      margin-right: var(--space-s);
    }

    ul.errors {
      width: 100%;
    }
  }

  ul.errors {
    position: absolute;
    left: 0;
    bottom: calc(-1 * var(--space-xs));

    li {
      color: $alert-color;
    }
  }

  &.fz, &.policy-privacy {
    display: flex;

    .checkbox {
      margin-right: var(--space-xs);
    }
  }
}

.modal__bg {
  .form-field {
    margin-bottom: var(--space-s);
  }
}

input, textarea, select {
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: inline-block;
}

input, select {
  height: $default-input-height;
}

textarea {
  padding: 5px 10px;
}

.form {
  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: $alert-color;
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.checkbox-field {
  display: flex;

  .checkbox {
    margin-right: var(--space-xs);
  }
}

/**
    Floating label
 */
.fl-wrap {
  &.fl-wrap-input, &.fl-wrap-textarea, &.fl-wrap-select {
    position: relative;

    input {
      width: 100%;
    }

    .label {
      display: inline-block;
      max-width: calc(100% - 25px);
      padding: 0 5px;
      opacity: 0;
      position: absolute;
      top: -1px;
      left: calc(var(--space-xs) - 5px);
      background: $bg-default;
      transition: opacity ease-out .2s, top ease-out .15s;
    }

    &.fl-is-active {
      .label {
        opacity: 1;
        top: -5px;
        z-index: 3;
      }
    }
  }
}

.fl-wrap.fl-wrap-select {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 12px;
    top: 50%;
    right: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../svg/arrow-4x9.svg');
    pointer-events: none;
    transform: translateY(-50%) rotateZ(90deg);
  }
}

select {
  padding: 0 22px;
  background: $t;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border-radius: 3px;
  font-size: 16px;
  border-color: $color-additional;
}


form{
  position: relative;
  &.success:after {
    content: "Спасибо, данные успешно сохранены!";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 500;
    position: absolute;
    background: #ffffff;
    width: calc(100% - 2px);
    height: calc(100% - -25px);
    top: -12px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    text-align: center;
    border-radius: 19px;
    border: 1px solid #4288b8;
    transform: rotateY(0deg);
  }

  &.form-OrderForm.success:after {
    content: "Спасибо! Ваш заказ поступил в обработку. Ожидайте звонка менеджера.";
  }
}

body.personal-page .form__input-search {
  .fl-wrap.fl-wrap-select {
        input {
          width: 100%;
        }
        .label {
          display: inline-block;
          max-width: calc(100% - 25px);
          padding: 0 5px;
          opacity: 0;
          position: absolute;
          top: -1px;
          left: calc(var(--space-xs) - 5px);
          background: $bg-default;
          transition: opacity ease-out .2s, top ease-out .15s;
          margin: 0;
        }
        &.fl-is-active {
          .label {
            opacity: 1;
            top: -5px;
            z-index: 1;
            font-size: 12px;
            line-height: 12px;
          }
        }
        &::after {
          display: none;
        }
  }
  .select2-container .select2-selection--single {
    height: 50px;
    border-color: var(--color-additional);
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 48px;
    padding: 0 var(--space-xs);

  }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50px;
  }

}

.form {
  &__color {
    label {
      margin-bottom: 15px;
    }
    .radio__label {
      border: 1px solid #8e8e8e61;
    }
  }
}

.checkbox-list {
  li {
    position: relative;
  }
  input[type="checkbox"] {
    height: auto;
    &:checked {
      & + .checkbox__label .checkbox__check-mark {
        background-repeat: no-repeat;
        background-size: 15px 11px;
        background-position: center center;
        background-image: url('../svg/checkmark.svg');
      }
    }
  }
  .checkbox__label {
    padding-left: 0;
    position: relative;
    z-index: 1;
    display: flex;
    margin: 0;
    padding: 8px 0;
  }
  
  .checkbox__title {
  
  }
  
  .checkbox__check-mark {
    opacity: 1;
    position: static;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
    background: transparent;
    margin-right: 10px;
  }
  .checkbox__check-mark {
    position: static;
  }
}




.file-input {
  margin-right: 20px;
}
.file-preview {
  margin-right: 20px;
}
.file-clear {
  position: relative;
  input[type="checkbox"] {
    position: absolute;
  }
  label {
    position: relative;
    z-index: 1;
  }
}

.modal__form {
  .form-field {
    &.phone {
      ul.errors {
        position: static;
      }
    }
  }
}
