body.cart-page {
  .page-title {
    @include respond(xl) {
      margin-top: calc(var(--space-xxxxxxl) + var(--space-xxxxs));
      margin-bottom: var(--space-xxxxxxxxxxl);
    }

    @include respond(lg) {
      margin-top: calc(var(--space-xxxxxxl) + var(--space-xxs));
      margin-bottom: var(--space-xxxxxxxl);
    }

    @include respond(md) {
      margin-top: var(--space-xxxxxl);
      margin-bottom: var(--space-xxxxxxl);
    }
    @include respond(sm) {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }
  }

  .section {
    &_name {
      &_actions {
        @include respond(xl) {
          position: sticky;
          top: 0;
        }

        @include respond(lg) {
          margin-top: var(--space-xxxxxl);
        }

        @include respond(md) {
          margin-top: calc(var(--space-xxxxxxl) + var(--space-xxxs));
        }

        @include respond(sm) {
          margin-top: var(--space-l);
        }
      }

      &_subscribe-form {
        @include respond(xl) {
          margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xl));
        }

        @include respond(lg) {
          margin-top: var(--space-xxxxxxxxxxl);
        }

        @include respond(md) {
          margin-top: var(--space-xxxxxxxxl);
        }

        @include respond(sm) {
          margin-top: var(--space-xxxxxxxxxxxl);
        }
      }
    }
  }

  .table {
    .form-field {
      margin: 0;
    }

    @at-root {
      &__head {
        .table__data {
          padding: var(--space-xs) 0;
        }

        @include respond-down(md) {
          display: none;
        }
      }

      &__body {
        .table__row {
          @include respond-down(md) {
            padding-top: var(--space-xl);
            padding-bottom: var(--space-m);
          }

          @include respond(sm) {
            padding-bottom: var(--space-xxl);
          }

          .table__data {
            padding-top: var(--space-xxxxs);
            padding-bottom: var(--space-xs);

            @include respond-down(md) {
              padding: 0;
            }
          }
        }
      }

      &__row {
        @include respond-down(md) {
          display: grid;
        }
        @include respond(md) {
          grid-template-areas: "image title actions" "image price actions" "image count actions" "image sum actions";
        }
        @include respond(sm) {
          grid-template-areas: "image" "actions" "title" "price" "count" "sum";
        }
      }

      &__data {
        padding: var(--space-xxs) 0;

        @include respond-down(md) {
          display: flex;
          align-items: center;
          text-align: left;

          &:before {
            content: attr(data-text);
            display: block;
            flex: 0 0 178px;
            text-transform: uppercase;

            @include respond(sm) {
              font-size: 12px;
              flex: 0 0 100px;
            }
          }
        }

        &_name {
          &_image {
            grid-area: image;

            @include respond(xl) {
              width: 16.6%;
            }

            @include respond(lg) {
              width: 16%;
            }

            @include respond-down(md) {
              align-items: flex-start;
              margin-top: calc(-1 * var(--space-l));

              &:before {
                flex: initial;
              }
            }
          }

          &_title {
            grid-area: title;

            @include respond(xl) {
              width: 22.24%;
            }

            @include respond(lg) {
              width: 27%;
            }

            @include respond-up(lg) {
              text-align: left;
            }

            @include respond-down(md) {
              align-items: flex-start;
              margin-bottom: var(--space-s);
            }

            @include respond(sm) {
              margin-bottom: var(--space-xxxs);
            }
          }

          &_price {
            grid-area: price;
            @include respond(xl) {
              width: 20%;
            }
            @include respond(lg) {
              width: 13.5%;
            }
            @include respond-down(md) {
              margin-bottom: var(--space-xxs);
            }
          }

          &_count {
            grid-area: count;

            --plus-minus-height: 48px;
            --plus-minus-height-sm: 48px;

            .plus-minus {
              justify-content: center;
            }

            @include respond(xl) {
              width: 15%;
            }

            @include respond(lg) {
              width: 18%;
            }
            @include respond-down(md) {
              margin-bottom: var(--space-xs);
            }
          }

          &_sum {
            grid-area: sum;

            @include respond(xl) {
              width: 15%;
            }
            @include respond(lg) {
              width: 18%;
            }
          }

          &_actions {
            grid-area: actions;

            @include respond(xl) {
              width: 11.16%;
            }

            @include respond(lg) {
              width: 7.5%;
            }

            @include respond-up(lg) {
              text-align: right;
              padding-right: var(--space-xxxs);
            }

            @include respond-down(md) {
              align-items: flex-start;

              &:before {
                flex: initial;
              }
            }

            @include respond(sm) {
              margin-top: var(--space-xxs);
              margin-bottom: var(--space-s);

              justify-content: flex-end;
            }
          }

        }
      }

      &__text {
        font-size: 18px;
        line-height: 1.33;

        @include respond(sm) {
          font-size: 16px;
        }

        &_small {
          font-size: 16px;
        }

        &_large {
          font-size: 24px;
        }

        &_company {
          display: flex;
          margin-top: var(--space-xxxs);

          @include respond(sm) {
            margin: 0;
            flex-direction: column;
          }

          .text {
            &:first-child {
              margin-right: var(--space-xxxxs);
            }

            @include respond(sm) {
              display: block;
            }
          }
        }
      }

      &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & > *:not(:last-child) {
          margin-right: var(--space-xxxs);

          @include respond(sm) {

          }
        }
      }
    }
  }

  .cart {
    @at-root {
      &__product-image {
        display: block;
        object-fit: contain;
        min-height: 50px;
        max-height: 150px;

        @include respond(xl) {
          padding-left: var(--space-xs);
          padding-right: var(--space-xxs);
        }

        @include respond-down(sm) {
          margin: 0 auto;
          max-height: 240px;
        }
      }

      &__card {
        padding: var(--space-m) var(--space-m) var(--space-xl);
        border-color: $color-additional;
        overflow: hidden;

        @include respond(lg) {
          padding: var(--space-m) var(--space-xxxxxxl) var(--space-xl);
        }

        @include respond-down(md) {
          border-color: $t;
          padding: 0;
        }

        .text {
          text-overflow: ellipsis;
        }
      }

      &__card-dl {
        line-height: 40px;

        @include respond(md) {
          padding: 0 var(--space-s);
        }
      }

      &__card-dt {
        margin-right: var(--space-xxxxs);
        float: left;
        font-size: 18px;

        @include respond-down(lg) {
          margin-right: var(--space-xxxs);
        }

        @include respond-down(md) {
          font-size: 16px;
        }
      }

      &__card-dd {
        font-size: 20px;

        &_size {
          &_large {
            font-size: 24px;
          }
        }
      }

      &__button {
        &_name {
          &_order {
            margin-top: var(--space-xl);
            font-size: 14px;

            @include respond(md) {
              margin-top: var(--space-m);
            }

            @include respond(sm) {
              margin-top: var(--space-l);
            }
          }

          &_continue {
            display: block;
            margin: var(--space-l) auto 0;

            @include respond(xl) {
              width: 83%;
            }

            @include respond-down(lg) {
              margin: 0;
              font-size: 14px;
            }

            @include respond(sm) {
              margin-top: var(--space-s);
            }
          }
        }
      }

      &__empty-text {
        display: flex;
        margin: 0 auto;
        width: 100%;
        max-width: 400px;
        flex-direction: column;
        text-align: center;
      }
    }
  }


  .table_loading, .cart__card_loading {
    position: relative;

    &, & * {
      pointer-events: none;
      user-select: none;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      bottom: -1px;
      left: 0;
      background: url('../svg/preloader.svg') center center no-repeat rgba($color-white, .5);
      background-size: 50px 50px;
    }
  }
}


.success_cart{
  background: #234557;
  color: #fff;
  position: sticky;
  bottom: 0px;
  width: 100%;
  display: none;

  .success_cart_wrapper{
    display:flex;
    width:100%;
    flex-direction:row;
    align-items:center;
    justify-content: space-between;
    padding: 0px 40px;

    p{
      margin: 30px 0px;
    }

    a{
      margin: 30px 0px;
      display: block;
      background-color: #fff;
      color: #234557;
      text-decoration: none;
      text-align: center;
      padding: 20px 30px;

      &:hover{
        color: #000;
      }
    }
  }
}

.scale-up-ver-bottom {
  -webkit-animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.scale-up-ver-bottom-reverse {
  -webkit-animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) reverse both;
  animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) reverse both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-3-30 19:33:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-bottom {
  0% {
    display: block;
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}
@keyframes scale-up-ver-bottom {
  0% {
    display: block;
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}
