.plus-minus {
  $height: var(--plus-minus-height, var(--input-xxl));
  $smHeight: var(--plus-minus-height-sm, var(--input-xl));
  $buttonWidth: 38px;
  $inputWidth: 78px;
  $borderColor: $color-secondary;
  $textColor: $color-primary;
  $borderRadius: 5px;
  $borderWidth: 1px;
  $backgroundColor: $bg-default;

  $smFullWidth: true;

  display: flex;
  align-items: center;
  height: $height;

  @include respond(sm) {
    height: $smHeight;
  }

  @if ($smFullWidth == true) {
    @include respond-down(sm) {
      width: 100%;
    }
  }

  @at-root {
    &__button {
      background: $backgroundColor;

      &_type {
        &_minus, &_plus {
          display: block;
          height: 100%;
          width: $buttonWidth;
          flex: 0 0 $buttonWidth;
          border: $borderWidth solid $borderColor;
          font-size: 24px;
          font-weight: normal;
        }

        &_minus {
          border-radius: $borderRadius 0 0 $borderRadius;
        }

        &_plus {
          border-radius: 0 $borderRadius $borderRadius 0;
        }
      }
    }

    &__input {
      padding: 0;
      opacity: 1;
      display: block;
      height: 100%;
      width: $inputWidth;
      text-align: center;
      color: $textColor;
      border-radius: 0;
      border: 0;
      border-top: $borderWidth solid $borderColor;
      border-bottom: $borderWidth solid $borderColor;

      @if ($smFullWidth == true) {
        @include respond-down(sm) {
          width: 100%;
        }
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}