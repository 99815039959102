.events-list-page {
  .events {
    .line {
      height: 1px;
      width: 100%;
      background: #979797;
    }
    .events__wrap {
      .events__filter {
        display: flex;
        margin-bottom: 24px;
        @include respond(sm) {
          flex-direction: column;
        }
        .events__item {
          display: flex;
          align-items: center;
          @include respond(sm) {
            margin-bottom: 20px;
          }
          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: transparent;
            border: none;
            height: auto;
            font-size: 16px;
            font-weight: $bold;
            position: relative;
            margin-right: 25px;
            @include respond(sm) {
              max-width: 140px;
              margin-right: 8px;
            }
          }
          .ico__arrow {
            width: 6px;
            height: 10px;
            transform: rotate(90deg);
          }
        }
        .sorting {
          margin-right: 160px;
          @include respond(sm) {
            margin-right: 0;
          }
          @include respond(md) {
            margin-right: 40px;
          }
          p {
            margin-right: 12px;
            color: #ababab;
            font-size: 16px;
            font-weight: $medium;
          }
        }
        .activity {
          margin-right: 107px;
          @include respond(sm) {
            margin-right: 0;
          }
          @include respond(md) {
            margin-right: 40px;
          }
          &__ico {
            margin-right: 14px;
          }
        }
        .period {
          &__ico {
            margin-right: 14px;
          }
        }
      }
    }
  }
}