.title-w-controls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @at-root {
    &__title {

    }

    &__slides-count {
      margin: 0 var(--space-xxxxxxl) 0 auto;
      font-size: 21px;
      @include respond-down(sm) {
        display: none;
      }

      &_sm {
        @include respond-down(sm) {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
        }
        @include respond-up(md) {
          display: none;
        }
      }
    }

    &__controls {
      display: flex;
      @include respond-down(sm) {
        display: none;
      }

      &_small {
        display: none;
        @include respond-down(sm) {
          display: flex;
          justify-content: center;
          margin-top: var(--space-xxl);
        }
      }
    }
  }
}