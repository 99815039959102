.header {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  padding-top: var(--space-xxxs);

  &, &__line {
    z-index: 100;
    background-color: #fff;
  }
  @include respond-down(sm) {
    & {
      padding-top: 0;
    }
  }
  @at-root {
    &__left, &__right {
      display: flex;
    }

    /**
        Левая часть
     */
    &__left {
      .header__menu {
        margin: var(--space-xxs) 0 0 var(--space-s);
        @include respond(lg) {
          margin: var(--space-xxxxs) 0 0 var(--space-m);
        }
      }
    }

    &__logo-wrapper {
      @include respond(xl) {
        margin-top: var(--space-xxxxs);
      }
    }

    &__logo {
      @include respond-down(lg) {
        &, .svg-icon {
          width: 132px;
        }
      }
      @include respond-down(sm) {
        &, .svg-icon {
          width: 126px;
          display: block;
          padding-top: 10px;
        }
      }
    }

    &__title {
      display: block;
      max-width: 200px;
      font-size: 12px;
      color: $color-secondary;
      letter-spacing: 0;
      line-height: 1.2;

      @include respond(lg) {
        max-width: 177px;
        margin: var(--space-xxxxxs) 0 0
      }
      @include respond-down(md) {
        max-width: initial;
        width: 100%;
        margin: var(--space-xxxxxs) 0 0
      }
      @include respond-down(sm) {
        position: absolute;
        top: var(--space-xxxs);
        font-size: 10px;
      }
    }

    &__line {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid $color-additional;

      &:nth-child(2) {
        @include respond-down(md) {
          display: none;
        }
      }

      &_type {
        &_first {
          padding: var(--space-xxs) 0;
          @include respond(lg) {
            padding: var(--space-xxs) 0 var(--space-s)
          }
          @include respond(sm) {
            padding: var(--space-xs) var(--space-xxs) var(--space-xxs) 0;
          }
        }

        &_second {
          padding: var(--space-s) 0;
        }
      }
    }


    &__menu {
      display: flex;
      @include respond-down(md) {
        display: none;
      }
    }

    &__menu-item {
      flex-grow: 0;
      flex-shrink: 0;

      &:not(:last-child) {
        padding-right: var(--space-xxxxxxl);
        @include respond(lg) {
          padding-right: var(--space-xxxxxxl);
        }
      }

      a {

      }
    }

    /**
        Правая часть
     */
    &__right {
      display: flex;
    }

    &__button {
      font-size: 11px;
      color: $bg-default;
      font-weight: $bold;
      text-transform: uppercase;
      @include respond-down(lg) {
        display: none;
      }
    }

    &__actions {
      display: flex;
      margin-left: var(--space-xxxxxxxxl);
      align-items: center;
      max-height: 45px;
      @include respond(lg) {
        max-height: 32px;
      }
      @include respond-down(sm) {
        margin-left: 0;
      }
    }

    &__icons {
      display: flex;
    }

    &__icon {
      cursor: pointer;
      margin-right: var(--space-s);

      @include respond(md) {
        margin-right: var(--space-m);
      }
      @include respond(sm) {
        margin-right: var(--space-xxl);
      }

      &_search {
        @include respond-down(sm) {
          display: none
        }
      }

      &_fav {
        @include respond-down(sm) {
          display: none
        }
      }

      &_cart {
        @include respond-down(sm) {
          margin-top: calc(-1 * var(--space-xxxxs));

          .svg-icon {
            width: 30px;
          }
        }
      }
    }

    &__auth {
      display: flex;
      @include respond(lg) {
        .text {
          font-size: 14px;
        }
      }
      @include respond-down(md) {
        display: none;
        .text {
          font-size: 18px;
        }
        .delimiter {
          padding: 0 var(--space-s);
        }
      }
    }
  }
}