body {
  font-size: $body-font-size;
  font-family: $font;
  line-height: 1;

  &, *, *:before, *:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
  }

  a {
    &, &:active, &:focus, &:hover {
      color: $color-link;
      text-decoration: none;
    }
  }

  &.no-transition {
    &, *, *:before, *:after {
      transition: none !important;
    }
  }

  &.overflow-hidden {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: hidden;
  }
}

.delimiter {
  padding-left: var(--space-xxxxs);
  padding-right: var(--space-xxxxs);
  pointer-events: none;
}

.fire-hover {
  cursor: url("../images/fire.png"), auto;
}

.ws-nowrap{
  white-space: nowrap !important;
}