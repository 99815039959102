input, .input {
  display: inline-block;
  height: 50px;
  padding: 0 var(--space-xs);
  outline: none;
  border: 1px solid $color-additional;
  border-radius: 4px;

  &, &::placeholder {
    opacity: 1;
    color: $color-secondary;
    font-size: 16px;
    @include respond(md) {
      font-size: 15px;
    }
    @include respond(sm) {
      font-size: 14px;
    }
  }

  @at-root {
    &_color {
      &_transparent {
        background: $t;
      }

      &_default {
        background: $bg-default;
      }
    }

    &_size {
      @each $name, $size in $inputSizes {
        &_#{$name} {
          height: var(--input-#{$name});
        }
      }

      &_xxl {
        &, &::placeholder {
          font-size: 14px;
        }
      }
    }

    &_border {
      &_all {
        border: 1px solid $color-additional;
      }
    }

    &_rounded {
      border-radius: 4px;
    }

    &_width {
      &_full {
        width: 100%;
      }
    }
  }
}

:root {
  @each $name, $size in $inputSizes {
    --input-#{$name}: #{$size};
  }
}

input[type="file"] {
  &, & ~ label {
    color: $t;
    cursor: pointer;
    border: none;
    outline: none;
  }
}
