.preloader {
    position: relative;
    min-height: 10vh;
    &::after {
        content: "";
        position: absolute;
        background-color:rgba(#fff,0.6);
        background-image: url('../svg/preloader.svg');
        background-size: 80px;
        background-repeat: no-repeat;
        background-position: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}