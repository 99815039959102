.js-accordion {
  @mixin respondStyles {
    .js-accordion__hidden {
      display: none;
    }
    .js-accordion__visible {
      &:after {
        content: '';
        cursor: pointer;
      }
    }
  }
  @at-root {
    &_icon {
      &_plus-minus {
        .js-accordion__visible {
          position: relative;

          &:before, &:after {
            content: '';
            display: block;
            margin: 0;
            position: absolute;
            right: 0;
            top: 50%;
            background: none;
            width: 12px;
            height: 1.5px;
            background: $bg-border;
            transition: transform ease-out .2s;
            transform: translateY(-50%) rotateZ(180deg) scale(1);
            transform-origin: center center;
            @include respond-down(sm) {
              top: var(--space-xl);
              transform: translateY(0) rotateZ(180deg) scale(1);
            }
          }

          &:before {
            transform: translateY(-50%) rotateZ(90deg) scale(1);
            @include respond-down(sm) {
              transform: translateY(0) rotateZ(90deg) scale(1);
            }
          }
        }

        &.js-accordion_visible {
          .js-accordion__visible {
            &:before {
              transform: translateY(-50%) rotateZ(90deg) scale(0);
            }
          }
        }
      }
    }

    &_smooth-open {
      .js-accordion__hidden {
        max-height: 0;
        min-height: 0;
        opacity: 0;
        overflow-y: hidden;
        transition: all ease-in-out .3s;
      }

      &.js-accordion_visible {
        .js-accordion__hidden {
          opacity: 1;
          max-height: var(--expanded, 500px);
          min-height: var(--expanded, 70px);
          @include respond-down(md) {
            max-height: 2000px;
          }
        }
      }
    }

    &_visible {
      .js-accordion__visible {
        &::after {
          transform: rotateZ(180deg);
        }
      }

      &.js-accordion_respond_sm {
        .js-accordion__hidden {
          display: block;
        }
      }
    }

    &__visible {
      width: 100%;
      position: relative;
      cursor: pointer;

      &::after {
        display: inline-block;
        width: 12px;
        height: 6px;
        position: absolute;
        top: 50%;
        right: var(--space-xxs);
        margin-top: -3px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpolyline points='271 572 277 578 283 572' transform='translate(-270 -571)' fill-rule='nonzero' stroke='%23000000' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        pointer-events: none;
        transition: transform ease-out .2s;
      }
    }

    &__hidden {

    }

    &_respond {
      &_all {
        .js-accordion__hidden {
          display: none;
        }
      }

      &_sm {
        @include respond-down(sm) {
          @include respondStyles;
        }
      }

      &_md {
        @include respond(md) {
          @include respondStyles;
        }
      }
    }
  }
  .js-accordion__trigger {
    display: none;

    @include respond-down(md) {
      display: block;
    }
  }
}