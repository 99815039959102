.pagination-wrapper {
  position: relative;
  padding-bottom: 35px;
}

.pagination-block {
  position: relative;
  text-align: center;
}

.pagination-list {
  display: inline-flex;
  align-items: center;

  & > * {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  li {

    &.active {
      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        top: calc(100% + 5px);
        left: -1px;
        width: 100%;
        border: 1px solid #000000;
      }
    }

    a, span {
      font-size: 18px;
      color: #000000;
    }

    &.first,
    &.dots,
    &.page,
    &.last {
      @include respond(sm) {
        display: none;
      }
    }
  }

  .prev, .next {
    height: 50px;
    width: 50px;
    border: 2px solid #000000;

    a, span {
      display: inline-block;
      position: relative;
      height: 100%;
      width: 100%;
      color: transparent;

      &::after {
        content: ' ';
        position: absolute;
        top: 17px;
        height: 10px;
        width: 10px;
        border-top: 2px solid #000000;
      }
    }
  }

  .prev a::after,
  .prev span::after {
    left: 19px;
    border-left: 2px solid #000000;
    transform: rotate(-45deg);
  }

  .next a::after,
  .next span::after {
    right: 19px;
    border-right: 2px solid #000000;
    transform: rotate(45deg);
  }
}