.b-news-card {
  display: flex;
  width: 100%;
  padding-top: var(--space-xxs);
  margin-bottom: var(--space-xxxxxxxl);
  border-top: 3px solid var(--bg-border);

  @include respond(lg) {
    margin-bottom: var(--space-xxxxxxl)
  }
  @include respond(md) {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-l);
    margin-bottom: 0;
  }
  @include respond-down(sm) {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xxl);
    margin-bottom: 0;
    flex-direction: column;
  }
  @at-root {
    &__date-wrapper {
      flex: 0 0 28%;
      @include respond-down(lg) {
        flex: 0 0 27%;
      }
      @include respond(md) {
        flex: 0 0 23%;
      }
      @include respond-down(sm) {
        flex: 0 0 100%;
      }
    }

    &__date {
      display: flex;
      align-items: center;
      padding-left: var(--space-xxs);
      @include respond-down(sm) {
        padding-left: 0;
      }
    }

    &__day {
      font-size: 60px;
      @include respond(sm) {
        font-size: 38px;
      }
    }

    &__month-year {
      margin-left: var(--space-xxs);
      @include respond(lg) {
        margin-left: var(--space-xxxs);
      }
      @include respond(md) {
        margin-left: var(--space-xxxs);
      }
    }

    &__month {
      font-size: 15px;
      margin-bottom: var(--space-xxxxs);
    }

    &__year {
      font-size: 12px;
    }

    &__content {
      padding-top: var(--space-xxxxs);
      @include respond(md) {
        padding-top: var(--space-xxxs);
      }
      @include respond-down(sm) {
        padding-top: var(--space-xxs);
      }
    }

    &__title {
      display: block;
      min-height: 72px;
      font-size: 20px;
      line-height: 22px;
      @include respond-down(md) {
        min-height: 50px;
      }
    }

    &__text {
      margin-top: var(--space-xs);
      @include respond(md) {
        margin-top: var(--space-xs);
      }
      @include respond-down(sm) {
        margin-top: var(--space-xxxs);
      }

      &, p {
        font-size: 16px;
        line-height: 24px;
        @include respond-down(lg) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}