body.contacts-page {
  .layout__container_no-gap_lg-up {
    max-width: none;
  }

  .page-title {
    margin-top: calc(var(--space-xxxxxxl) + var(--space-xxxxs));
    margin-bottom: var(--space-xxxxxxl);

    @include respond(lg) {
      margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxxs));
      font-size: 56px;
    }

    @include respond(md) {
      margin-top: var(--space-xxxxxxl);
      margin-bottom: var(--space-xxxxxxxxl);
      font-size: 47px;
    }

    @include respond(sm) {
      margin-top: calc(var(--space-xxxxl) + var(--space-xxxs));
      margin-bottom: var(--space-l);
      font-size: 28px;
    }
  }

  .section {
    @at-root {
      &_name {
        &_map {
          position: relative;
          overflow-x: hidden;

          @include respond-up(lg) {
            .grid {
              position: absolute;
              top: 0;
              right: var(--grid-gap);
              bottom: 0;
              left: var(--grid-gap);
              pointer-events: none;
            }
          }

          .map-info-window-wrapper {
            position: relative;
            pointer-events: all;
          }

          .map-info-window {
            border: 1px solid $color-secondary;

            @include respond-up(lg) {
              opacity: 0;
              pointer-events: none;

              position: absolute;
              top: 50%;
              right: 0;
              left: 0;

              transform: translateY(-50%);
              z-index: 9999;

              &_active {
                opacity: 1;
                pointer-events: all;
              }
            }

            @include respond(lg) {

            }

            @include respond(md) {
              position: static;
              padding: var(--space-xxxxxl) var(--space-xxxxxxxl) var(--space-xxxxxxl);
            }

            @include respond(sm) {
              position: static;
              padding: var(--space-l) var(--space-m) var(--space-l) var(--space-xxs)
            }
          }
        }

        &_requisites {
          margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxxs));

          @include respond(lg) {
            margin-top: calc(var(--space-xxxxxxl) + var(--space-s));
          }
          @include respond(md) {
            margin-top: var(--space-xxxxxxxxxxl);
          }
          @include respond(sm) {
            margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxs));
          }

          .card {
            @at-root {
              &__title {
                font-size: 38px;

                @include respond(md) {
                  font-size: 32px;
                }

                @include respond(sm) {
                  font-size: 22px;
                }
              }

              &__content {
                margin-top: var(--space-xxxxxl);
                font-size: 18px;
                line-height: 2;

                @include respond(lg) {
                  margin-top: var(--space-xxxxl);
                }

                @include respond(md) {
                  margin-top: var(--space-xl);
                  font-size: 16px;
                }
                @include respond(sm) {
                  margin-top: var(--space-xl);
                  font-size: 14px;
                }
              }
            }
          }
        }

        &_subscribe-form {
          margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxs));

          @include respond(lg) {
            margin-top: calc(var(--space-xxxxxxxxxxxl) + var(--space-xxxxs));
          }

          @include respond(md) {
            margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxxs));
          }

          @include respond(sm) {
            margin-top: calc(var(--space-xxxxxxxxxl) + var(--space-xxxs));
          }
        }
      }
    }
  }

  .map {
    display: block;
    width: 100%;
    height: 566px;
    background-image: url('../svg/preloader.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 64px 64px;

    @include respond-down(md) {
      height: 420px;
    }

    @include respond(sm) {
      height: 280px;
    }

    .gm-style-iw {
      border-radius: 0;
      box-shadow: none;
    }

    .gm-style-iw-d ~ button {
      top: 0 !important;
      right: 0 !important;

      img {
        margin: 0 auto !important;
        width: 24px !important;
        height: 24px !important;
      }
    }

    .gm-style-iw-c {

    }

    .gm-style-iw-d, .gm-style-iw-c, .gm-style-iw-t {
      max-height: none !important;
      padding: 0 !important;

      &:after {
        content: none !important;
      }
    }

    @at-root {

    }
  }

  .map-info-window {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: var(--space-xxxxl) var(--space-xxxxxxxxl) var(--space-xxxxxl);
    background: $bg-default;

    @include respond-up(lg) {
      max-height: 423px;
      max-width: 537px;
    }
    @include respond(lg) {
      max-width: 563px;
    }


    @at-root {
      &__title {
        margin-bottom: var(--space-xl);
        font-size: 38px;

        @include respond(sm) {
          margin-bottom: var(--space-xl);
          font-size: 24px;
        }
      }

      &__list {

      }

      &__list-item {
        display: flex;
        align-items: center;
        font-weight: $bold;
        font-size: 18px;
        line-height: 24px;
        color: $color-primary;

        a{
          color: $color-black;
        }

        &:not(:last-child) {
          margin-bottom: var(--space-s);
        }

        &:before {
          content: '';
          display: inline-block;
          width: 24px;
          height: auto;
          margin-right: var(--space-xs);
          flex: 0 0 24px;
          background-repeat: no-repeat;
          background-size: contain;
        }

        @include respond(sm) {
          font-size: 16px;
        }

        &_name {
          &_placemark {
            &:before {
              height: 21px;
              background-image: url('../svg/placemark.svg');
            }
          }

          &_phone {
            &:before {
              height: 25px;
              background-image: url('../svg/smartphone.svg');
            }
          }

          &_mail {
            &:before {
              height: 15px;
              background-image: url('../svg/mail.svg');
            }
          }
        }
      }

      &__button {
        width: 230px;
        margin-top: var(--space-xxl);

        @include respond-down(md) {
          height: var(--input-xl);
          font-size: 14px;
          line-height: var(--input-xl);
        }
        @include respond(sm) {
          width: 100%;
          margin-top: var(--space-l);
        }
      }
    }
  }
}