body.catalog-view-page {
  /**
    Секции
   */
  .section {
    &_name {
      &_product {
        @include respond(xl) {
          margin-top: var(--space-xxxxxxxxl);
        }
        @include respond(lg) {
          margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxxs));
        }
      }

      &_images {
        @include respond-up(lg) {
          position: sticky;
          top: 0;
        }

        @include respond(lg) {
          margin-top: calc(-1 * var(--space-m));
        }

        @include respond-down(md) {
          margin-top: var(--space-xxxxl);
        }
      }

      &_other-products {
        @include respond(xl) {
          margin-top: var(--space-xxxxxxxxxxl);
        }

        @include respond(lg) {
          margin-top: var(--space-xxxxxxxxxxxl);
        }

        @include respond(md) {
          margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxxs));
        }

        @include respond(sm) {
          margin-top: calc(var(--space-xxxxxxxl) + var(--space-xxxxs));

          .title-w-controls {
            padding: 0 20px;
          }
        }
      }

      &_subscribe-form {
        @include respond(xl) {
          margin-top: calc(var(--space-xxxxxxxxxxl) + var(--space-xxxxs))
        }

        @include respond(lg) {
          margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxxs));
        }

        @include respond-down(md) {
          margin-top: calc(var(--space-xxxxxxxxxxxl) + var(--space-xxxxs));
        }
      }
    }

    .title-w-controls {
      &__title {
        line-height: 1.31;

        @include respond-down(lg) {
          font-size: 38px;
          line-height: 1.25;
        }
        @include respond(sm) {
          font-size: 28px;
        }
      }
    }
  }

  .product {
    @at-root {
      &__content {
        @include respond(lg) {

        }
      }

      &__content-section {
        &_name {
          &_info {

          }

          &_card {
            @include respond-down(lg) {
              order: -1;
            }
            @include respond(md) {
              margin-top: var(--space-xs);
            }
            @include respond(sm) {
              margin-top: var(--space-l);
            }
          }

          &_buy {

          }
        }
      }

      /**
        Слайдеры
       */
      &__images-slider {
        position: relative;

        [data-glide-el="controls"] {
          position: absolute;
          display: flex;
          width: 100%;
          top: 50%;
          align-items: center;
          justify-content: space-between;
          pointer-events: none;

          & * {
            pointer-events: all;
          }
        }

        .glide__bullets {
          position: relative;
          top: var(--space-xxxxl);
          bottom: initial;
        }


        .product__image {
          max-height: 465px;
          height: 100%;

          @include respond(sm) {
            max-height: 300px;
          }
        }
      }

      &__images-slider-nav {
        @include respond(xl) {
          width: 80%;
          margin-top: calc(var(--space-xxxxxxl) + var(--space-xxxxs));
        }
        @include respond(lg) {
          margin-top: var(--space-xs);
        }
        @include respond-down(md) {
          margin-top: var(--space-xs);
        }

        .glide__slide {
          border-bottom: 2px solid $t;
          transition: border-bottom-color ease-out .3s;

          &.glide__slide--active {
            border-bottom-color: $bg-border;
          }
        }

        .product__image {
          max-height: 120px;
          height: 100%;

          @include respond-down(md) {
            max-height: 115px;
          }
        }
      }

      &__image-wrapper {
        display: flex;
        @include respond-down(sm) {
          margin: 0 -20px;
        }
      }

      &__image {
        display: block;
        width: 100%;
        margin: 0 auto;
        object-fit: contain;
      }

      /**
        Контент
       */
      &__top-info {
        @include respond(xl) {
          margin-top: var(--space-m);
        }
        @include respond-down(sm) {
          //margin-top: var(--space-xxxs);
        }
      }

      &__links {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;

        @include respond-down(lg) {
          font-size: 14px;
        }

        @include respond-down(sm) {
          flex-direction: column;
          .link {
            position: relative;

            .icon {
              position: absolute;
              top: -3px;
              left: -32px;

              .svg-icon {
                width: 20px;
              }
            }

            &:not(:last-child) {
              margin-bottom: var(--space-l);
            }
          }
        }
      }

      &__vendor-code {
        font-size: 14px;

        @include respond(xl) {
          margin-top: var(--space-xxl);
        }

        @include respond(lg) {
          margin-top: var(--space-xl);
        }

        @include respond(md) {
          margin-top: var(--space-xl);
        }
      }

      &__title {
        @include respond(xl) {
          margin-top: var(--space-s);
          margin-bottom: var(--space-xxxs);
          font-size: 42px;
          line-height: calc(46 / 42);
        }

        @include respond-down(lg) {
          margin-top: var(--space-s);
          font-size: 38px;
          line-height: 38px;
        }

        @include respond(md) {
          margin-top: var(--space-xs);
          font-size: 32px;
        }

        @include respond(sm) {
          display: none;
        }
      }

      &__dl {
        padding: var(--space-xxs) 0;
        font-size: 18px;
        line-height: calc(43 / 18);

        &:not(:last-child) {
          border-bottom: 1px solid $color-additional;
        }

        @include respond(xl) {
          line-height: calc(48 / 18);
        }

        @include respond-down(lg) {
          padding: var(--space-xxxs) 0 var(--space-xxs) 0;
          font-size: 16px;
          line-height: 33px;
        }

        @include respond(sm) {
          font-size: 14px;
          line-height: 24px;
        }


        &_columns {
          &_2 {
            column-count: 2;

            @include respond-down(sm) {
              column-count: 1;
            }
          }
        }

        &_direction {
          &_column {
            .product__dt {
              float: initial;
              @include respond-down(lg) {
                line-height: 2.7;
              }
            }

            .product__dd {
              @include respond(sm) {
                margin-top: var(--space-xxxxs);
              }
            }
          }
        }
      }

      &__dt {
        float: left;
        margin-right: var(--space-xxxxs);
        font-weight: $medium;
        color: $color-secondary;
      }

      &__dd {
        font-weight: $bold;
        color: $color-primary;
        page-break-inside: avoid;
        &_name {
          &_category {
            a {
              @include respond(xl) {
                min-width: 0;
                margin-left: var(--space-xxs);
                margin-bottom: var(--space-xxxxs);
                padding: var(--space-xxxs) var(--space-s);

                border: 1px solid var(--bg-border);
                border-radius: 5px;
                font-size: 14px;
              }

              @include respond-down(lg) {
                font-size: 15px;

                .text {
                  text-decoration: underline;
                  font-weight: $bold;
                }
              }

              @include respond-down(md) {
                font-size: 14px;
              }
              @include respond(sm) {
                margin-left: var(--space-xxs);
              }
            }
          }
        }

        .input-group {
          &:not(:last-child) {
            margin-right: var(--space-xxxxs);
          }

          @include respond-down(lg) {
            margin-bottom: var(--space-xxxxs);
          }
          @include respond(sm) {
            margin-bottom: var(--space-xxxs);
          }
        }
      }


      &__price {
        font-size: 24px;
        line-height: 52px;
        white-space: nowrap;
        overflow-x: hidden;

        @include respond-down(lg) {
          line-height: normal;
          margin-top: calc(-1 * var(--space-xxxs));
        }
        @include respond(sm) {
          margin-top: calc(-1 * var(--space-xxxxs));
        }
      }

      &__price-sum {
        margin-left: var(--space-xxxs);
        font-size: 40px;
      }


      &__to-cart {
        display: flex;
        margin-top: var(--space-xl);
        align-items: flex-start;
        @include respond-up(xl) {
          flex-wrap: wrap;
          flex-grow: 1;
        }
        @include respond-down(md) {
          margin-top: var(--space-l);
        }

        @include respond(sm) {
          flex-direction: column;
        }
      }

      &__to-cart-button {
        @include respond-up(md) {
          margin-left: var(--space-l);
          margin-right: var(--space-l);
        }

        @include respond-down(sm) {
          margin-top: var(--space-xxxs);
          height: var(--input-xl);
          width: 100%;
        }
      }

      &__to-cart-info {
        display: inline-block;
        max-width: 234px;
        //margin-left: auto;
        font-size: 14px;
        line-height: 18px;

        @include respond-down(sm) {
          max-width: none;
          margin-top: var(--space-m);
          font-size: 13px;
          line-height: 16px;
        }
      }

      &__description-block {
        margin-top: var(--space-xs);
        font-size: 16px;
        line-height: 24px;

        @include respond-down(lg) {
          margin-top: var(--space-xxxs);
          font-size: 14px;
          line-height: 1.6;
        }

        @include respond(sm) {
          margin-top: var(--space-xxxl);
          line-height: 1.3;
        }
      }

      &__description-title {
        padding-bottom: var(--space-s);
        font-size: 24px;
        border-bottom: 2px solid $bg-border;

        @include respond-down(lg) {
          padding-bottom: var(--space-xs);
        }

        @include respond(sm) {
          padding-bottom: var(--space-xxs);
          font-size: 18px;
        }
      }

      &__description {
        margin: var(--space-xxl) 0 var(--space-xs);

        @include respond-down(lg) {
          margin: var(--space-l) 0 var(--space-xxs);
        }
        @include respond(sm) {
          margin: var(--space-s) 0 var(--space-xs);
        }
      }

      &__pros-list {
        line-height: 1.85;

        @include respond-down(lg) {
          line-height: 2.1;
        }

        @include respond(sm) {
          line-height: 1.6;
        }
      }

      &__pros-item {
        &:before {
          display: inline;
          content: '-';
        }

        @include respond(sm) {
          line-height: 18px;

          &:not(:last-child) {
            margin-bottom: var(--space-xxxs);
          }
        }
      }

      /**
          Похожие товары
       */
      &__other-slider {
        & .glide__slide {
          height: auto;
        }
        & .b-product-card {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        @include respond(xl) {
          margin-top: calc(var(--space-xxxxxxxxxxl) + var(--space-xxxxs));
        }

        @include respond(lg) {
          margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxxs));
        }

        @include respond(md) {
          margin-top: var(--space-xxxxxxxl);
        }

        @include respond(sm) {
          margin-top: var(--space-xxxxl);
        }

        .glide__bullets {
          position: relative;
          bottom: initial;
          top: var(--space-s);
        }
      }

      &__other-button {
        display: block;
        padding: 0;
        width: 296px;
        margin-left: auto;
        margin-right: auto;

        &, .text {
          font-size: 11px;
        }

        @include respond(xl) {
          margin-top: var(--space-xxxxxxxxxxxl);
        }

        @include respond-down(lg) {
          margin-top: var(--space-xxxxxxxxl)
        }

        @include respond(sm) {
          margin-top: var(--space-xxxxxxxl);
        }
      }

      /**
          Контакты продавца карточка
       */
      &__contact-card {
        @include respond(xl) {
          margin-top: var(--space-xxxs);
          padding: var(--space-s) var(--space-xs) var(--space-xs) var(--space-s);
        }

        @include respond-down(lg) {
          margin-top: var(--space-m);
          padding: var(--space-xs) var(--space-xxs) var(--space-xs) var(--space-s);
        }

        @include respond(md) {
          margin-top: 0;
          padding-bottom: var(--space-s);
        }
        @include respond(sm) {
          padding: var(--space-xs) var(--space-xs) var(--space-m) var(--space-xs);
        }

        .card__title {
          padding-bottom: var(--space-s);
          font-size: 22px;
          line-height: calc(28 / 22);
          border-bottom: 1px solid currentColor;

          @include respond-down(lg) {
            padding-bottom: var(--space-xxs);
            font-size: 20px;
            line-height: 30px;
          }

          @include respond(md) {
            padding-bottom: var(--space-xs);
          }
          @include respond(sm) {
            padding-bottom: var(--space-xxs);
            font-size: 18px;
            line-height: 22px;
          }
        }

        .card__content {
          padding-top: var(--space-s);
          padding-right: var(--space-xxxxs);
          font-size: 18px;
          line-height: calc(25 / 18);

          @include respond(lg) {
            padding-top: var(--space-xs);
          }

          @include respond-down(lg) {
            flex-direction: row;
            flex-wrap: wrap;
          }

          @include respond-down(md) {
            padding-top: var(--space-xxs);
          }

          @include respond(sm) {
            padding-top: var(--space-xs);
            font-size: 16px;
            line-height: 22px;
          }
        }

        .card__line {
          @include respond(xl) {
            &:not(:last-child) {
              margin-bottom: var(--space-xxxxs);
            }
          }

          @include respond-down(lg) {
            flex: 0 0 50%;
            line-height: 22px;
          }

          @include respond(sm) {
            flex: 0 0 100%;
            &:not(:last-child) {
              margin-bottom: var(--space-xxxs);
            }
          }

          .text {
            overflow-wrap: break-word;
          }
          &_name {
            &_name {
              order: 1;
              @include respond-down(lg) {
                flex-basis: 100%;
                line-height: 25px;
                margin-bottom: 10px;
              }
              @include respond(sm) {
                order: 1;
              }
            }

            &_address {
              order: 3;
              @include respond-down(lg) {
                order: 2;
              }
              @include respond(sm) {
                order: 3;
              }
            }

            &_phone {
              order: 2;
              @include respond-down(lg) {
                order: 3;
              }
              @include respond(sm) {
                order: 2;
              }
            }
          }
        }
      }
    }
  }

}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}