body.learning-page, body.learning-view-page {
  .layout {
    @at-root {
      & > .layout__container {
        position: relative;
      }
    }

    h1.regular_size{
      font-size: 42px;
    }
  }

  .banner-learning{
    width: 100%;
    img{
      width: 100%;
    }
  }

  .title-w-controls {
    @include respond(xl) {
      margin-bottom: calc(var(--space-xxxxxxxxl) + var(--space-xxxs));
    }

    @include respond(lg) {
      margin-bottom: var(--space-xxxxxxxxxl);
    }

    @include respond(md) {
      margin-bottom: calc(var(--space-xxxxxxxxl) + var(--space-xxxxs));
    }
    @include respond(sm) {
      margin-bottom: calc(var(--space-xxxxxxxxxl) + var(--space-xxxxs));
    }

    @at-root {
      &__title {
        @include respond(xl) {
          font-size: 72px;
        }

        @include respond(lg) {
          font-size: 62px;
        }

        @include respond(md) {
          font-size: 48px;
        }

        @include respond(sm) {
          font-size: 32px;
        }
      }
    }
  }

  .section {
    &_name {

      &_stats{
        margin: 30px 0px;
        ul{
          padding: 0px;
          margin: 0px;
          list-style: none;
          li{
            font-size: 24px;
            padding: 20px 0px;

            @include respond(md) {
              font-size: 20px;
            }

            @include respond(sm) {
              font-size: 16px;
            }
          }
        }
        .button {
          @include respond(sm) {
            min-width: 125px;
            font-size: 10px;
          }
        }
      }

      &_hero {
        @include respond(xl) {
          margin-top: var(--space-xs);
        }
        @include respond(lg) {
          margin-top: var(--space-xs);
        }
        @include respond(md) {
          padding-top: var(--space-xs);
          padding-right: 0;
        }

        @include respond(sm) {
          padding-top: var(--space-xs);

          .b-hero__image {
            height: 190px;
          }
        }
      }

      &_filter {
        @include respond(xl) {
          margin-top: calc(var(--space-xxxxxxl) + var(--space-xxxxs));
        }

        @include respond(lg) {
          margin-top: var(--space-xxxxxl);
        }

        @include respond-down(md) {
          margin-top: var(--space-xs);
        }
        @include respond(sm) {
          margin-top: var(--space-xxl);
        }
      }

      &_featured {
        margin-top: var(--space-xxxxxxxxl);
        @include respond-down(md) {
          margin-top: var(--space-l);
        }

        @include respond(sm) {
          margin-top: var(--space-m);
        }
      }

      &_programs {
        margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xs));

        @include respond(lg) {
          margin-top: calc(var(--space-xxxxxxxxxxl) + var(--space-xxxxs));
        }

        @include respond-down(md) {
          margin-top: calc(var(--space-xxxxxxxxxxxl) + var(--space-xxxxs));
        }

        @include respond(sm) {
          margin-top: var(--space-xxxxxl);

          .title-w-controls {
            margin-bottom: var(--space-xxxl);
          }
        }

      }


      &_webinars {
        padding: var(--space-xxxxxxxxxl) 0 var(--space-xxxxxxxxxxxl);
        margin-top: var(--space-xxxxxxxxxxxl);

        @include respond(lg) {
          padding: var(--space-xxxxxxl) 0 var(--space-xxxxxxxxl);
          margin-top: var(--space-xxxxxxxxxl);
        }

        @include respond-down(md) {
          padding: var(--space-xxxxxxxl) 0 var(--space-xxxxxxxxl);
          margin-top: var(--space-xxxxxxxxxl);
        }

        @include respond(sm) {
          padding: var(--space-xxxxxxxl) 0 var(--space-xxxxxxxxl);
          margin-top: var(--space-xxxxxxxxxxl);

          .title-w-controls {
            margin-bottom: var(--space-xxxl);
          }
        }
      }

      &_video-courses {
        padding: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxxs)) 0 var(--space-xs);

        @include respond(lg) {
          padding: var(--space-xxxxxxxxxl) 0 var(--space-xs)
        }

        @include respond-down(md) {
          padding: var(--space-xxxxxxxxxxxxl) 0 var(--space-xs);
        }

        @include respond(sm) {
          padding: var(--space-xxxxxxxxl) 0 var(--space-xs);
          .title-w-controls {
            margin-bottom: var(--space-xxxxxl);
          }
        }
      }

      &_get {
        padding: var(--space-xxxxxxxxxl) 0;
        margin-top: var(--space-xxxxl);

        @include respond(lg) {
          padding: var(--space-xxl) 0;
        }

        @include respond-down(md) {
          padding: 0;
          margin-top: var(--space-xxxxxxxl);
        }

        @include respond(sm) {
          margin-top: var(--space-xxxxxxxxxl);

          .title-w-controls {
            margin-bottom: var(--space-xxxl);
          }
        }
      }

      &_teachers {
        margin-top: var(--space-s);
        padding: var(--space-xxxxxxxxxl) 0 var(--space-xxxxxxxxl);

        @include respond(lg) {
          padding: var(--space-xxxxxxxxl) 0 var(--space-xxxxxxxxl);
        }

        @include respond(md) {
          padding: var(--space-xxxxxxxxl) 0 var(--space-xxxxxxxxl);
        }

        @include respond(sm) {
          padding: var(--space-xxxl) 0 var(--space-xxxxxxxl);
          margin-top: var(--space-xxxxl);

          .title-w-controls {
            margin-bottom: var(--space-xxxxl);
          }
        }

        .slider {
          margin-top: var(--space-xl);
        }
      }

      &_testimonials {
        margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxxxs));

        @include respond-down(md) {
          margin-top: var(--space-xxxxxxxl);
        }

        @include respond(sm) {
          margin-top: var(--space-xxxxxxxxxl);

          .title-w-controls {
            margin-bottom: var(--space-xxxxl);
          }
        }
      }

      &_admins {
        @include respond(xl) {
          margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxxl));
        }

        @include respond(lg) {
          margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxxxs));
        }

        @include respond(md) {
          margin-top: calc(var(--space-xxxxxl) + var(--space-xxxl));

          .title-w-controls {
            margin-bottom: var(--space-xxxxxxl);
          }
        }

        @include respond(sm) {
          margin-top: var(--space-xxxxxxxxxxxl);

          .title-w-controls {
            margin-bottom: var(--space-xxxxl);
          }
        }
      }

      &_subscribe-form {
        margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxs));

        @include respond(lg) {
          margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxxxs));
        }

        @include respond-down(md) {
          margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxxxs));
        }
      }
    }

    .glide {
      .glide__bullets {
        position: relative;
        top: var(--space-l);
        bottom: initial;
        flex-wrap: wrap;
        justify-content: center;
        button {
          margin-bottom: 5px;
        }

        @include respond(sm) {
          top: var(--space-m);
        }
      }
    }
  }

  .learning {
    @at-root {
      &__text {
        font-size: 18px;
        line-height: 23px;

        @include respond-down(md) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      &__get-list {
        display: flex;
        justify-content: space-between;

        @include respond-down(md) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
      }

      &__get-item {
        @include respond-up(lg) {
          flex: 1 1;
        }

        @include respond(md) {
          flex: 0 0 33.33%;
          margin-bottom: var(--space-xxxxxxl);

          &:last-child:nth-of-type(5), &:last-child:nth-of-type(4) {
            flex: 0 0 66.66%;

            .learning__get-text {
              max-width: none;
            }
          }
        }

        @include respond(sm) {
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: flex-start;

          &:not(:last-child) {
            margin-bottom: var(--space-m);
          }
        }
      }

      &__get-icon {
        display: flex;
        align-items: center;
        width: 82px;
        height: 100px;
        border-bottom: 4px solid $bg-border;

        @include respond(sm) {
          flex: 0 0 57px;
          width: 57px;
          height: 45px;
          margin-right: var(--space-xs);
          align-items: flex-start;
          border-bottom-color: $t;

          .svg-icon {
            height: 100%;
          }
        }
      }

      &__get-text {
        max-width: 300px;
        margin-top: var(--space-m);

        font-size: 32px;
        line-height: 39px;
        font-weight: $medium;
        padding-right: var(--space-xxxs);

        @include respond(lg) {
          margin-top: var(--space-xxl);
        }

        @include respond-down(lg) {
          font-size: 18px;
          line-height: 24px;
        }

        @include respond(sm) {
          margin-top: 0;
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
  }

  .card {
    @at-root {
      &_name {
        &_contacts {
          a{
            color: $color-black;
          }

          padding: var(--space-xl) var(--space-m) var(--space-xxxl);
          border-color: $color-secondary;

          @include respond(sm) {
            border-color: $t;
          }

          @include respond(md) {
            margin-top: var(--space-xl);
            padding: var(--space-xl) var(--space-xxxxl) var(--space-xxxl);
          }

          @include respond(sm) {
            margin-top: var(--space-xxl);
            padding: 0;
          }

          .card__list {

          }

          .card__list-item {
            display: flex;
            align-items: center;
            font-weight: $bold;
            font-size: 24px;
            line-height: 24px;
            color: $color-primary;

            &:not(:last-child) {
              margin-bottom: var(--space-s);
            }

            &:before {
              content: '';
              display: inline-block;
              width: 24px;
              height: auto;
              margin-right: var(--space-xs);
              flex: 0 0 24px;
              background-repeat: no-repeat;
              background-size: contain;
            }

            @include respond(sm) {
              font-size: 16px;
            }

            &_name {
              &_placemark {
                &:before {
                  height: 21px;
                  background-image: url('../svg/placemark.svg');
                }
              }

              &_phone {
                &:before {
                  height: 25px;
                  background-image: url('../svg/smartphone.svg');
                }
              }

              &_mail {
                &:before {
                  height: 15px;
                  background-image: url('../svg/mail.svg');
                }
              }
            }
          }
        }
      }
    }
  }

  .b-filter {
    @at-root {
      &__part {
        &, &:first-of-type {
          width: 100%;
        }

        @include respond(sm) {
          .button {
            height: var(--input-xl);
            line-height: calc(var(--input-xl) - 1px);

            .text {
              font-size: 14px;
            }
          }
          &:not(:last-child) {
            margin-bottom: var(--space-s);
          }
        }
      }
    }
  }

  .info {
    padding: var(--space-xxxxxl);
    border: 1px solid #979797;

    @include respond(sm) {
      padding: var(--space-xl);
    }

    &__section {
      margin-top: 50px;
      margin-bottom: 10px;
    }

    &__item {
      padding: var(--space-xxs) 0;
      &:first-child {
        border-right: 4px solid #000000;
        padding-right: 8px;
      }

      &:last-child {
        padding-left: var(--space-xxxl);
        @include respond(sm) {
          padding-left: var(--space-l);
        }
      }
    }
  }

  .common-info {
    padding: var(--space-xxxxxl);
    border: 1px solid #979797;
    @include respond(sm) {
      padding: var(--space-xl);
    }

    &__item {
      padding-left: var(--space-l);

      @include respond-down(md) {
        display: flex;
        justify-content: space-between;
        padding-left: 0;
      }

      .button {
        margin-left: var(--space-xxxxxxxxl);

        @include respond-down(lg) {
          margin-left: var(--space-xxxl);
        }

        @include respond-down(md) {
          margin-right: var(--space-xxxxxl);
        }

        @include respond-down(sm) {
          margin-right: 0;
          margin-left: var(--space-m);
        }
      }
    }
  }

  .logo {
    .b-hero__image {
      @include respond(xl) {
        object-fit: contain;
      }
    }
  }
}