.cat-filter {
  position: relative;
  height: 100%;
  z-index: 3;
  background: $bg-default;

  @at-root {
    &__container {
      position: relative;
      @include respond(xl) {
        top: var(--space-xl);
        position: sticky;
      }

      @include respond(sm) {
        grid-row-gap: 0;
      }
    }

    &__list-wrapper {
      @include respond(lg) {
        display: none;

        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        &_active {
          display: block;

          .cat-filter__list {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
          }
        }
      }
    }

    &__list {
      position: relative;
      padding: 0;
      background: $bg-default;

      @include respond(lg) {
        opacity: 0;
        transform: translateY(calc(-1 * var(--space-xs)));
        pointer-events: none;
        transition: all ease .3s;

        position: absolute;
        top: calc(var(--space-xxxl) + var(--space-xs));
        right: 0;
        left: 0;

        padding-left: var(--grid-gap);
        padding-right: calc(2 * var(--space-xxxxxxxxxxxxl));

        column-count: 2;
        column-gap: var(--grid-gap);

        border: 1px solid $color-secondary;
      }

      @include respond(md) {
        column-count: 2;
        column-gap: var(--grid-gap);
      }
    }

    &__list-item {
      border-bottom: 1px solid $color-secondary;
      transition: all ease-out .2s;

      @include respond-up(xl) {
        span {
          padding-right: var(--space-xxxs);
        }
      }

      .icon-arrow-4x9_bold {
        display: none;
      }

      &_active {
        padding-left: var(--space-xxs);

        @include respond(sm) {
          padding: 0 0 var(--space-s);

          .cat-filter__list-link {
            position: sticky;
            top: calc(-1 * var(--space-l));
            right: 0;
            left: 0;
            z-index: 1;
            border-bottom: 1px solid $color-secondary;
            color: $color-primary;
            background: $bg-default;
          }

          .cat-filter__sub-list {
            opacity: 1;
            display: block;
            pointer-events: all;
          }
        }

        .icon-arrow-4x9 {
          display: none;
        }

        .icon-arrow-4x9_bold {
          display: block;
        }
      }

      @include respond-up(lg) {
        &:hover {
          padding-left: var(--space-xxs);

          .icon-arrow-4x9 {
            display: none;
          }

          .icon-arrow-4x9_bold {
            display: block;
          }
        }
      }

      & > a {
        padding: var(--space-xxs) 0;

        @include respond(sm) {
          padding: var(--space-xs) 0;
        }
      }
    }

    &__list-link {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      @include respond(sm) {
        color: $color-secondary;
      }
    }

    &__sub-list {
      opacity: 0;
      pointer-events: none;
      display: flex;
      min-width: 100%;
      justify-content: space-between;
      transition: opacity ease-out .2s;
      background: $bg-default;
      border: 1px solid $color-secondary;
      z-index: 5;

      @include respond-up(lg) {
        width: 65vw;
        position: absolute;
        top: var(--space-xl);
        left: calc(100% + var(--space-xs));
        padding: var(--space-l) var(--space-xl);
      }

      @include respond(lg) {
        width: calc(100% + 3px);
        padding: calc(var(--space-xxxxxxxxxl) + var(--space-xxxxs)) var(--space-l) var(--space-m);
        top: -1px;
        left: -1px;
        right: 0;
      }

      @include respond-down(md) {
        display: none;
      }

      @include respond(sm) {
        padding-top: var(--space-xxs);
        border-color: $t;
      }

      &_xl-active {
        @include respond(xl) {
          opacity: 1;
          pointer-events: all;
        }
      }

      &_lg-active {
        @include respond(lg) {
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    &__sub-list-col {
      &:not(:last-child) {
        //margin-right: var(--grid-gap);
      }
    }

    &__button-back {
      @include respond(xl) {
        display: none;
      }

      position: absolute;
      top: var(--space-l);
      left: var(--space-l);
      font-size: 12px;
      font-weight: $medium;

      @include respond(lg) {
        min-width: 167px;
      }
    }

    &__sub-item {
      display: block;
      font-size: 14px;
      min-width: 200px;

      a {
        position: relative;
        transition: all ease-out .2s;
        line-height: 2;

        &:before {
          opacity: 0;
          content: '';
          display: inline-block;
          position: absolute;
          left: -7px;
          top: 4px;
          width: 7px;
          height: 11px;
          background-image: url('../svg/arrow-4x9_bold.svg');
          background-repeat: no-repeat;
          background-size: contain;
          transition: all ease-out .2s;
        }
      }

      a:hover, &_active a {
        font-weight: $bold;
        color: $color-primary;
        padding-left: var(--space-xxs);

        &:before {
          opacity: 1;
          left: 0;
        }
      }

      &_all-cats {
        position: relative;
        font-size: 16px;
        font-weight: $medium;

        a {
          border-bottom: 1px solid currentColor;

          &, &:hover {
            padding-left: 0;
            font-weight: $medium;

            &:before {
              display: none;
              content: none;
            }
          }
        }
      }
    }

    &__catalog-button {
      display: flex;
      height: var(--input-xl);
      line-height: var(--input-xl);
      border-top: 1px solid $bg-border;
      border-bottom: 1px solid $bg-border;
      font-weight: $bold;
      font-size: 16px;

      justify-content: space-between;
      align-items: center;

      &, &:hover, &:active, &:focus {
        color: $color-primary;
      }

      &_active {
        .cat-filter__catalog-button-plus {
          &:before {
            opacity: 0;
            transform: rotateZ(90deg);
          }
        }
      }
    }

    &__catalog-button-plus {
      display: flex;
      width: 18px;
      height: 18px;
      justify-content: center;
      align-items: center;

      &:before {
        opacity: 1;
        transform: rotateZ(0);

        content: '';
        display: block;
        position: relative;
        top: 0;
        left: 9px;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: $bg-border;

        transition: all ease .3s;
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: $bg-border;
      }
    }
  }
}