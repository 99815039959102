.b-goods {
  @at-root {
    &__block-title {
      max-width: 692px;
      @include textLargest;
      @include respond(md) {
        max-width: 452px;
      }
      @include respond-down(sm) {
        padding: 0 var(--grid-gap);
        margin-bottom: var(--space-xxxxxl);
      }
    }

    &__slider {
      margin-top: var(--space-xxl);
      & .glide__slide {
        height: auto;
      }
      & .b-product-card {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      @include respond(lg) {
        margin-top: var(--space-xxxxxxxxxl);
      }
      @include respond(md) {
        margin-top: var(--space-xxxxxxxl);
      }
      @include respond-down(sm) {
        margin-top: 0;
      }
    }

    &__controls {
      &_small {
        display: none;
        @include respond-down(sm) {
          display: flex;
          margin-top: var(--space-xs);
          justify-content: center;
        }
      }
    }

    &__links {
      display: flex;
      width: 100%;
      margin-top: var(--space-xxxxxxxxxxl);
      align-items: center;
      justify-content: center;
      @include respond(lg) {
        margin-top: var(--space-xxxxxxxl);
      }
      @include respond(md) {
        margin-top: var(--space-xxxxxxxl);
      }
      @include respond-down(sm) {
        margin-top: var(--space-xxl);
        flex-direction: column;
      }
    }

    &__button-add {
      width: 317px;
      margin-right: var(--space-s);
      font-size: 11px;

      @include respond-down(sm) {
        width: auto;
        margin-right: 0;
      }
    }

    &__link-more {
      @include respond-down(sm) {
        margin-top: var(--space-xl);
      }
    }

    &__container {
      padding: var(--space-xl) 0;
    }
  }
}