.checkbox {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
  flex: 0 0 28px;
  border: 1px solid $color-secondary;

  &, & > * {
    cursor: pointer;
  }

  @at-root {
    &__input, input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      outline: none;
      appearance: none;

      &:checked {
        & ~ .checkbox__check-mark {
          opacity: 1;
        }
      }
    }

    &__check-mark {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: 15px 11px;
      background-position: center center;
      background-image: url('../svg/checkmark.svg');
      pointer-events: none;
      transition: opacity ease .15s;
    }
  }
}