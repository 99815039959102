.layout {
  $containerSpacesV: ("xxxxl", "xxxxxl");
  $containerIndentsB: ("xxl", "xxxxl");

  @at-root {
    &__container {
      &_align {
        &_center {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &_size {
        @each $size, $value in $containerSizes {
          &_#{$size} {
            width: 100%;
            max-width: $value;
            --layout-padding: var(--grid-gap);
            padding-left: var(--layout-padding);
            padding-right: var(--layout-padding);
          }
          &_default {
            @include respond(md) {
              --layout-padding: 44px;
            }

            @include respond(sm) {
              --layout-padding: 20px;
            }

            @include respond-down(md) {
              padding-left: var(--layout-padding);
              padding-right: var(--layout-padding);
            }
          }
        }
      }

      &_no-gap {
        &_small {
          @include respond(sm) {
            --layout-padding: 0;
          }
        }

        &_lg-up {
          @include respond-up(lg) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      &_space-v {
        @each $size in $containerSpacesV {
          &_#{$size} {
            padding-top: var(--space-#{$size});
            padding-bottom: var(--space-#{$size});
          }
        }
      }

      &_indent-b {
        @each $size in $containerIndentsB {
          &_#{$size} {
            margin-bottom: var(--space-#{$size});
          }
        }
      }
    }
  }
}
