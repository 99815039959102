.grid {
  display: grid !important;

  @at-root {
    &_align-content {
      &_between {
        align-content: space-between;
      }

      &_center {
        align-items: center;
      }

      &_end {
        align-items: flex-end;
      }

      &_lg-center {
        @include respond(lg) {
          align-items: center;
        }
      }

      &_md-start {
        @include respond(md) {
          align-items: flex-start;
        }
      }

      &_sm-start {
        @include respond(sm) {
          align-items: flex-start;
        }
      }
    }

    &_justify-content {
      &_between {
        justify-content: space-between;
      }
    }

    @each $name, $content in $grid {
      &_#{$name}-columns {
        @include respond(#{$name}) {
          @for $i from 3 through 12 {
            &_#{$i} {
              grid-template-columns: repeat($i, 1fr);
            }
          }
        }
      }
    }

    &_col-gap {
      &_two-thirds {
        grid-column-gap: calc(var(--grid-gap) * 2 / 3);
      }

      &_full {
        grid-column-gap: var(--grid-gap);
      }

      &_half {
        grid-column-gap: calc(var(--grid-gap) * 1 / 2);
      }
    }

    &_row-gap {
      &_two-thirds {
        grid-row-gap: calc(var(--rowGap) * 2 / 3);
      }

      &_half {
        grid-row-gap: calc(var(--rowGap) * 1 / 2);
      }

      &_full {
        grid-row-gap: var(--rowGap);
      }

      &_3x {
        grid-row-gap: calc(3 * var(--rowGap));
      }

      &_4x {
        grid-row-gap: calc(4 * var(--rowGap));
      }
    }

    &__fraction {
      @each $name, $content in $grid {
        @include respond(#{$name}) {
          &_#{$name}-col {
            @for $size from 1 through 12 {
              &_#{$size} {
                grid-column: span #{$size};
              }
            }
          }
          &_#{$name}-row-start {
            @for $i from 1 through 12 {
              &_#{$i} {
                grid-row-start: $i;
              }
            }
          }
          &_#{$name}-col-start {
            @for $i from 1 through 13 {
              &_#{$i} {
                grid-column-start: $i;
              }
            }
          }
          &_#{$name}-col-end {
            @for $i from 1 through 13 {
              &_#{$i} {
                grid-column-end: $i;
              }
            }
          }
          &_#{$name}-order {
            @for $i from 0 through 3 {
              &_#{$i} {
                order: #{$i};
              }
            }
          }
        }
      }
    }
  }
}
