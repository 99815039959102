h1, h2, h3, h4, h5, h6 {
  font-family: $h-font;
  font-weight: $bold;
}

$h1-size: 56px;
$h2-size: 48px;
$h3-size: 38px;
$h4-size: 34px;
$h5-size: 28px;
$h6-size: 24px;

h1 {
  font-size: 56px;
  @include respond(lg) {
    font-size: 42px;
  }
  @include respond(md) {
    font-size: 38px;
  }
  @include respond-down(sm) {
    font-size: 32px;
  }
}

h2 {
  font-size: $h2-size;
  @include respond(lg) {
    font-size: 38px;
  }
  @include respond(md) {
    font-size: 34px;
  }
  @include respond(sm) {
    font-size: 28px;
  }
}

h3 {
  font-size: $h3-size;
  @include respond(lg) {
    font-size: 32px;
  }
  @include respond(md) {
    font-size: 28px;
  }
  @include respond(sm) {
    font-size: 26px;
  }
}

h4 {
  font-size: $h4-size;
  @include respond(lg) {
    font-size: 28px;
  }
  @include respond(md) {
    font-size: 24px;
  }
  @include respond(sm) {
    font-size: 24px;
  }
}

h5 {
  font-size: $h5-size;
  @include respond(lg) {
    font-size: 24px;
  }
  @include respond(md) {
    font-size: 18px;
  }
  @include respond(sm) {
    font-size: 18px;
  }
}

h6 {
  font-size: $h6-size;
}

@mixin textLargest() {
  font-size: 72px;
  @include respond(lg) {
    font-size: 62px;
  }
  @include respond(md) {
    font-size: 48px;
  }
  @include respond-down(sm) {
    font-size: 38px;
  }
}