.b-video {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;

  @at-root {
    &__wrapper {
      display: flex;
      height: 550px;
      width: 100%;
      position: relative;
      @include respond-down(lg) {
        height: 345px;
      }
      @include respond-down(sm) {
        height: 210px;
      }
    }

    &__poster {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__play-button {
      display: block;
      position: absolute;
      width: 118px;
      height: 118px;
      border-radius: 50%;
      background: $bg-white;
      transition: transform ease .4s;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      @include respond-down(lg) {
        width: 73px;
        height: 73px;
      }

      @include respond-down(sm) {
        width: 48px;
        height: 48px;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: calc(50% - 6px);
        top: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 23px 0 23px 23px;
        border-color: $t $t $t $bg-border;
        transform: translateY(-50%);
        transition: border-color ease .2s;
        @include respond-down(sm) {
          left: calc(50% - 3px);
          border-width: 10px 0 10px 10px;
        }
      }

      &:hover, &:focus, &:active {
        &:before {
          border-color: $t $t $t $color-link;
        }
      }
    }

    &__title {
      margin-top: var(--space-s);
      font-size: 20px;
      line-height: 24px;
      @include respond-down(lg) {
        margin-top: var(--space-xs);
      }
      @include respond(sm) {
        margin-top: var(--space-xxs);
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}