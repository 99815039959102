@font-face {
    font-family: 'PTRootUIWeb';
    src: url('PTRootUI_Light.eot');
    src: url('PTRootUI_Light.eot?#iefix') format('embedded-opentype'),
    url('PTRootUI_Light.woff2') format('woff2'),
    url('PTRootUI_Light.woff') format('woff'),
    url('PTRootUI_Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'PTRootUIWeb';
    src: url('PTRootUI_Regular.eot');
    src: url('PTRootUI_Regular.eot?#iefix') format('embedded-opentype'),
    url('PTRootUI_Regular.woff2') format('woff2'),
    url('PTRootUI_Regular.woff') format('woff'),
    url('PTRootUI_Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'PTRootUIWeb';
    src: url('PTRootUI_Medium.eot');
    src: url('PTRootUI_Medium.eot?#iefix') format('embedded-opentype'),
    url('PTRootUI_Medium.woff2') format('woff2'),
    url('PTRootUI_Medium.woff') format('woff'),
    url('PTRootUI_Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PTRootUIWeb';
    src: url('PTRootUI_Bold.eot');
    src: url('PTRootUI_Bold.eot?#iefix') format('embedded-opentype'),
    url('PTRootUI_Bold.woff2') format('woff2'),
    url('PTRootUI_Bold.woff') format('woff'),
    url('PTRootUI_Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
