.events-list-page {
  .events {
    .events__wrap {
      .events__activity {
        .activity__item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 99px;
          margin-top: 99px;
          @include respond(md) {
            flex-direction: column;
          }
          @include respond(sm) {
            flex-direction: column;
          }
          .date__time {
            display: flex;

            .wrap {
              display: flex;
              align-items: center;
              margin-right: 194px;
              @include respond(lg) {
                margin-right: 30px;
                flex-direction: column;
                align-items: center;
              }
              @include respond(md) {
                display: none;
              }
              @include respond(sm) {
                display: none;
              }
              .date {
                margin-right: 47px;
                @include respond(lg) {
                  margin-right: 0;
                }
                &__day {
                  font-size: 18px;
                  font-weight: $medium;
                }
                &__number {
                  font-size: 90px;
                  font-weight: $bold;
                }
                &__month {
                  font-size: 28px;
                  font-weight: $medium;
                  @include respond(lg) {
                    margin-bottom: 27px;
                  }
                  @include respond(md) {
                    margin-bottom: 27px;
                  }
                }
            }
              .time {
                display: flex;
                align-items: center;
                &__ico {
                  margin-right: 13px;
                }
                &__number {
                  font-size: 28px;
                  font-weight: $bold;
                }
              }
            }
            .image {
              margin-right: 69px;
              min-width: 359px;
              @include respond(md) {
                width: 100%;
                margin-right: 0;
              }
              @include respond(sm) {
                width: 100%;
                margin-right: 0;
              }
              img {
                @include respond(md) {
                  display: block;
                  margin: auto;
                }
                @include respond(sm) {
                  display: block;
                  margin: auto;
                }
              }
            }
          }
          .info {
            display: flex;
            @include respond(sm) {
              flex-direction: column;
            }
            .text {
              max-width: 689px;
              .title {
                font-size: 32px;
                font-weight: $bold;
                line-height: 40px;
                max-width: 665px;
                margin-bottom: 10px;
              }
              .organizer {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                p {
                  margin-right: 20px;
                  font-size: 16px;
                  font-weight: $bold;
                }
                img {
                  max-width: 156px;
                }
              }
              .desc {
                font-size: 16px;
                font-weight: $normal;
                line-height: 24px;
                opacity: 0.5;
                margin-bottom: 29px;
              }
              .location {
                display: flex;
                align-items: center;
                margin-bottom: 41px;
                .ico {
                  margin-right: 20px;
                }
                p {
                  font-size: 18px;
                  font-weight: $bold;
                }
              }
              .buttons {
                display: flex;
                @include respond(sm) {
                  flex-wrap: wrap;
                }
                button, a {
                  max-width: 234px;
                  height: 56px;
                  text-align: center;
                }
                button:first-child,
                a:first-child {
                  margin-right: 30px;
                  @include respond(sm) {
                    margin-bottom: 20px;
                  }
                }
              }
            }
            .wrap {
              display: none;
              @include respond(md) {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
              @include respond(sm) {
                display: flex;
                flex-direction: row;
                justify-content: center;
              }
              .date {
                margin-right: 47px;
                @include respond(lg) {
                  margin-right: 0;
                }
                @include respond(sm) {
                  display: flex;
                  align-items: center;
                }
                &__day {
                  font-size: 18px;
                  font-weight: $medium;
                }
                &__number {
                  font-size: 90px;
                  font-weight: $bold;
                }
                &__month {
                  font-size: 28px;
                  font-weight: $medium;
                  @include respond(lg) {
                    margin-bottom: 27px;
                  }
                  @include respond(md) {
                    margin-bottom: 27px;
                  }
                }
              }
              .time {
                display: flex;
                align-items: center;
                &__ico {
                  margin-right: 13px;
                }
                &__number {
                  font-size: 28px;
                  font-weight: $bold;
                }
              }
            }
          }
        }
      }
    }
  }
}