.b-testimonial {
  display: grid;
  align-items: center;
  grid-template: "image text-block";
  grid-template-columns: 33.33% 66.67%;

  @include respond-down(md) {
    align-items: flex-start;
  }

  @include respond(sm) {
    grid-template: "image" "text-block";
  }

  @at-root {
    &__image {
      display: flex;
      grid-area: image;

      @include respond(md) {
        width: 180px;
      }

      @include respond(sm) {
        width: 140px;
      }

      img {
        width: 100%;
        height: 100%;
        max-height: 303px;
        object-fit: cover;

        @include respond(md) {
          max-height: 190px;
        }
        @include respond(sm) {
          max-height: 140px;
        }
      }
    }

    &__text-block {
      grid-area: text-block;

      @include respond-up(lg) {
        padding-left: var(--space-xxxl);
      }

      @include respond(md) {
        padding-left: var(--space-xs);
      }

      @include respond(sm) {
        margin-top: var(--space-s);
      }
    }

    &__info {

    }

    &__name {
      margin-bottom: var(--space-xs);
      font-size: 38px;
      line-height: 28px;
      font-weight: $bold;

      @include respond(md) {
        font-size: 24px;
      }

      @include respond(sm) {
        margin-bottom: var(--space-xxxs);
        font-size: 18px;
      }
    }

    &__company {
      display: flex;
      font-size: 18px;
      line-height: 26px;

      @include respond(sm) {
        flex-direction: column;
        font-size: 14px;
        line-height: 18px;
      }
    }

    &__company-name {
      max-width: 290px;
      width: 100%;

      @include respond(sm) {
        max-width: none;
      }

      & > *:first-child {
        font-weight: $bold;
      }
    }

    &__position {
      font-weight: $bold;

      @include respond-up(md) {
        padding-left: var(--space-xxxs);
      }

      @include respond(sm){
        margin-top: var(--space-xxs);
      }
    }

    &__text {
      margin-top: var(--space-xs);
      font-size: 18px;
      line-height: 23px;

      @include respond(sm){
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}