.b-how-it-was {
  @at-root {
    &__text {
      padding-top: var(--space-xxs);
      margin-top: var(--space-xxxxxxxxxxl);
      @include respond-down(lg) {
        padding-top: 0;
      }
      @include respond-down(md) {
        margin-top: var(--space-xxxxxxl)
      }
      @include respond-down(sm) {
        margin-top: var(--space-xxxxl);
      }
    }

    &__numbers {
      display: flex;
      width: 100%;
      justify-content: space-between;

      @include respond(lg) {

      }
      @include respond(md) {

      }
      @include respond-down(sm) {
        flex-direction: column;
      }
    }

    &__number {
      flex: 0 0 33%;

      @include respond-down(sm) {
        display: flex;
        flex: 0 0 100%;

        &:not(:last-child) {
          margin-bottom: var(--space-xxs);
        }
      }
    }

    &__number-title {
      font-size: 52px;
      @include respond-down(lg) {
        font-size: 36px;
      }
      @include respond-down(sm) {
        flex: 0 0 var(--space-xxxxxxxxl);
      }
    }

    &__number-description {
      font-size: 30px;
      @include respond(lg) {
        margin-top: var(--space-xxxs);
        font-size: 24px;
      }
      @include respond-down(md) {
        margin-top: var(--space-xxxxs);
        font-size: 26px;
      }
    }

    &__description {
      margin-top: var(--space-xxxxxxxxxxl);

      @include respond-down(lg) {
        margin-top: var(--space-xxl);
      }
      @include respond-down(md) {
        margin-top: var(--space-m)
      }
      @include respond-down(sm) {
        margin-top: var(--space-s)
      }

      &, p {
        font-size: 18px;
        line-height: 24px;

        @include respond(lg) {
          font-size: 16px;
          line-height: 24px;
        }
        @include respond-down(md) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &__video {
      margin-top: calc(var(--space-xxxxxxxxxxl) + var(--space-xs));
      @include respond-down(lg) {
        margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxxl));
      }
      @include respond-down(md) {
        margin-top: var(--space-xxl);
      }
      @include respond-down(sm) {
        margin-top: var(--space-l);
      }
    }
  }
}