.b-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 400px;
  height: var(--hiddenHeight, 500px);
  position: relative;
  cursor: pointer;

  @include respond(md) {
    max-height: 200px;
  }

  @include respond-down(sm) {
    height: initial;
  }

  @at-root {
    &_type {
      &_horiozntal {

      }
    }

    &_visible, &:hover {
      @include respond-up(md) {
        .b-card__hover {
          opacity: 1;
          pointer-events: all;
          transform-origin: center center;
          transform: translate(-20%, -50%) scale(1);
        }
      }
    }

    &__visible {
      min-width: 100%;
      min-height: 200px;
      padding: var(--space-xs) var(--space-xxs);
      border: 1px solid $bg-border;
      @include respond-down(sm) {
        display: none;
      }

      .b-card__title {
        margin-top: var(--space-m);
        font-size: 16px;
        line-height: 18px;
        @include respond(lg) {
          margin-top: var(--space-l);
          max-width: 200px;
        }
        @include respond(sm) {
          padding: var(--space-xs) var(--space-xs);
          font-size: 22px;
          line-height: 24px;
        }
      }
    }

    &__hover {
      opacity: 0;
      display: flex;
      min-height: 290px;
      width: 432px;
      position: absolute;
      top: 50%;
      left: 0;
      padding: var(--space-m) var(--space-xxs);
      transform-origin: center center;
      transform: translate(-20%, -50%) scale(0);
      background: $bg-default;
      border: 1px solid $bg-border;
      pointer-events: none;
      transition: all ease-out .2s .1s;
      z-index: 10;

      @include respond-down(sm) {
        position: static;
        opacity: 1;
        width: 100%;
        padding: var(--space-xxs) var(--space-xs) var(--space-s);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        flex-direction: column;
        transform-origin: center center;
        transform: scale(1);
      }

      &.navigation{
        flex-direction: column;
      }
    }

    &__wrapper{
      display: flex;
    }

    &__navigation{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 20px 0px;
    }

    &__image-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
    }

    &__image {
      display: block;
      width: 125px;
      height: 65px;
      object-fit: contain;
    }

    &__stand {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @include respond-down(sm) {
        position: relative;
        flex: 0 0 85px;
        right: -10px;
      }
    }

    &__stand-text {
      line-height: 28px;
    }

    &__stand-text-title {
      font-size: 18px;
      @include respond-down(sm) {
        font-size: 16px;
      }
    }

    &__stand-text-num {
      font-size: 26px;
      @include respond-down(sm) {
        font-size: 18px;
      }
    }

    &__stand-icon {
      display: flex;
      margin-top: var(--space-xxxs);
      width: 35px;
      height: 60px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @include respond-down(sm) {
        margin-top: var(--space-xxxxxs);
        width: 35px;
        height: 31px;
      }

      &:before, &:after {
        content: '';
        display: block;
        background: $bg-border;
      }

      &:after {
        width: 100%;
        height: 4px;
      }

      &:before {
        width: 4px;
        height: 100%;
      }
    }

    &__title-block {
      @include respond-down(sm) {
        margin-top: var(--space-xxxs);
      }
    }

    &__title {
      font-size: 22px;
      line-height: 24px;
    }

    &__description {
      max-height: 160px;
      overflow: scroll;
      margin-top: var(--space-xxs);
      font-size: 14px;
      line-height: 18px;

      @include respond-down(md) {
        max-height: none;
      }
    }

    &__button {
      min-width: 160px;
      margin-top: var(--space-m);

      @include respond-down(sm) {
        width: 100%;
        margin-top: var(--space-xs);
      }
    }

    &__button-navigation {
      min-width: 160px;
      margin-top: 10px;

      &-transparent{
        background: none;
        border: 1px solid $color-black;

        &:hover{
          background: none;
          border: 1px solid $color-black;
        }
      }

      @include respond-down(sm) {
        width: 100%;
      }
    }

    &__left {
      display: flex;
      flex: 0 0 120px;
      flex-direction: column;
      margin-right: var(--space-xl);

      @include respond-down(sm) {
        flex-direction: row;
        margin-right: 0;
        flex: 0 0 auto;
      }
    }
  }
}