.catalog-promo {
  @at-root {
    &__title-block {

    }

    &__title {
      font-size: 56px;
      line-height: 1;
      @include respond(lg) {
        font-size: 42px;
        line-height: 48px;
      }
      @include respond(md) {
        font-size: 36px;
        line-height: 42px;
      }
      @include respond(sm) {
        font-size: 28px;
      }
    }

    &__subtitle {
      margin-top: var(--space-s);
      font-size: 24px;
      line-height: 35px;

      @include respond-down(lg) {
        font-size: 18px;
        line-height: 28px;
      }

      @include respond-down(md) {
        display: none;
      }

      @include respond(sm) {
        font-size: 16px;
        line-height: 1;
      }
    }

    &__button {
      margin-top: var(--space-xxxxxxxxl);

      @include respond(lg) {
        margin-top: var(--space-xxxxxxl);
      }
    }
  }
}