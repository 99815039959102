.label {
  @at-root {
    /*
       Для того, чтобы лейбл стал иконкой,
       label и input должны лежать в одном блоке input-group
     */
    &_as-icon {
      font-size: 0;
      margin: 0;
      padding: 0;

      &::before {
        pointer-events: none;
      }
    }

    &_icon {
      &_search {
        &::before {
          content: '';
          display: inline-block;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url('../svg/search.svg');
        }
      }
    }

    &_icon-position {
      &_right {
        &::before {
          position: absolute;
          right: 10px;
        }
      }
    }
  }
}