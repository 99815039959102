.footer {
  padding: var(--space-xxxxxxl) 0;
  @include respond(lg) {
    padding: var(--space-xxl) 0 var(--space-xxxxl);
  }
  @include respond(md) {
    padding: var(--space-xxxxl) 0;
  }
  @include respond-down(sm) {
    padding: var(--space-xxl) 0;
  }
  @at-root {
    &__logo-wrapper {
      @include respond-down(lg) {
        margin-bottom: var(--space-xxxl);
      }

      @include respond(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      @include respond-down(sm) {
        display: flex;
        margin-bottom: var(--space-xl);
        flex-direction: column;
        align-items: center;
      }
    }

    &__logo {
      @include respond-down(sm) {
        &, .svg-icon {
          width: 126px;
        }
      }
    }

    &__copyright {
      margin-top: var(--space-xxs);
      white-space: nowrap;
      font-size: 16px;
      @include respond(lg) {
        font-size: 13px;
      }
      @include respond(md) {
        margin-top: 0;
        padding-bottom: var(--space-xxxs);
      }
      @include respond-down(sm) {
        margin-top: var(--space-xxxs);
      }
    }

    &__menu {
      display: flex;
      width: 100%;
      justify-content: space-between;

      &.js-accordion_visible {
        @include respond-down(sm) {
          margin-bottom: var(--space-xxxs);
        }
      }

      @include respond-down(sm) {
        border-top: 1px solid $color-greyd2;
        &_last {
          border-bottom: 1px solid $color-greyd2;
        }
      }
    }


    &__list {
      width: 100%;
      @include respond-down(sm) {
        padding-left: var(--space-xxxs);
      }

      &.js-accordion__hidden {
        @include respond-down(sm) {
          padding-top: var(--space-l);
        }
      }
    }

    &__list-item {
      font-size: 16px;

      &:not(:last-child) {
        @include respond-up(md) {
          margin-bottom: var(--space-l);
        }
      }

      @include respond-down(sm) {
        font-size: 18px;
        padding-right: var(--space-xs);
        &:not(.js-accordion__visible) {
          margin-bottom: var(--space-m);
        }
      }


      &.js-accordion__visible {
        @include respond-down(sm) {
          padding: var(--space-xxs) 0 var(--space-xs) var(--space-xxxs);
        }
      }
    }

    &__search-col {
      @include respond(lg) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      @include respond-down(md) {
        display: flex !important;
        align-items: center;
        margin-top: var(--space-s);
        justify-content: space-between;
        width: 100%;
      }
      @include respond-down(sm) {
        margin-top: var(--space-m);
        flex-direction: column;
        justify-content: flex-start;
      }
    }

    &__search {
      @include respond(lg) {
        margin-top: var(--space-l);
        width: 100%;
      }
      @include respond(md) {
        width: 46%;
      }
      @include respond-down(sm) {
        width: 100%;
        margin-bottom: var(--space-xl);
      }
    }

    &__search-form {
    }

    &__moow {
      display: inline-block;
      white-space: nowrap;
      @include respond(lg) {
        padding-bottom: var(--space-xs);
      }
    }
  }
}