.list {
  @at-root {
    &_checkmarks {
      .list__item {
        display: flex;
        align-items: flex-start;
        flex-direction: row;

        &:before {
          content: '';
          display: inline-block;
          flex: 0 0 28px;
          width: 28px;
          height: 15px;
          margin-top: 2px;
          margin-right: var(--space-l);
          background-image: url('../svg/checkmark.svg');
          background-repeat: no-repeat;
          background-size: contain;
          @include respond-down(md) {
            margin-right: var(--space-m);
          }
          @include respond(sm) {
            margin-right: var(--space-m);
            width: 18px;
            height: 15px;
            flex: 0 0 18px;
          }
        }
      }
    }

    &_border {
      &_all {
        border: solid var(--bg-border);
      }

      &_view {
        &_default {
          background: var(--bg-default);
        }
      }
    }

    &__title {
      margin-bottom: var(--space-xxxl);
      font-size: 30px;
      line-height: 35px;
      font-weight: $bold;

      @include respond(lg) {
        margin-bottom: var(--space-l);
      }
      @include respond-down(md) {
        margin-bottom: var(--space-l);
      }
      @include respond-down(sm) {
        margin-bottom: var(--space-xxxs);
        font-size: 24px;
        line-height: 26px;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;

      &_vertical {
        flex-direction: row;
      }

      &_indent-b {
        &_xs {
          &:not(:last-child) {
            margin-bottom: var(--space-xs);
          }
        }
      }
    }

    &__image-wrapper {
      display: flex;
      width: 100%;
    }

    &__image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
