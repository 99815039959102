body.news-list-page {
  .news-list__item {
    margin-bottom: var(--space-xxxxxxxxxxl);

    @include respond(lg) {
      margin-bottom: var(--space-xxxxxxxxxxxl);
    }

    @include respond(md) {
      min-height: 523px;
      margin-bottom: var(--space-xxxxxxxxl);
    }

    @include respond(sm) {
      margin-bottom: var(--space-xxxxxxl);
    }
  }

  .page-title {
    margin-top: var(--space-xxxxxxl);
    margin-bottom: var(--space-xxxxxxxl);
    @include respond(sm) {
      margin-bottom: var(--space-xxs);
    }
  }

  .items_filter {
    margin-bottom: var(--space-xxxxxxxxl);
    display: inline-flex;
    width: 100%;
    border-bottom: solid 1px $color-secondary;
    padding-bottom: var(--space-xxxs);
    @include respond(sm) {
      display: block;
    }
    @at-root {
      &-to_date {
        margin-right: var(--space-xs);
        font-size: 14px;
        @include respond(sm) {
          margin-bottom: var(--space-xxxs);
        }
      }

      &-start_new {
        margin-right: var(--space-xs);
        font-size: 14px;
      }

      &-start_old {
        font-size: 14px;
      }
    }

    .radio-list {
      display: flex;

      li {

        label {
          font-size: 14px;
          color: var(--color-secondary);
        }

        input:checked + label {
          font-weight: bold;
          color: var(--color-primary);
        }

        &:first-child {
          margin-right: var(--space-xs);
        }
      }
    }
  }
}
