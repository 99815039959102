.avatar {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;

  @at-root {
    &_circle, &_rounded {
      border-radius: 50%;
      overflow: hidden;
    }

    &_size {
      &_small {
        flex-basis: 200px;
        width: 200px;
        height: 200px;
      }

      &_medium {
        flex-basis: 124px;
        width: 124px;
        height: 124px;
      }

      &_lg {
        flex-basis: 200px;
        width: 200px;
        height: 200px;

        @include respond-down(md) {
          flex-basis: 180px;
          width: 180px;
          height: 180px;
        }
        @include respond-down(sm) {
          flex-basis: 140px;
          width: 140px;
          height: 140px;
        }
      }

      &_super_lg {
        flex-basis: 300px;
        width: 300px;
        height: 300px;

        @include respond-down(md) {
          flex-basis: 190px;
          width: 190px;
          height: 190px;
        }
        @include respond-down(sm) {
          flex-basis: 140px;
          width: 140px;
          height: 140px;
        }
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
