.js-hidden-content {
  &_active {
    &:after {
      content: none !important;
    }

    .js-hidden-content__hidden {
      display: initial !important;
    }
  }


  @at-root {
    &_respond {
      &_sm {
        @include respond(sm) {
          &:after {
            content: '...';
            display: inline-block;
            letter-spacing: 1px;
            font-size: 24px;
            color: $color-secondary;
            line-height: 0;
            font-weight: $bold;
            transform: translateY(5px);
          }

          .js-hidden-content__hidden {
            @include respond(sm) {
              display: none;
            }
          }
        }
      }
    }

    &__hidden {

    }

    &__visible {

    }
  }

}