body.company-view-page {
  .company_info{
    background: #F4F4F4;
    margin: 50px 0px;
    padding: 20px;
    font-size: 16px;

    p{
      margin-bottom: 10px;
    }
  }

  .company_logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img{
      margin: 50px;
    }
  }

  .company_description{
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    padding: 30px 0px;
    margin-bottom: 100px;
    margin-top: 100px;
    font-size: 16px;
    line-height: 24px;

    p{
      margin-bottom: 20px;
    }
  }

  .company_product_wrapper{
    background-color: #EBF1F3;
    padding: 100px 0px;

    .arrow{
      background-color: #EBF1F3;

    }
  }

  .company_additional{
    margin: 50px 0px;

    h2{
      margin: 50px 0px;
      font-size: 48px;
    }

    table{
      border-collapse: collapse;
      td{


        &:first-child{
          border-right: 1px solid #595959;
          padding: 20px 60px 20px 0px;
        }

        &:last-child{
          padding: 20px 20px 20px 60px;
        }

      }
    }
  }

  .button{
    margin-right: 30px;
  }

  .container_margin{
    margin: 100px 0px;
  }
}

