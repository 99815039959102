.speakers {
    overflow: hidden;
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -50px;
        .b-person {
            padding: 0 50px;
            flex-basis: 20%;
            @include respond(lg) {
                flex-basis: 25%;
            }
            @include respond(md) {
                flex-basis: 50%;
                margin-bottom: 20px;
            }
            @include respond(sm) {
                flex-basis: 100%;
                margin-bottom: 30px;
            }
            .b-person__title-block {
                height: auto;
            }
            .b-person__description {
                overflow-y: auto;
                padding-right: 10px;
            }
        }
    }
}