@mixin mobileScrollbar() {
  &::-webkit-scrollbar {
    display: block;
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    border-radius: 15px;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    background: $color-additional;
    border-radius: 15px;
    max-height: 50px;
  }

  &::-webkit-scrollbar-button {
    display: none;
    height: 5px;
    background: rgb(255, 255, 255);
  }
}