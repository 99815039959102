.icon-plus {
  display: flex;
  flex-wrap: nowrap;

  @at-root {
    &_vertical-align {
      &_top, &_start {
        align-items: flex-start;
      }

      &_center {
        align-items: center;
      }
    }

    &__icon {
      &_indent-r {
        $spaces: "xs", "s", "m";
        @each $name in $spaces {
          &_#{$name} {
            margin-right: var(--space-#{$name});
          }
        }
      }

      &_indent-l {
        $spaces: "l";
        @each $name in $spaces {
          &_#{$name} {
            margin-left: var(--space-#{$name});
          }
        }
      }

      &_width {
        &_col {
          flex: 0 0 80px;
          width: 80px;

          @include respond(lg) {
            flex: 0 0 53px;
            width: 53px;
          }
          @include respond(md) {
            width: 70px;
            flex: 0 0 70px;
          }
          @include respond-down(sm) {
            flex: 0 0 48px;
            width: 48px;
          }
        }
      }

      &_align {
        &_center {
          display: flex;
          align-items: center;
        }
      }
    }

    &__block {
    }
  }
}
