.b-slider {
  position: relative;
  margin-bottom: var(--space-l);

  @at-root {
    &__glide {
      &.glide--moving {
        .b-slider__text {
          opacity: 1;
        }
      }
    }

    &__text {
      opacity: 0;
      padding: var(--space-xxxxxxxxl) 0;
      transition: opacity ease-out 1s;
      @include respond(lg) {
        padding: var(--space-xxxxxxxxxl) 0
      }
      @include respond-down(md) {
        padding: var(--space-xs) 0 var(--space-xxxl);
      }
      @include respond-down(sm) {
        padding: var(--space-l) 0 var(--space-xl);
      }
    }

    &__slider {
      position: relative;

      &.glide__track {
        overflow-y: visible;
        overflow-x: hidden;
      }
    }

    &__container {
      &.glide__slides {
        @include respond-up(lg) {
          height: auto !important;
        }
      }
    }

    &__slide {
      height: 100%;

      &.glide__slide {
        min-width: 0;

        &--active {
          .b-slider__text {
            opacity: 1;
          }
        }
      }

      @include respond-up(lg) {
        min-height: 500px;
      }
    }

    &__subtitle {
      font-size: 18px;
      margin-bottom: var(--space-xxxxs);
      @include respond(lg) {
        margin-bottom: var(--space-xxxs);
      }
      @include respond(md) {
        margin-bottom: var(--space-xxxxs);
      }
      @include respond-down(sm) {
        margin-bottom: var(--space-xxs);
      }
    }

    &__title {
      font-size: 40px;
      @include respond(lg) {
        font-size: 42px;
        line-height: 48px;
      }
      @include respond-down(md) {
        font-size: 36px;
        line-height: 42px;
      }
      @include respond-down(sm) {
        font-size: 28px;
        line-height: 29px;
        padding-right: 5px;
      }
    }


    &__images-col {
      &.grid__fraction {
        position: relative;
        @include respond-up(lg) {
          grid-column-end: 13;
        }
      }
    }

    &__images {
      @include respond-up(lg) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
      }
    }

    &__image-wrapper {
      display: flex;
      position: relative;
    }

    &__image {
      &, &[src=""] {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 456px;
        object-fit: cover;
        @include respond-down(lg) {
          height: 420px;
        }
        @include respond-down(sm) {
          height: 190px;
        }
      }
    }

    &__bullets {
      position: absolute;
      top: initial;
      left: 50%;
      bottom: calc(-1 * (16px + var(--space-xxs)));
      right: initial;
      @include respond-down(lg) {
        bottom: calc(-1 * (16px + var(--space-m)));
      }
      @include respond-down(sm) {
        bottom: calc(-1 * (16px + var(--space-xxs)));
      }

      &.glide__bullets {
        &, * {
          pointer-events: all;
        }
      }
    }

    &__bullet {
      background-color: $t;
      margin: 0 var(--space-xxxxs) 0 0;

      &:last-child {
        margin: 0;
      }
    }

    [data-first-slider] {
      cursor: default;

      a {
        cursor: pointer;
      }
    }

    [data-second-slider] {
      pointer-events: all;
    }
  }
}