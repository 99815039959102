.b-card-slider {

  &.glide {
    overflow: visible;

    .glide__track {
      overflow: visible;

      .glide__slides {
        overflow: visible;

        .glide__slide {
          transition: opacity ease-out .3s;
        }

        /* TODO LG MD SM
         При наведении на карточку появляется всплывашка
           У крайних элементов показываем подальше от края
        */
        .glide__slide--active {
          @include respond(xl) {
            .b-card {
              .b-card__hover {
                left: 17px;
              }
            }
            & ~ .glide__slide ~ .glide__slide ~ .glide__slide ~ .glide__slide ~ .glide__slide {
              .b-card {
                .b-card__hover {
                  left: -50px;
                }
              }

              & ~ .glide__slide {
                opacity: 0;
                pointer-events: none;
              }
            }
          }
          @include respond(lg) {
            .b-card {
              .b-card__hover {
                left: 86px;
              }
            }
            & ~ .glide__slide ~ .glide__slide ~ .glide__slide {
              .b-card {
                .b-card__hover {
                  left: -50px;
                }
              }

              & ~ .glide__slide {
                opacity: 0;
                pointer-events: none;
              }
            }
          }
          @include respond(md) {
            .b-card {
              .b-card__hover {
                left: 86px;
              }
            }
            & ~ .glide__slide {
              .b-card {
                .b-card__hover {

                }
              }

              & ~ .glide__slide {
                opacity: 0;
                pointer-events: none;
              }
            }
          }
          @include respond(sm) {

          }
        }
      }
    }
  }

  @at-root {
    &__button {
      width: 236px;
      margin-top: var(--space-xxxxxxxxl);
      transform: translateX(-50%);
      left: 50%;
      @include respond(lg) {
        margin-top: var(--space-xxxxxxl);
      }
      @include respond-down(sm) {
        width: 100%;
        margin-top: var(--space-xxxxl);
      }
    }
  }
}

.grid__fraction--width {
  max-width: 100%;
}