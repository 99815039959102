body.articles-list-page {
  .articles-list__filter {
    display: block;
    margin-top: var(--space-l);
    border-bottom: solid 1px var(--color-secondary);
    padding-bottom: var(--space-xxs);
    @include respond(sm) {
      display: grid;
    }
    @at-root {
      &__all-authors {
        display: inline-flex;
        margin-right: var(--space-xxxxxxxxxxl);
        @include respond(sm) {
          margin-bottom: var(--space-xs);
        }
      }

      &__all-company {
        display: inline-flex;
        margin-right: var(--space-xxxxxl);
        @include respond(sm) {
          margin-bottom: var(--space-xs);
        }
      }

      &__all-subjects {
        display: inline-flex;

      }

      &__check-box {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ERectangle%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='32' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Статьи' transform='translate(-85.000000, -258.000000)' fill-rule='nonzero' stroke='%23000000'%3E%3Cg id='Rectangle' transform='translate(85.000000, 258.000000)'%3E%3Crect stroke-width='1' x='0.5' y='0.5' width='27' height='27'%3E%3C/rect%3E%3Cpolyline id='Path-4' stroke-width='2' stroke-linecap='square' points='9 13.2730086 12.3933281 17 20 10'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        margin-right: var(--space-xxs);
      }

      &__text {
        line-height: 28px;
      }

      &__down-arrow {
        background-image: url('/static/images/down_arrow.png');
        width: 10px;
        height: 5px;
        margin-left: var(--space-xxs);
        margin-top: 13px;
      }
    }
  }

  .articles-list__article {
    display: flex;
    padding-top: 100px;
    padding-bottom: 100px;
    border-bottom: solid 1px var(--color-secondary);
    @include respond-down(md) {
      display: block;
      padding-top: 70px;
      padding-bottom: 50px;
    }

    @at-root {
      &__date-and-text {
        display: flex;
        max-width: 950px;
        flex-grow: 1;
        @include respond(sm) {
          display: block;
        }
      }

      &__date {
        display: flex;
        margin-right: var(--space-m);
        @include respond(sm) {
          margin-right: 0;
          margin-bottom: var(--space-xs);
        }
        @at-root {
          &__number {
            font-size: 60px;
            margin-right: var(--space-xxxxs);
            @include respond(sm) {
              margin-right: var(--space-xs);
            }
          }

          &__month-and-year {
            font-size: 15px;
            margin-top: var(--space-xxxs);
          }

          &__year {
            font-size: 12px;
          }
        }
      }

      &__text {
        padding-right: 100px;
        @include respond-down(lg) {
          padding-right: 0px;
        }

        @at-root {
          &__title {
            display: block;
            margin-bottom: var(--space-xs);
            font-size: 24px;
            line-height: 32px;

            @include respond-down(md) {
              font-size: 18px;
            }
            @include respond-down(sm) {
              line-height: 24px;
            }
          }

          &__text {
            font-size: 16px;
            line-height: 24px;
            @include respond-down(md) {
              font-size: 14px;
            }
            @include respond-down(sm) {
              line-height: 20px;
            }
          }
        }
      }

      &__person {
        display: flex;
        padding-left: var(--space-xxxxxxxl);
        @include respond(lg) {
          padding-left: var(--space-xxl);
        }
        @include respond-down(md) {
          padding-left: 0;
          margin-top: var(--space-m);
        }
        @include respond(sm) {
          display: block;
        }
        @at-root {
          &__photo {
            width: 124px;
            margin-right: var(--space-s);
            @include respond(sm) {
              display: flex;
              margin-right: 0;
            }
          }

          &__card {
            display: flex;
            @include respond(lg) {
              display: block;
            }
            @include respond(md) {
              display: flex;
            }
            @include respond(sm) {
              display: block;
            }
          }

          &__img {
            margin-bottom: var(--space-xxs);
            border-radius: 50%;
          }

          &__button {
            width: 124px;
            min-width: 124px;
            height: 24px;
            font-family: $h-font;
            padding: 0;
            text-align: center;
            border-radius: var(--space-xxxxs);
            @include respond(sm) {
              margin-top: var(--space-xxl);
              margin-left: var(--space-xs);
            }
          }

          &__info {
            margin-right: var(--space-xxs);
            @include respond(sm) {
              margin-right: 0;
            }
            @at-root {
              &__name {
                font-size: 24px;
                line-height: 28px;
                width: 150px;
                margin-bottom: var(--space-xxxs);
                @include respond(sm) {
                  width: unset;
                }
              }

              &__position {
                font-size: 14px;
                max-width: 235px;
                line-height: 18px;

                @include respond(sm) {
                  width: initial;
                }
              }
            }
          }

          &__company {
            @at-root {
              &__title {
                margin-bottom: var(--space-xxxs);
                margin-top: 7px;
              }

              &__name {

              }
            }
          }
        }
      }
    }
  }
}