.b-schedule {
  @at-root {
    /**
    Кнопки
     */
    &__days {
      .grid__fraction.grid__fraction_xl-col_2 {
        grid-column: span 2;
      }
      @include respond-down(md) {
        margin-top: var(--space-m);
        max-width: 100%;
        overflow: scroll;
      }
      @include respond-down(sm) {
        margin-top: var(--space-m);
      }
    }

    &__button-day {
      background: $t;
      border: 1px solid $bg-border;

      &, .text {
        color: $bg-border;
        min-width: 100px;
      }

      &_active, &:active, &:hover, &:focus {
        background: $bg-border;

        &, .text {
          color: $bg-white;
        }
      }
    }

    /**
      Инфа слева
     */
    &__info {
      position: sticky;
      top: var(--space-xs);

      @include respond-down(md) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }
      @include respond-down(sm) {
        justify-content: space-between;
        align-items: center;
        & > div {
          width: calc(50% - 15px);
        }
      }
    }

    &__date-wrapper {
      display: flex;
      height: 233px;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 2px solid $bg-border;

      @include respond(lg) {
        height: 155px;
      }
      @include respond(md) {
        width: 123px;
        height: 123px;
      }
      @include respond-down(sm) {
        height: 124px;
      }
    }

    &__date {

    }


    &__date-num {
      font-size: 90px;
      line-height: .9;

      @include respond(lg) {
        font-size: 78px;
      }
      @include respond(md) {
        font-size: 60px;
      }
      @include respond(sm) {
        font-size: 48px;
      }
    }

    &__date-month, &__date-day {
      width: 100%;
      font-size: 24px;
      @include respond(lg) {
        font-size: 20px;
      }
      @include respond(md) {
        font-size: 18px;
      }
      @include respond-down(md) {
        font-size: 16px;
      }
    }

    &__text {
      margin-top: var(--space-xxxxxxxl);
      @include respond(lg) {
        margin-top: var(--space-xl);
      }
      @include respond(md) {
        margin-top: 0;
        margin-left: var(--space-m);
      }
      @include respond-down(sm) {
        margin-top: 0;
      }
    }

    &__location {
      @include respond-down(sm) {
        padding-top: var(--space-xxxxs);
      }

      .text {
        font-size: 28px;
        line-height: 36px;
        @include respond-down(lg) {
          font-size: 20px;
          line-height: 25px;
        }

        @include respond-down(sm) {
          font-size: 14px;
        }

        &:not(:last-child) {
          margin-bottom: var(--space-s);
          @include respond-down(lg) {
            margin-bottom: var(--space-xs);
          }
        }
      }
    }

    &__download-file {
      display: flex;
      font-size: 24px;
      line-height: 1.25;

      @include respond-down(lg) {
        font-size: 18px;
        line-height: 23px;
        .svg-icon {
          width: 18px;
        }
      }
      @include respond-down(sm) {
        font-size: 14px;
        line-height: 18px;

        .icon-plus__icon {
          margin-right: var(--space-xxxs);
        }
      }
    }

    /**
      Список
     */
    &__list {
      padding: var(--space-xxxxxl) 0;
      @include respond(lg) {
        padding: var(--space-xxxxl) 0;
      }
      @include respond-down(sm) {
        padding: var(--space-xxxxxl) 0 var(--space-xxxl);
      }
    }

    &__item {
      border-top: 2px solid $bg-border;
      cursor: pointer;

      &:last-child {
        border-bottom: 2px solid $bg-border;
      }

      &.js-accordion_visible {
        .b-schedule__item-hidden {
          margin: 0 0 var(--space-s);
          @include respond(lg) {
            margin: 0 0 var(--space-xs);
          }
        }
      }
    }

    &__item-visible {
      padding: var(--space-s) 0;
      @include respond(lg) {
        padding: var(--space-m) 0;
      }
      @include respond(md) {
        padding: var(--space-xxs) 0;
      }
      @include respond-down(sm) {
        padding: var(--space-m) 0 var(--space-s);

        .b-schedule__item-description {
          display: none;
        }
      }
    }

    &__item-hidden {

    }

    &__item-date {
      font-size: 20px;

      @include respond-down(sm) {
        font-size: 18px;

        .icon-plus__icon {
          margin-right: var(--space-xxs);
        }
      }

      .icon-plus__hall {
        margin-top: 10px;
        display: block;
        font-size: 12px;
      }
    }

    &__item-day {
      background-color: $bg-border;
      color: $bg-default;
      padding-left: 35px;
      cursor: default;
    }

    &__item-title-block {
      @include respond(md) {
        margin-left: calc(-1 * var(--space-xxxxl));
      }
      @include respond-down(sm) {
        padding-top: var(--space-xs);
      }
    }

    &__item-title {
      font-size: 20px;
      line-height: 25px;
      @include respond-down(md) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__item-description {
      margin-top: var(--space-xs);
      max-width: 50%;
      font-size: 14px;
      line-height: 20px;
      @include respond(lg) {
        max-width: 73%;
      }
      @include respond-down(md) {
        max-width: 100%;
        margin-top: var(--space-xxxxs);
      }
    }

    &__files {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      & > * {
        padding-right: var(--space-xxxxs);

        @include respond-up(lg) {
          flex: 0 0 33%;
          &:nth-last-child(n+4) {
            margin-bottom: var(--space-s);
          }
        }

        @include respond(md) {
          flex: 0 0 50%;
          &:nth-last-child(n+3) {
            margin-bottom: var(--space-xxxs);
          }
        }
        @include respond(sm) {
          flex: 0 0 100%;
          &:not(:last-child) {
            margin-bottom: var(--space-xxxs);
          }
        }
      }
    }

  }
}