body.publishing-house-page {
  .title-w-controls {
    @include respond(xl) {
      margin-bottom: var(--space-xxxl);
    }
    @include respond(lg) {
      margin-bottom: var(--space-l);
    }

    &__title {
      @include respond-up(lg) {
        font-size: 38px;
      }
      @include respond(md) {
        font-size: 32px;
      }
      @include respond(sm) {
        font-size: 28px;
      }
    }
  }

  .publication {
    @include respond(xl) {
      margin-top: var(--space-xxxxxxxxxxl);
      margin-bottom: calc(var(--space-xxxxxxxxxxxxl) + var(--space-l));
    }
    @include respond(lg) {
      margin-top: var(--space-xxxxxxxxl);
      margin-bottom: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxxxxxxl) + var(--space-xxxxs));
    }
    @include respond(md) {
      margin-top: var(--space-xxxxxxxxxl);
      margin-bottom: var(--space-xxxxxxxxxxxl);
    }
    @include respond(sm) {
      margin-top: var(--space-xxxxxxxl);
      margin-bottom: var(--space-xxxxxxxxxxxl);
    }

    @at-root {
      &__section {

        &_name {
          &_list {

          }

          &_poster, &_description {
            @include respond(xl) {
              margin-top: calc(var(--space-xxxxxxl) + var(--space-xxxxs));
            }
            @include respond(lg) {
              margin-top: calc(var(--space-xxxxxxl) + var(--space-xxs));
            }
          }

          &_journals {
            @include respond(lg) {
              margin-top: var(--space-xxxxxxxxxxl);
            }
            @include respond(md) {
              margin-top: var(--space-xxxxxxxxxl);
            }
            @include respond(sm) {
              margin-top: var(--space-xxxxxl);
            }
          }

          &_poster {
            @include respond(md) {
              margin-top: var(--space-xl);
            }
            @include respond(sm) {
              margin-top: var(--space-xxs);
            }
          }

          &_description {
            @include respond(md) {
              margin-top: var(--space-xxxxxl);
            }
            @include respond(sm) {
              margin-top: var(--space-l);
            }
          }
        }
      }

      &__list {
        margin-top: var(--space-xl);
      }


      &__list-item {
        &:not(:last-child) {
          border-bottom: 1px solid $color-secondary;
        }

        a {
          display: flex;
          padding: var(--space-xs) 0;
          justify-content: space-between;
          align-items: center;
          color: $color-secondary;

          &:not(:last-child) {
            border-bottom: 1px solid $color-secondary;
          }

          &:after {
            display: inline-block;
            width: 8px;
            height: 13px;
            background-image: url('../svg/arrow-4x9_bold.svg');
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        &:hover, &.publication__list-item_active {
          a {
            font-weight: $bold;
            color: $color-black;

            &:after {
              content: '';
              @include respond-down(md) {
                content: none;
              }
            }
          }
        }
      }

      &__list-buttons {
        margin-top: var(--space-l);
        @include respond(md) {
          margin-top: var(--space-s);
        }
        @include respond-up(xl) {
          width: 60%;
        }
        @include respond(lg) {
          width: 72%;
        }
        @include respond(md) {
          display: flex;
        }

        .button {
          &:not(:last-child) {
            @include respond-up(lg) {
              margin-bottom: var(--space-m);
            }
            @include respond(sm) {
              margin-bottom: var(--space-xxs);
            }
          }
        }
      }

      &__image-wrapper {
        display: flex;
      }

      &__image {
        display: block;
        max-height: 744px;
        object-fit: contain;
        @include respond-down(md) {
          max-height: 505px;
        }
      }

      &__buttons {
        display: flex;

        @include respond(xl) {
          width: 60%;
          margin-bottom: var(--space-xxxl);
        }
        @include respond(lg) {

        }
        @include respond-down(sm) {
          flex-direction: column;
        }

        .button {
          @include respond-up(xl) {
            min-width: 233px;
          }
          @include respond(lg) {
            min-width: 210px;
          }
          @include respond(md) {
            width: 220px;
          }

          &:not(:last-child) {
            @include respond(xl) {
              margin-right: var(--grid-gap);
            }
            @include respond(lg) {
              margin-right: var(--space-xs);
            }
            @include respond(md) {
              margin-right: var(--space-s);
            }
            @include respond(sm) {
              margin-bottom: var(--space-xxs);
            }
          }
        }
      }

      &__text-block {
        margin-top: var(--space-xs);
        line-height: 24px;

        b {
          display: inline-block;
          font-size: 120%;
          font-weight: bold;
          margin-bottom: var(--space-xxxs);
        }

        @include respond(lg) {
          margin-top: var(--space-xxxxl);
        }
      }

      &__text-title {
        margin-top: var(--space-xxl);
        margin-bottom: var(--space-xs);
        font-size: 24px;

        @include respond(lg) {
          margin-top: var(--space-xxs);
        }
        @include respond(md) {
          margin-top: var(--space-xxs);
          margin-bottom: var(--space-xs);
        }
        @include respond(sm) {
          margin-top: var(--space-l);
          margin-bottom: var(--space-xs);
          font-size: 18px;
        }

        &_indent_lg {
          @include respond(lg) {
            margin-top: var(--space-xl);
            margin-bottom: var(--space-xxs);
          }
          @include respond(md) {
            margin-top: var(--space-xxxl);
          }
        }
      }

      &__text {
        line-height: 1.5;
        @include respond-down(md) {
          font-size: 14px;
          line-height: calc(20 / 14);
          &:not(:last-child) {
            margin-bottom: var(--space-xxl);
          }
        }
        @include respond(sm) {
          &:not(:last-child) {
            margin-bottom: var(--space-m);
          }
        }

        &_name {
          &_presentation {
            display: flex;
            align-items: center;
            @include respond(lg) {
              margin-top: var(--space-s);
            }
            @include respond(sm) {
              flex-direction: column;
              align-items: flex-start;
            }

            a {
              &:not(:last-child) {
                @include respond(xl) {
                  margin-right: var(--space-xxxxxxxxxxl);
                }
                @include respond(lg) {
                  margin-right: var(--space-xxxxxxxxxl);
                }
                @include respond(sm) {
                  margin-bottom: var(--space-xs);
                }
              }
            }
          }
        }
      }

      &__download-link {
        @include respond-down(lg) {
          font-size: 18px;
        }

        @include respond(md) {
          &:not(:last-child) {
            margin-right: var(--space-xxxxxxxxxl);
          }
        }

        .svg-icon {
          margin-right: var(--space-xxs);
        }
      }

      &__slider {
        .glide__bullets {
          position: relative;
          top: var(--space-m);
          bottom: 0;
          flex-wrap: wrap;
        }

        @include respond(md) {
          margin-top: var(--space-xxxl);
        }
        @include respond(sm) {
          margin-top: var(--space-xl);
          .glide__bullet {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .journal {
    @at-root {
      &__slide {
        display: flex;
        flex-direction: column;
      }

      &__image-wrapper {

      }

      &__image {
        display: block;
        height: 323px;
        object-fit: contain;
        @include respond(lg) {
          height: 360px;
        }
        @include respond(md) {
          height: 430px;
        }
        @include respond(sm) {
          height: 390px;
        }
      }

      &__title-block {
        margin-top: var(--space-s);
        @include respond(lg) {
          margin-top: var(--space-xs);
        }
        @include respond(sm) {
          margin-top: var(--space-m);
        }
      }

      &__title {

      }

      &__buttons {
        display: flex;
        margin-top: var(--space-xs);
        @include respond(lg) {
          margin-top: var(--space-xxs);
        }
      }

      &__button {
        display: block;
        min-width: 88px;
        max-width: 88px;

        &:not(:last-child) {
          margin-right: var(--space-xxxs);
        }
      }
    }
  }
}