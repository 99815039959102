.b-person {
  @at-root {
    &__image {

    }

    &__title-block {
      display: flex;
      flex-direction: column;
      margin-top: var(--space-m);
      height: 100%;

      @include respond-up(xl){
        flex-direction: column;
      }

      @include respond-down(md) {
        margin-top: var(--space-s);
        flex-direction: column;
        justify-content: flex-start;
      }
      @include respond-down(sm) {
        margin-top: var(--space-m);
      }
    }

    &__title {
      font-size: 20px;
      line-height: 22px;
      @include respond-down(md) {
        line-height: 24px;
      }
    }

    &__info {
      display: flex;
      padding: var(--space-xxxs) 0 var(--space-xxxxs);
      min-height: 100%;
      flex-direction: column;
      font-size: 14px;
      line-height: 18px;

      .text-box {
        *:not(:last-child) {
          margin-bottom: var(--space-xxxs);
        }
      }
      .link {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        a {
          display: inline-flex;
          align-items: center;
          transition: all 0.3s ease-in;
          img {
            margin-right: 20px;
          }
          &:hover {
            text-decoration: underline;
          }
          &:not(:last-child) {
            margin-bottom: var(--space-xxxs);
          }
        }
      }

      @include respond-down(lg){
        max-width: 180px;
      }
      @include respond-down(md) {
        max-width: 100%;
        padding: var(--space-xxs) 0 var(--space-xxxxs);
        > .text {
          &:not(:last-child) {
            margin-bottom: var(--space-xxxs);
          }
        }
      }
    }

    &__description {
      margin-top: var(--space-s);
      font-size: 14px;
      line-height: 18px;

      max-height: 93px;
      overflow-y: scroll;
      cursor: auto;

      @include respond-down(md) {
        margin-top: var(--space-xxxxs);
      }

      @include respond-down(sm) {
        margin-top: var(--space-xxxs);
      }

      &__name {

      }

      &__image {
      }
    }
  }
}