.events-list-page {
  .events {
    margin-top: var(--space-xxxxxxxl);
  }
  .hero {
    //display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--space-xxxxxxxxxxl);
    @include respond(lg) {
      //display: block;
    }
    @at-root {
      &__title {
        max-width: 700px;
        @include respond(md) {
          margin-bottom: 37px;
        }
        @include respond(sm) {
          margin-bottom: 37px;
        }
      }
      &__img {
        max-width: 100%;
        max-height: 401px;
        @include respond(lg) {
          max-height: 270px;
        }
      }
    }
  }
  .main-events {

  }
  .title-block {
    //display: flex;
    @at-root {
      &__title {
        //max-width: 415px;
        @include respond-down(sm) {
          margin-bottom: 30px;
        }
      }
      &__subtitle {
        font-size: 16px;
        line-height: 24px;
        max-width: 525px;
        @include respond(lg) {
          //max-width: 450px;
        }
      }
    }
  }

  .glide {

    @include respond-down(sm) {
      position: relative;
    }

    &::before {
      content: none;
    }

    [data-glide-el="track"] {
      @include respond-down(sm) {
        overflow: hidden;
      }
    }

    &__bullets {
      bottom: 60px;
      display: none;

      @include respond-down(sm) {
        display: inline-flex;
      }
    }

    &__bullet {
      height: 14px;
      width: 14px;
      background-color: #91D2F2;

      &--active {
        height: 14px;
        width: 14px;
        background-color: #0D8CCB;
      }

      &:hover, &:focus {
        border: none;
      }
    }
  }

  .list-events {
    display: flex;
    width: 100%;
    margin-top: var(--space-xxl);
    padding-top: var(--space-m);
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 176px;
    @include respond(lg) {
      padding-top: var(--space-xl);
      justify-content: center;
    }
    @include respond(md) {
      margin-top: var(--space-s);
      padding-top: var(--space-xxl);
      justify-content: flex-start;
    }
    @include respond-down(sm) {
      margin-top: var(--space-l);
      padding-right:var(--space-xxxxs);
      justify-content: flex-start;
      flex-wrap: nowrap;
      margin-bottom: 95px;
    }

    &, & * {
      box-sizing: border-box;
    }
  }
  .event {
    display: flex;
    flex-basis: 22%;
    padding-right: 8%;
    flex-direction: column;
    justify-content: space-between;
    @include respond(lg) {
      flex-basis: 33%;
      &:nth-of-type(4), &:nth-of-type(5) {
        margin-top: var(--space-xxxxl);
      }
    }
    @include respond(md) {
      flex-basis: calc(50% - calc(var(--grid-gap) / 2));
      min-height: 72px;
      margin-bottom: var(--space-xs);
      max-width: none;
       padding-right: 0;
    }
    @include respond-down(sm) {
      flex-basis: auto;
      max-width: none;
      padding-right: 0;
      &:not(:last-child) {
        margin-bottom: var(--space-s);
      }
    }

    &:last-child {
      flex-basis: 12%;
      padding-right: 0;
      @include respond(lg) {
        flex: 0 0 33%;
        padding-right: 8%;
      }
      @include respond-down(md) {
        flex: 0 0 calc(50% - calc(var(--grid-gap) / 2));
        padding-right: 8%;
      }
      @include respond-down(sm) {
        flex-basis: auto;
        max-width: none;
        padding-right: 0;
      }
    }

    &__wrap {
      .event__title {
        margin: 20px 0;
        font-size: 18px;
        font-weight: $bold;

        @include respond(sm) {
          padding-left: 7px;
          margin-bottom: 45px;
        }
      }
    }
    .event__link {
      position: relative;
      color: #424242;
      font-weight: $normal;
      font-size: 16px;
      text-decoration: underline;
      max-width: 200px;
      @include respond(sm) {
        max-width: 230px;
        padding-left: 7px;
      }
      &:before {
        content: '';
        background: url("../svg/arrow-4x9.svg") no-repeat;
        width: 6px;
        height: 9px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -4.5px;

      }
    }
  }
}