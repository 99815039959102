textarea, .textarea {
  display: inline-block;
  min-height: 188px;
  padding: var(--space-xs);
  outline: none;
  resize: none;
  border: 1px solid $color-additional;
  border-radius: 4px;
  font-family: 'Arial';

  @include respond(md) {
    min-height: 145px;
  }

  @include respond(sm) {
    min-height: 160px;
  }

  &, &::placeholder {
    opacity: 1;
    color: $color-secondary;
    font-size: 16px;
    @include respond(md) {
      font-size: 15px;
    }
    @include respond(sm) {
      font-size: 14px;
    }
  }

  @at-root {
    &_color {
      &_transparent {
        background: $t;
      }

      &_default {
        background: $bg-default;
      }
    }

    &_size {
      @each $name, $size in $inputSizes {
        &_#{$name} {
          height: #{$size};
        }
      }

      &_xxl {
        &, &::placeholder {
          font-size: 14px;
        }
      }
    }

    &_border {
      &_all {
        border: 1px solid $color-additional;
      }
    }

    &_rounded {
      border-radius: 4px;
    }

    &_width {
      &_full {
        width: 100%;
      }
    }
  }
}
