.file-input {
  display: block;
  position: relative;
  width: 206px;
  height: var(--input-xxl);
  border-radius: 3px;
  border: 1px solid $color-secondary;
  cursor: pointer;
  transition: background ease .4s;
  overflow: hidden;

  &:hover {
    background: $color-black;

    .file-input__label {
      color: $color-white;
    }
  }

  &, * {
    cursor: pointer;
  }

  input {
    opacity: 0;

    width: 100%;
    height: 100%;
    outline: none;
    cursor: pointer;
  }

  @at-root {
    &__label {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      font-weight: $bold;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      line-height: var(--input-xxl);
      color: $color-secondary;
      transition: color ease .4s;
    }
  }
}