.check-dot {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  flex: 0 0 18px;
  border-radius: 50%;

  @include respond-down(sm) {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
  }

  &, &:hover, &:active, &:focus {
    border-style: solid;
    border-color: $bg-border;
    border-width: 2px;
    background: $t;
  }

  &:before {
    opacity: 0;
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    height: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: $bg-border;
    transition: opacity .2s linear;
  }

  &_active {
    &:before {
      opacity: 1;
    }
  }
}