html {
  min-width: 375px;
}
body.b-fp-filter-active {
  @include respond-down(md) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: hidden;

    .breadcrumbs {
      display: none;
    }

    main.main {
      position: relative;
    }
  }
}

.b-fp-filter {
  display: none;

  @include respond-down(md) {
    display: block;
    @at-root {
      &_opened {
        .b-fp-filter__content {
          z-index: 1;
          display: block;
          opacity: 1;
          padding-bottom: var(--space-xxxxxl);

          pointer-events: all;

        }
      }

      &__button {
        display: flex;
        width: 100%;
        height: var(--input-xl);
        border-top: 1px solid $color-secondary;
        border-bottom: 1px solid $color-secondary;

        justify-content: space-between;
        align-items: center;

        line-height: calc(var(--input-xl) - 1px);
        font-size: 16px;
        color: $color-primary;

        background: $t;

        &_type {
          &_open {

          }

          &_close {

          }
        }
        &_border-top {
          &_none {
            border-top: none;
          }
        }
      }

      &__button-plus {
        display: flex;
        width: 18px;
        height: 18px;
        justify-content: center;
        align-items: center;

        &:before {
          opacity: 1;
          transform: rotateZ(0);

          content: '';
          display: block;
          position: relative;
          top: 0;
          left: 9px;
          bottom: 0;
          width: 1px;
          height: 100%;
          background: $bg-border;

          transition: all ease .3s;
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: $bg-border;
        }

        &_type {
          &_close {
            &:before {
              content: none;
            }
          }
        }
      }

      &__content {
        opacity: 0;
        pointer-events: none;

        display: none;
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: var(--space-l) var(--layout-padding) 0;
        background: $bg-default;
        overflow: scroll;

        [data-content] {
          position: relative;
          z-index: 1;
          @include respond(sm) {
            margin-bottom: var(--space-xxxxxxxxl);
          }
        }

        [data-content-cat-current] {
          @include respond(md) {
            list-style: none;
            column-count: 3;
            background-color: #fff;
            position: relative;
            z-index: 1;
          }

          @include respond(sm) {
            display: none;
          }
        }

        .b-fp-filter__button {
          border: 0;
          font-size: 24px;
          font-weight: $bold;
        }
      }

      &__content-buttons {

        .button {
          margin-bottom: var(--space-xs);
        }
      }

      &__content-filters {

      }

    }
  }
}