.b-partners {
  @at-root {
    &__filter {
      margin-top: var(--space-xxxxxxl);

      @include respond(lg) {
        margin-top: var(--space-xxxxxxxl);
      }
      @include respond(md) {
        margin-top: var(--space-xxxxl);
      }
    }

    &__filter-wrapper {
      column-count: 3;
      @include respond(md) {
        column-count: 2;
      }
      @include respond(sm) {
        column-count: 1;
      }
    }

    &__filter-item {
      display: block;
      font-size: 18px;
      line-height: 21px;

      @include respond-down(sm) {
        font-size: 16px;
        line-height: 20px;
      }

      &_active {
        font-weight: $bold;
        color: $color-primary;
        text-decoration: none;
      }

      &:not(:last-child) {
        margin-bottom: var(--space-xs);
      }
    }

    &__slider {
      display: block;
      margin-top: var(--space-xxxxxxl);

      @include respond(lg) {
        margin-top: var(--space-xxxxxl);
      }
      @include respond-down(sm) {
        margin-top: var(--space-xxl);

        .title-w-controls__slides-count {
          margin: var(--space-xxl) 0 var(--space-xxs);
        }
      }
    }

    &__slider-bullets {
      max-width: 100%;
      overflow-x: scroll;

      @include respond-down(sm) {
        position: static;
        left: 0;
        transform: initial;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__item-image-wrapper {
      display: flex;
      height: 100%;
    }

    &__item-image {
      display: block;
      min-width: 50px;
      width: 100%;
      height: 100px;
      object-fit: contain;
    }

    &__item-title {
      margin-top: var(--space-xxxs);
      font-size: 14px;
      line-height: 18px;
    }
  }
}


.b-partners__section {
  margin-top: 80px;
  margin-bottom: 80px;
}

.b-partners__static {
  display: flex;
  padding-top: 50px;
  flex-wrap: wrap;
  justify-content: space-between;
  &-wrap {
    flex-basis: 40%;
    display: flex;
    flex-wrap: wrap;
    @include respond(md) {
      flex-basis: 45%;
    }
    @include respond(sm) {
      flex-basis: 100%;
      margin-bottom: 20px;
    }
  }
  & .b-partners__item {
    flex-basis: 50%;
    padding: 0 15px;
    @include respond(md) {
      flex-basis: 50%;
    }
    @include respond(sm) {
      flex-basis: 50%;
      margin-bottom: 20px;
    }
  }
  & .b-partners__item-image-wrapper {
    padding: 0 30px;
    margin-bottom: 25px;
  }
  & .b-partners__item-title {
    text-align: center;
  }
}

