.b-service-card {
  @at-root {
    &__item {
      position: relative;
      width: 100%;

      @include respond(sm) {
        padding-bottom: var(--space-xs);
      }

      &_has-contacts {
        padding-bottom: var(--space-m);
      }
    }

    &__image-wrapper {
      display: flex;
      position: relative;
      @include imageHoverZoom;
    }

    &__image {
      display: block;
      width: 100%;
      object-fit: contain;
      height: 264px;
      @include respond(md) {
        height: 230px;
      }
      @include respond-down(sm) {
        height: 220px;
      }
    }

    &__image-hint {
      display: flex;
      position: absolute;
      top: 12px;
      right: 12px;
      align-items: center;
      font-size: 12px;
      pointer-events: none;

      .text {
        max-height: 20px;
        padding: var(--space-xxxxs);
        background: $color-white;
      }

      .svg-icon {
        margin-left: var(--space-xxxxs);
      }
    }

    &__title-block-info {
      padding: 20px 0px;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-secondary);
      max-height: 100px;
      overflow: hidden;
    }

    &__title-block {
      display: flex;
      width: 100%;
      min-height: 72px;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: var(--space-xs);
      border-top: 2px solid $bg-border;
      padding-top: 20px;

      @include respond(lg) {
        min-height: 69px;
      }
      @include respond(md) {
        min-height: 54px;
        margin-bottom: var(--space-s);
      }
      @include respond(sm) {
        margin-bottom: var(--space-xxxxxs);
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 20px;
      }
    }

    &__title {
      max-width: 75%;
      font-size: 23px;
      line-height: 24px;
      @include respond(lg) {
        max-width: 72%;
        font-size: 20px;
        line-height: 1;
      }
      @include respond(md) {
        max-width: 60%;
        font-size: 16px;
        line-height: 18px;
      }
      @include respond-down(sm) {
        max-width: none;
        font-size: 18px;
        line-height: 20px;
      }
    }

    &__price-wrapper {
      display: flex;
      padding: var(--space-xxxs) 0 0;
      flex-direction: column;
      align-items: flex-end;

      @include respond(lg) {
        padding: 0;
      }
      @include respond(md) {
        padding: var(--space-xxxxs) 0 0;
      }
      @include respond-down(sm) {
        flex-direction: row;
        margin-top: var(--space-xxxs);
        margin-bottom: var(--space-s);
        justify-content: space-between;
        align-items: center;
      }
    }

    &__price {
      font-size: 22px;
      @include respond(md) {
        font-size: 18px;
      }
      @include respond(sm) {
        font-size: 21px;
      }
    }

    &__fav {
      margin-top: var(--space-xxs);
      background-color: transparent;

      @include respond(lg) {
        margin-top: var(--space-s);
      }

      @include respond-down(sm) {
        margin-top: 0;
        margin-right: var(--space-xxxs);
      }

      &, .svg-icon {
        width: 20px;
        height: 20px;
      }

      .svg-icon {
        cursor: pointer;
      }
    }

    &__additional-info {
      display: flex;
      width: 100%;
      padding-top: var(--space-s);
      align-items: center;
      justify-content: space-between;

      @include respond(md) {
        padding-top: var(--space-xxs);
      }
      @include respond-down(sm) {
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        align-items: flex-start;
        justify-content: flex-start;
      }

      a{
        font-size: 16px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #424242;
        position: relative;

        &:after{
          content: '';
          background-image: url('data:image/svg+xml;utf8,<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5 5L1 9" stroke="black"/></svg>');
          position: absolute;
          width: 6px;
          height: 10px;
          background-repeat: no-repeat;
          right: -18px;
          top: 7px;
        }
      }

      .text {
        font-size: 15px;
        line-height: 19px;
        @include respond-down(sm) {
          &:nth-child(n+2) {
            font-size: 16px;
          }
          &:not(:last-child) {
            margin-bottom: var(--space-xxs);
          }
        }
      }
    }

    &__prod-contact {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      align-items: flex-end;
      line-height: 1.1;

      .svg-icon {
        margin-right: var(--space-xs);

      }
    }
  }
}

.b-service-card-wrapper + .b-service-cards-empty {
  display: none;
}