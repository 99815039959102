body.favorites-page {
  .page-title {
    @include respond(xl) {
      margin-top: calc(var(--space-xxxxxxl) + var(--space-xxxxs));
      margin-bottom: calc(var(--space-xxxxxxxxxl) + var(--space-xxxs));
    }

    @include respond(lg) {
      margin-top: var(--space-xxxxxxxl);
      margin-bottom: var(--space-l);
    }

    @include respond(md) {
      margin-top: var(--space-xxxxxl);
      margin-bottom: var(--space-l);
    }

    @include respond(sm) {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-m);
    }
  }

  .section {
    @at-root {
      &_name {
        &_count {
          border-bottom: 1px solid $color-secondary;
          padding-bottom: var(--space-xxs);

          @include respond(xl) {
            margin-bottom: var(--space-xxl);
          }

          @include respond(lg) {
            margin-bottom: var(--space-xxxxxxxl);
          }

          @include respond(md) {
            margin-bottom: var(--space-xxxxxxxxl);
          }

          @include respond(sm) {
            margin-bottom: calc(var(--space-xxxxxxxl) + var(--space-xxxxs));
          }


          .text {
            & > .text:first-child {
              margin-right: var(--space-xxxs);
            }
          }
        }

        &_goods {

        }

        &_subscribe-form {
          @include respond(xl) {
            margin-top: calc(var(--space-xxxxxxxxxxxxl) + var(--space-m));
          }

          @include respond(lg) {
            margin-top: var(--space-xxxxxxxxxxxl);
          }

          @include respond(md) {
            margin-top: calc(var(--space-xxxxxxxxxxxl) + var(--space-xxxxs));
          }

          @include respond(sm) {
            margin-top: var(--space-xxxxxxxxxxxl);
          }
        }
      }
    }
  }
}