$checkbox-height: 28px;
$checkbox-width: 28px;



.articles-list {
    &__wrap {
        position: relative;
        z-index: 1;
        @include respond(xl) {
            &:hover {
                z-index: 2;
                & .articles-list__list {
                    display: flex;
                }
            }
        }
        @include respond(sm) {
            &:hover {
                & .articles-list__list {
                    display: auto;
                }
            }
            &--active {
                & .articles-list__list {
                    display: flex;
                }
            }
        }
    }
    &__arrow {
        display: inline-block;
        min-width: 10px;
        flex-basis: 10px;
        height: 100%;
        margin-left: var(--space-xxs);
        background-image: url('/static/images/down_arrow.png');
        background-position: center;
        background-repeat: no-repeat;
        flex-shrink: 0;
    }
    &__label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        z-index: 2;
        &--noarrow {
            & .articles-list__checkbox {
                width: 14px;
                height: 14px;
                flex-basis: 14px;
                background-size: contain;
            }
            &::after {
                display: none;
            }
        }
    }
    &__title {
        font-size: 16px;
        white-space: nowrap;
    }
    &__checkbox {
        background-image: none;
        border: 1px solid var(--color-secondary);
        box-sizing: border-box;
        height: $checkbox-height;
        flex-basis: $checkbox-width;
        width: $checkbox-width;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        margin-right: var(--space-xxs);
    }
    & input {
        display: none;
        &:checked ~ .articles-list__checkbox {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ERectangle%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='32' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Статьи' transform='translate(-85.000000, -258.000000)' fill-rule='nonzero' stroke='%23000000'%3E%3Cg id='Rectangle' transform='translate(85.000000, 258.000000)'%3E%3Crect stroke-width='1' x='0.5' y='0.5' width='27' height='27'%3E%3C/rect%3E%3Cpolyline id='Path-4' stroke-width='2' stroke-linecap='square' points='9 13.2730086 12.3933281 17 20 10'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            border: 0;
        }
    }
    &__list {
        z-index: 1;
        display: none;
        flex-direction: column;
        position: absolute;
        top: -($checkbox-height / 2);
        left: -$checkbox-width;
        border: 1px solid var(--color-secondary);
        padding: calc(#{$checkbox-height} * 2) 30px 20px 30px;
        background-color: #fff;
        // max-height: 50vh;
        // overflow: auto;
        box-sizing: content-box;
        min-width: 100%;
    }
    &__elem {
        margin-bottom: 5px;
    }
    &__empty {
        min-height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

