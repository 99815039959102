.vacancy {
  margin-top: 71px;
  margin-bottom: 150px;

  .title_block {
    margin-bottom: 35px;
    font-size: 32px;
    line-height: 39px;
  }

  @include respond(sm) {
    margin-top: 55px;
  }

  .buttons {
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
      @include respond(sm) {
        max-width: 233px;
      }
    }

    button:first-child {
      margin-bottom: 20px;
      @include respond(sm) {
        margin-right: 20px;
      }
    }

    @include respond(sm) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .info {
    padding-left: 70px;
    border-left: 2px solid #D8D8D8;
    @include respond(md) {
      padding-left: 30px;
    }
    @include respond(sm) {
      padding-left: 0;
      border: none;
      margin-top: 63px;
    }

    .title {
      font-size: 24px;
      font-weight: $bold;
      margin-bottom: 20px;
    }

    .description {
      margin-bottom: 52px;

      &__text {
        line-height: 24px;
      }
    }

    .duties {
      margin-bottom: 52px;

      ul {
        li {
          margin-bottom: 5px;
        }
      }
    }

    .conditions {
      ul {
        li {
          margin-bottom: 5px;
        }
      }
    }
  }
}