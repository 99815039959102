.whom-we-see {
  @at-root {
    &__title {
      margin-bottom: var(--space-xxxxl);

      @include respond(lg) {
        margin-bottom: var(--space-xxxxxxl);
      }

      @include respond-down(sm) {
        margin-bottom: 0;
      }
    }

    &__list {
      @include respond-up(lg) {
        margin-top: var(--space-xxl);
      }
      @include respond-down(sm) {
        margin-bottom: var(--space-xs);
      }
    }
  }
}

.whom-we-see {
  .list__title {
    margin-top: var(--space-xxl);
    @include respond-down(sm) {
      margin-top: var(--space-m);
      margin-bottom: var(--space-xxs);
    }
  }

  .list__list {

  }

  .list__item {
    font-size: 18px;
    @include respond-down(sm) {
      font-size: 14px;
      line-height: 18px;
    }

    .icon-plus__icon {
      @include respond-down(md) {
        justify-content: center;
      }
      @include respond-down(sm) {
        justify-content: flex-start;
        margin-right: 20px;
      }
    }
  }
}