.js-multi-menu {
  @at-root {
    &_respond {

    }

    &__item {

    }

    &__items {
      display: none;
    }
  }
}