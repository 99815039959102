.b-prices-card {
  height: 456px;
  padding: var(--space-xxxxxxxxl) calc(var(--space-xxxxxxxxl) - var(--space-xxxxs)) calc(var(--space-xxxxxxxxxxl) + var(--space-s));

  @include respond(lg) {
    height: 420px;
    padding: var(--space-xxxl) var(--space-xxxl) calc(var(--space-xxxxxxxxxxl) + var(--space-xs));
  }
  @include respond-down(md) {
    height: 260px;
    padding: var(--space-xxxl);
    margin-bottom: var(--space-xs);
  }

  @include respond(sm) {
    height: 240px;
    padding: var(--space-xxxl) var(--space-xs);
    &_size {
      &_large {
        height: 420px;
      }
    }
  }

  &.b-prices-card_size_large {
    @media (min-width: 1440px) and (max-width: 1667px) {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
    }

    @media (min-width: 1151px) and (max-width: 1275px) {
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 25px;
    }
  }

  @at-root {
    &__content {

    }

    &__title {
      font-size: 32px;
      line-height: 41px;
      font-weight: $bold;
      @include respond-down(lg) {
        font-size: 28px;
        line-height: 36px;
      }
      @include respond(sm) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &__subtitle {
      font-size: 18px;
      line-height: 23px;
    }

    &__text {
      font-size: 36px;
      line-height: 42px;
      @include respond-down(lg) {
        font-size: 28px;
        line-height: 35px;
      }
      @include respond(sm) {
        font-size: 25px;
        line-height: 44px;
      }
    }

    &__body {
      padding-top: var(--space-xs);
      @include respond-down(sm) {
        padding-top: var(--space-m);
      }
    }

    &__list {
      @include respond(md) {
        display: flex;
        justify-content: space-between;
      }
    }

    &__list-item {
      &:not(:last-child) {
        margin-bottom: var(--space-m);
      }
    }

    &__footer {
      margin-top: var(--space-l);
      position: relative;
      width: initial;
      left: 0;
      right: 0;
      font-size: 14px;
      line-height: 18px;
      @include respond-down(lg) {
        margin-top: 0;
      }
      @include respond(sm){
        top:calc(-1 * var(--space-xxxxxxl));
      }
    }

    &__button {
      @include respond(sm) {
        width: 100%;
        padding: 0 var(--space-xxxxs);
        text-align: center;
      }
    }
  }
}