.section {
  @at-root {
    &_lazy {
      display: block;
      width: 100%;
      min-height: 300px;
      background: $bg-default url('../svg/preloader.svg') no-repeat center center;
      background-size: 50px;
      color: var(--bg-default);
    }

    &_indent-b {
      $spaces: "xxl", "xxxxl", "xxxxxl";
      @each $name in $spaces {
        &_#{$name} {
          margin-bottom: var(--space-#{$name});
        }
      }
    }

    &_space-v {
      $spaces: "xxl", "xxxxl", "xxxxxl", "xxxxxxl";
      @each $name in $spaces {
        &_#{$name} {
          padding-top: var(--space-#{$name});
          padding-bottom: var(--space-#{$name});
        }
      }
    }

    &_background {
      &_default {
        background: $bg-default;
      }

      &_footer {
        background: $bg-footer;
      }
    }

    &__title {
      @include textLargest;
    }
  }
}
