.banner {
    display: flex;
    column-gap: 100px;
    padding-top: 40px;
    padding-bottom: 50px;
    @media(max-width:1150px) {
        flex-direction: column;
    }
    // .banner__info
    &__info {
        display: flex;
        flex-direction: column;
        @media(max-width:1150px) {
            margin-bottom: var(--space-xl);
        }
    }
    &__info,
    &__image {
        @media(min-width:1151px) {
            flex: 0 0 calc(50% - 50px);
        }
    }
    // .banner__h2
    &__h2 {
        margin-bottom: var(--space-xxl);
    }
    // .banner__text
    &__text {
        flex: 1 1 auto;
        margin-bottom: var(--space-s);
        font-size: 18px;
        line-height: 120%;
    }
    // .banner__date
    &__date {
        color: var(--color-link);
    }
    // .banner__image
    &__image {
        text-align: center;
    }
}

.members {
    padding-top: 50px;
    padding-bottom: 50px;
    // .members__h2
    &__h2 {
        @extend .banner__h2;
    }
    // .members__h3
    &__h3 {
        margin-bottom: var(--space-xxxxxxxl);
    }
    // .members__grid
    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
        column-gap: 15px;
        row-gap: 55px;
    }
    // .members__item
    &__item {
    }
    // .members__logo
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 75px;
        margin-bottom: var(--space-l);
    }
    // .members__name
    &__name {
        margin-bottom: var(--space-xs);
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
        text-align: center;
    }
    // .members__desc
    &__desc {
        margin-bottom: var(--space-xs);
        font-size: 14px;
        line-height: 120%;
    }
    // .members__link
    &__link {
        display: inline-flex;
        align-items: center;
        column-gap: 5px;
        font-size: 18px;
        line-height: 120%;
        color: var(--color-link);
        text-decoration: underline;
    }
}

.charter {
    padding-top: 50px;
    padding-bottom: 50px;
    // background-color: #EAF0F2;
    color: #000;
    // .charter__wrap
    &_wrap {
    }
    // .charter__h2
    &__h2 {
        @extend .banner__h2;
    }
    // .charter__row
    &__row {
    }
    // .charter__item
    &__item {
        color: #000;
    }
}
.documents {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #73A4B5;
    color: #fff;
    // .documents__h2
    &__h2 {
        @extend .banner__h2;
    }
    // .documents__row
    &__row {
    }
    // .documents__item
    &__item {
        color: #fff;
    }
}

.management {
    padding-top: 50px;
    padding-bottom: 50px;
    // .management__h2
    &__h2 {
        @extend .banner__h2;
    }
}


.document-list {
    display: grid;
    row-gap: 50px;
    @media(min-width:500px) {
        grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
        column-gap: 20px;
    }
    // .document-list__item
    &__item {
        display: flex;
        align-items: flex-end;
        column-gap: 18px;
    }
    // .document-list__logo
    &__logo {
    }
    // .document-list__info
    &__info {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        column-gap: 20px;
        padding-bottom: 10px;
        width: 100%;
        border-bottom: 1px solid currentColor;
        svg {
            flex: 0 0 20px;
        }
    }
    // .document-list__name
    &__name {
    }
}

