.sub_title {
  margin-top: 83px;
  @include respond(sm) {
    font-size: 14px;
    margin-top: 55px;
  }
}

.goals {
  margin-top: 83px;

  .title_block {
    margin-bottom: 35px;
    font-size: 32px;
    line-height: 39px;
  }

  @include respond(sm) {
    margin-top: 55px;
  }

  ul {
    li {
      position: relative;
      padding-left: 50px;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;

      li:last-child {
        margin-bottom: 0;
      }

      @include respond(sm) {
        font-size: 14px;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 9px;
        width: 14px;
        height: 9px;
        background: url("../svg/checkmark.svg") no-repeat;
        background-size: cover;
      }
    }
  }
}
