.unsubscribe-page {
  .unsubscribe {
    display: flex;
    min-height: 500px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @at-root {
      &__title {
        font-size: calc(10px + 3vw);
        text-align: center;
      }

      &__link {
        font-size: calc(10px + 1vw);
        display: block;
        margin-top: 30px;
        text-decoration: underline;
      }
    }
  }
}