body.catalog-list-page {
  &.b-fp-filter-active {
    &.catalog-list-page .section_name_catalog-list, .cat-filter, .cat-filter__container {
      position: static;
    }
  }

  .cat-filter {
    .b-fp-filter {

    }
  }

  .catalog-list {

    @at-root {
      &__filter {

        @include respond(lg) {
          margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxxs));
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
        }
      }

      &__goods-wrapper {
        margin-top: var(--space-xxxxxxxxl);
        @include respond(md) {
          margin-top: var(--space-xxxxxl);
        }
        @include respond(sm) {
          margin-top: var(--space-xs);
        }
      }

      &__goods {

      }

      &__goods-filter {
        margin-bottom: var(--space-xl);
      }

      &__pagination {
        padding-top: var(--space-xxxxxxxxl);
      }
    }
  }

  .section {
    &_name {
      &_catalog-list {
        position: relative;

        @include respond(xl) {
          margin-top: calc(var(--space-xs) + var(--space-m));
          margin-bottom: calc(var(--space-xxxxxxxxxxxxl) + var(--space-xxs));
        }

        @include respond(lg) {
          margin-top: calc(var(--space-xs));
        }

        @include respond(md) {
          margin-top: var(--space-xxxxl);
        }
      }

      &_promo {
        @include respond(lg) {
          margin-top: calc(var(--space-xxxxxxxxl) + var(--space-xxxs));
        }

        @include respond(md) {
          margin-top: calc(var(--space-xxxl) + var(--space-xxxs));

          .b-promo-product {
            margin-top: var(--space-xxxxxl);
          }
        }
      }
    }
  }

  .grid__fraction {
    @at-root {
      &_name {
        &_add-to-site {
          @include respond(sm) {
            margin-bottom: var(--space-xxl);
          }
        }

        &_search {
          @include respond(sm) {
            margin-top: var(--space-l);
          }
        }
      }
    }
  }
}