.info-promo {
  display: flex;
  position: relative;
  padding: var(--space-xxxl) var(--space-xxxxxxxl);
  background-color: $color-secondary;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  background-image: url('../images/blocks/info-promo/bg.png');

  @include respond(lg) {
    padding: var(--space-s) var(--space-xxl)
  }

  @include respond-down(md) {
    padding: var(--space-xxxxxxl) var(--space-xxl) var(--space-xxxxxxl);
    background-position: top left;
    background-size: 476px;
    background-image: url('../images/blocks/info-promo/bg-md.png');
  }

  @include respond-down(md) {
    margin-left: calc(-1 * var(--layout-padding));
    margin-right: calc(-1 * var(--layout-padding));
  }

  @include respond(sm) {
    padding: var(--space-xl) var(--space-xs) var(--space-xxl);
  }

  @at-root {
    &__container {
      display: flex;
      position: relative;
      width: 100%;
      min-height: 300px;
      justify-content: space-between;
      align-items: center;

      @include respond-down(md) {
        flex-direction: column;
      }
    }

    &__text {
      position: relative;
      z-index: 2;

      @include respond-down(md) {
        width: 100%;
      }
    }

    &__title {
      font-size: 72px;
      @include respond-down(lg) {
        font-size: 58px;
      }
      @include respond(sm) {
        font-size: 47px;
      }
    }

    &__subtitle {
      margin-top: var(--space-xxxxl);
      font-size: 58px;

      @include respond(sm) {
        margin-top: var(--space-xl);
        font-size: 36px;

        .text {
          &:last-child {
            margin-top: var(--space-xxs);
            font-size: 58px;
          }
        }
      }
    }

    &__image {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;
      z-index: 1;

      @include respond-down(md) {
        position: static;
        height: 240px;
        margin-top: var(--space-xs);
        background-position: left;
      }
      @include respond(sm) {
        margin-top: var(--space-xxxs);
      }
    }
  }
}