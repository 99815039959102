.info-form {
  position: relative;
  background-image: url('../images/blocks/info-form/bg_center.png');
  background-position: center bottom;

  &, &::before, &::after {
    background-repeat: no-repeat;
    background-size: contain;
  }

  &::before, &::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
  }

  &::before {
    background-image: url('../images/blocks/info-form/bg_left.png');
    background-position: center left;
    @include respond(lg) {
      background-image: url('../images/blocks/info-form/bg_left_lg.png');
    }
    @include respond(md) {
      background-image: url('../images/blocks/info-form/bg_left_md.png');
    }
    @include respond(sm) {
      background-position: 0 0;
      background-size: auto;
      background-image: url('../images/blocks/info-form/bg_left_sm.png');
    }
  }

  &::after {
    background-image: url('../images/blocks/info-form/bg_right.png');
    background-position: center right;
    @include respond(lg) {
      background-image: url('../images/blocks/info-form/bg_right_lg.png');
    }
    @include respond(md) {
      background-image: url('../images/blocks/info-form/bg_right_md.png');
    }
    @include respond(sm) {
      content: none;
      background-image: none;
    }
  }

  @at-root {
    &__container {
      position: relative;
      padding: calc(var(--space-xxxxxxxxl) + var(--space-xxxs)) 0 calc(var(--space-xxxxxxxxl) + var(--space-xxxs)) 0;
      z-index: 2;
      @include respond(lg) {
        padding: var(--space-xxxxxxxxxxl) 0 var(--space-xxxxxxxxxl) 0;
      }
      @include respond(md) {
        padding: var(--space-xxxxxxxxxl) 0 var(--space-xxxxxxxxl) 0;
      }
      @include respond-down(sm) {
        padding: var(--space-xxxxxxxl) 0 var(--space-xxxxxxxl) 0;
      }
    }

    &__title-block {
      @include respond(xl) {
        grid-column-start: 3;
        grid-column-end: 7;
      }
      @include respond(lg) {
        grid-column-start: 2;
        grid-column-end: 7;
      }
    }

    &__title {
      border-top: 3px solid $bg-border;
      border-bottom: 3px solid $bg-border;
      border-radius: 1.5px;
      padding: var(--space-l) 0;
      font-size: 26px;
      line-height: 32px;
      @include respond-down(lg) {
        font-size: 18px;
        line-height: 24px;
        padding: var(--space-l) 0 var(--space-s);
      }
      @include respond(md) {
        padding: var(--space-m) 0 var(--space-xl);
      }
      @include respond-down(sm) {
        font-size: 22px;
        text-align: center;
        line-height: 27px;
      }

    }

    &__form {
      display: flex;
      position: relative;
      align-items: center;

      &.success {
        &:before {
          content: 'Спасибо за подписку!';
          display: flex;
          position: absolute;
          top: -20px;
          right: -20px;
          bottom: 0;
          left: 0;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: $color-black;
          background: $bg-default;
          font-size: calc(16px + .5vw);
          z-index: 5;
        }

        & * {
          opacity: 0;
          pointer-events: none;
        }
      }

      @include respond-down(md) {
        flex-direction: column;
      }
      @include respond-down(sm) {
        margin-top: var(--space-xxxxxl);
      }
      @include respond(lg) {
        & > div {
          width: 53%;
        }
        .button {
          width: 47%;
        }
      }

      .form-field {
        margin-bottom: 0;
      }

      & > * {
        @include respond-up(xl) {
          width: 50%;
        }
        @include respond-down(md) {
          width: 100%;
        }
      }

      .button {
        margin-left: var(--space-xxxs);
        @include respond(lg) {
          margin-left: var(--space-xxs);
        }
        @include respond-down(md) {
          margin-left: 0;
          margin-top: var(--space-xxs);
        }
      }

      input {
        background: $t;
        color: $color-black;

        &::placeholder {
          color: $color-black;
          text-transform: uppercase;
        }
      }
    }
  }
}