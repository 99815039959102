.breadcrumbs {
  @include respond(xl) {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xl);
  }

  @include respond(lg) {
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxl);
  }

  @include respond(md) {
    margin-top: var(--space-s);
    margin-bottom: var(--space-xxxl);
  }

  @include respond(sm) {
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xs);
  }

  @at-root {
    &__list {
      display: block;
    }

    &__list-item {
      display: inline;

      @include respond-down(lg) {
        font-size: 14px;
      }
      @include respond-down(sm) {
        font-size: 12px;
      }

      &:not(:last-child) {
        margin-right: var(--space-xxxs);
      }

      @include respond-down(sm) {
        line-height: 2.3;
      }
    }

    &__delimiter {
      display: inline-block;
      margin-right: var(--space-xxxs);
      @include respond(lg) {
        margin-right: var(--space-xxxxs);
      }
      @include respond-down(md) {
        margin-right: var(--space-xxxxs);
      }
    }

    &__link {
      text-decoration: underline;
    }
  }
}