@mixin threeDots() {
  content: '...';
  display: inline-block;
  position: absolute;
  right: -40px;
  bottom: -5px;
  font-size: 46px;
  width: 32px;
  letter-spacing: 2px;
}

.glide__bullets {
  &_respond {
    &_sm {
      @include respond-up(md) {
        display: none;
      }
    }
  }
}

.glide__bullet {
  box-shadow: none;

  &--active {
    background-color: $t;

    &.check-dot {
      &::before {
        opacity: 1;
      }
    }
  }
}

/**
  Preloader
 */
.glide {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('../svg/preloader.svg') no-repeat center center;
    background-size: 50px;
    z-index: 5;
  }

  .glide__track {
    opacity: 0;
  }

  &.glide--swipeable {
    &:before {
      content: none;
    }

    .glide__track {
      opacity: 1;
    }
  }
}